import React, { useState } from 'react'
import Logo from '@/assets/svg/yuka-logo.svg'
import { ButtonPrimary } from '@/components/Button'
import Card from '@/components/Card'
import { useAuth } from '@/hooks/useAuth'
import { useHistory } from 'react-router-dom'
import { Input } from '@/components/Input'
import { object, string, InferType } from 'yup'
import { useNotification } from '@/hooks/useNotification'
import { useYup } from '@/hooks/useYup'
import { NotificationType } from '@/components/Notification'

const formSchema = object({
  email: string().email(`Le format de l'email n'est pas valide`).required(),
  password: string().required(),
})

type LoginForm = InferType<typeof formSchema>

export default function SignInPage() {
  const { signIn } = useAuth()
  const history = useHistory()
  const { setNotification } = useNotification()
  const [form, setForm] = useState<LoginForm>({
    email: '',
    password: '',
  })
  const { errors, validate } = useYup(form, formSchema)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (await validate()) {
      await signIn(form.email, form.password)
        .then(() => history.push('/'))
        .catch(() => {
          setNotification({
            title: 'Erreur',
            message: `Votre e-mail ou mot de passe est incorrect. Merci d'essayer à nouveau.`,
            type: NotificationType.Error,
          })
        })
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md space-y-10">
        <img className="lg:block h-8 w-auto mx-auto" src={Logo} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Bienvenue !</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Card>
          <form onSubmit={handleSubmit} className="py-8 px-4 sm:px-10 space-y-6">
            <Input
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              placeholder="Saisissez votre addresse e-mail"
              label="Adresse e-mail"
              error={errors.email}
              handleChange={(text: string) => {
                setForm({ ...form, email: text })
              }}
            />
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              placeholder="Entrez votre mot de passe"
              label="Mot de passe"
              error={errors.password}
              handleChange={(text: string) => {
                setForm({ ...form, password: text })
              }}
            />
            <div className="flex items-center justify-between">
              <ButtonPrimary title="Se connecter" type="submit" />
              <div className="text-sm">
                <a href="/forgot-password" className="underline font-regular text-gray-500 hover:text-gray-400">
                  Mot de passe oublié?
                </a>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}
