import React, { useState } from 'react'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import JsBarcode from 'jsbarcode'
import { createCanvas } from 'canvas'
import Modal from '../Modal'

export default function BarCodeModal({ ean, label }: { ean: string; label: string }) {
  const [open, setOpen] = useState(false)
  const canvas = createCanvas(0, 0)

  JsBarcode(canvas, ean)

  const barCodeNode = (
    <div className="my-3">
      <p className="mx-auto flex justify-center">
        <img src={canvas.toDataURL()} />
      </p>
    </div>
  )

  return (
    <div>
      <span className="text-sm text-gray-500">
        {ean}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          <ExternalLinkIcon className="inline-block ml-1 mb-0.5 h-4 w-4" />
        </a>
      </span>
      <Modal
        title={label}
        titleClassName="block w-full text-lg text-center leading-6 font-medium text-gray-900"
        body={barCodeNode}
        open={open}
        setOpen={setOpen}
      />
    </div>
  )
}
