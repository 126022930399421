import React, { useState } from 'react'
import Logo from '../../assets/svg/yuka-logo.svg'
import Card from '../../components/Card'
import { Input } from '../../components/Input'
import { ButtonPrimary } from '../../components/Button'
import API from '../../infrastructure/api'
import { useNotification } from '../../hooks/useNotification'
import { NotificationType } from '../../components/Notification'
import { useHistory } from 'react-router-dom'

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const { setNotification } = useNotification()
  const history = useHistory()

  const validateEmail = () => {
    if (email.length === 0) {
      setError('Obligatoire')
      return false
    }
    const regex = new RegExp('.+@.+\\..+')
    if (!regex.test(email)) {
      setError('Adresse e-mail invalide')
      return false
    }
    return true
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <img className="lg:block h-8 w-auto mx-auto mb-6" src={Logo} />

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Card>
          <form className="py-8 px-4 sm:px-10 space-y-6">
            <div className="space-y-2">
              <h2 className="text-xl font-semibold text-gray-900">Mot de passe oublié ?</h2>
              <p className="text-sm text-gray-500">
                {
                  "Entrez l'adresse e-mail de votre compte, nous allons vous envoyer un e-mail contenant les instructions pour réinitialiser votre mot de passe."
                }
              </p>
            </div>
            <Input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="Saisissez votre addresse e-mail"
              label="Adresse e-mail"
              value={email}
              error={error}
              handleChange={(text: string) => {
                setEmail(text)
                setError(undefined)
              }}
            />
            <div className="flex items-center justify-between">
              <ButtonPrimary
                title="Réinitialiser mon mot de passe"
                onClick={(e) => {
                  e.preventDefault()
                  if (!validateEmail()) {
                    return
                  }
                  API.get(`/auth/forgot-password/${email}`)
                    .then(() => {
                      setNotification({
                        title: 'E-mail envoyé',
                        message:
                          'Vous allez recevoir un e-mail avec les instructions pour réinitialiser votre mot de passe',
                        type: NotificationType.Success,
                      })
                      history.push('/signin')
                    })
                    .catch((err) => {
                      setNotification({
                        title: 'Erreur',
                        message: err.response.data.message,
                        type: NotificationType.Error,
                      })
                    })
                }}
              />
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}
