import React from 'react'
import Card from '../Card'
import CosmeticIngredient, { Dangerousness } from '../../types/CosmeticIngredient'

export interface HealthCalculationResult {
  grade?: HealthGradeResult
  isLoading: boolean
  ingredients: CosmeticIngredient[]
}

export interface HealthGradeResult {
  total?: number
}

export default function CosmeticHealthGradeDetails({ grade, ingredients, isLoading }: HealthCalculationResult) {
  const { total } = grade || {}

  const getNbIngredientsByDangerousness = (dangerousness: Dangerousness): number => {
    return ingredients.reduce((acc, ingredient) => {
      if (ingredient.dangerousness === dangerousness) {
        acc++
      }

      return acc
    }, 0)
  }

  const getResultColor = (total: number): string => {
    if (total >= 75) {
      return 'bg-green-700'
    }

    if (total >= 50) {
      return 'bg-green-400'
    }

    if (total >= 25) {
      return 'bg-orange-400'
    }

    return 'bg-red-600'
  }

  return (
    <div>
      <Card>
        <div className="bg-white sm:rounded">
          <div className="p-5">
            <div className="flex justify-between items-center border-b border-gray-200 pb-2">
              <p className="text-lg leading-6 font-medium text-gray-900">Score</p>
            </div>
            {!total && <p className="text-gray-500 text-sm pt-5">Saisissez les informations du produit.</p>}
            {total !== undefined && (
              <div className="space-y-3 pt-5">
                {getNbIngredientsByDangerousness(Dangerousness.HIGH) > 0 && (
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-sm">
                      <span className="bg-red-500 inline-block rounded-full h-2 w-2"></span>
                      <span className="ml-3">À risque</span>
                    </p>
                    <p className="text-gray-900 font-medium text-sm text-right">
                      <span className="text-sm">{getNbIngredientsByDangerousness(Dangerousness.HIGH)}</span>
                    </p>
                  </div>
                )}
                {getNbIngredientsByDangerousness(Dangerousness.MODERATE) > 0 && (
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-sm">
                      <span className="bg-orange-400 inline-block rounded-full h-2 w-2"></span>
                      <span className="ml-3">Risque modéré</span>
                    </p>
                    <p className="text-gray-900 font-medium text-sm text-right">
                      <span className="text-sm">{getNbIngredientsByDangerousness(Dangerousness.MODERATE)}</span>
                    </p>
                  </div>
                )}
                {getNbIngredientsByDangerousness(Dangerousness.LOW) > 0 && (
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-sm">
                      <span className="bg-yellow-400 inline-block rounded-full h-2 w-2"></span>
                      <span className="ml-3">Risque faible</span>
                    </p>
                    <p className="text-gray-900 font-medium text-sm text-right">
                      <span className="text-sm">{getNbIngredientsByDangerousness(Dangerousness.LOW)}</span>
                    </p>
                  </div>
                )}
                {getNbIngredientsByDangerousness(Dangerousness.NONE) > 0 && (
                  <div className="flex justify-between items-center">
                    <p className="text-gray-500 text-sm">
                      <span className="bg-green-500 inline-block rounded-full h-2 w-2"></span>
                      <span className="ml-3">Sans risque</span>
                    </p>
                    <p className="text-gray-900 font-medium text-sm text-right">
                      <span className="text-sm">{getNbIngredientsByDangerousness(Dangerousness.NONE)}</span>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="bg-gray-50 sm:rounded">
            <div className="py-3 px-6 sm:border-t sm:border-gray-200">
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-sm">Total</p>
                <p className="relative text-gray-900 font-medium text-sm text-right">
                  {isLoading && (
                    <svg
                      className="animate-spin h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <span className={isLoading ? 'hidden' : ''}>
                    <span
                      className={
                        (total !== undefined ? getResultColor(total) : '') + ' inline-block rounded-full h-2 w-2'
                      }
                    ></span>
                    {total !== undefined && (
                      <span className="ml-2">
                        {total}
                        <span className="text-xs text-gray-400">/100</span>
                      </span>
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
