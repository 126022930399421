import React, { useState, Dispatch, SetStateAction } from 'react'
import Modal from '../Modal'
import { ButtonPrimary, ButtonWhite } from '../Button'
import { useNotification } from '../../hooks/useNotification'
import { NotificationType } from '../Notification'
import API from '../../infrastructure/api'
import { Product } from '../../types/Product'
import { CosmeticProduct } from '@/types/CosmeticProduct'

export default function DeleteProductModal({
  product,
  endpoint,
  open,
  setOpen,
  onSuccess,
}: {
  endpoint: string
  product?: Product | CosmeticProduct
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccess: () => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const { setNotification } = useNotification()

  if (!product) return <></>

  return (
    <Modal
      title="Supprimer un produit"
      body={
        <div className="p-6 text-sm text-gray-700">
          Êtes-vous sûr de vouloir supprimer le produit{' '}
          <span className="text-gray-900 font-medium">{product.name}</span> ?{' '}
          <p className="mt-3">Cette action est irréversible.</p>
        </div>
      }
      footer={
        <div className="flex space-x-3 justify-center">
          <ButtonPrimary
            type="submit"
            title={'Supprimer'}
            color="red"
            loading={loading}
            onClick={() => {
              setLoading(true)
              API.delete(endpoint)
                .then(() => {
                  setLoading(false)
                  setOpen(false)
                  onSuccess()
                })
                .catch(() => {
                  setLoading(false)
                  setOpen(false)
                  setNotification({
                    title: 'Erreur',
                    message: `Impossible de supprimer ${product.name}`,
                    type: NotificationType.Error,
                  })
                })
            }}
          />
          <ButtonWhite title="Annuler" disabled={loading} onClick={() => setOpen(false)} />
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  )
}
