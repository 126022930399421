import Selectable from './Selectable'

export enum IngredientGroup {
  WildFish = 'wild-fish',
  FarmedFish = 'farmed-fish',
}

export interface Ingredient extends Selectable {
  groups: { slug: IngredientGroup }[]
}

export enum RegionType {
  Country = 'country',
  Continent = 'continent',
  Sea = 'sea',
  Ocean = 'ocean',
  World = 'world',
}

export interface Region extends Selectable {
  type: RegionType
  code: string
}

export interface Origin {
  ingredient?: Ingredient
  regions?: Region[]
  percentage?: string
}
