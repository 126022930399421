import React, { useState } from 'react'
import { classNames } from '../../functions/classNames'

interface InputProps {
  id: string
  name: string
  type: string
  value?: string | number
  error?: string | undefined
  displayRedBorder?: boolean
  trailingText?: string
  trailingTextClassName?: string
  step?: number
  min?: number
  max?: number
  placeholder?: string
  autoComplete?: string
  width?: string
  height?: string
  label?: string
  disabled?: boolean
  autoFocus?: boolean
  trailingContent?: React.ReactNode
  textAlign?: string
  className?: string
  handleChange: (value: any) => void
}

export function Input({
  id,
  name,
  type,
  value,
  error,
  displayRedBorder,
  trailingText,
  trailingTextClassName,
  step,
  min,
  max,
  handleChange,
  autoComplete,
  width,
  height,
  placeholder,
  label,
  disabled,
  autoFocus,
  trailingContent,
  textAlign,
  className,
}: InputProps) {
  const [focused, setFocused] = useState(false)

  return (
    <div>
      {label && <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>}
      <div className={trailingContent ? 'flex space-x-2 relative' : ''}>
        <div
          className={classNames(
            className ?? '',
            width ?? '',
            trailingContent ? 'flex-1' : 'flex',
            'border space-y-0 rounded-md',
            disabled ? 'bg-gray-100' : 'bg-white',
            error || displayRedBorder
              ? 'border-red-400'
              : focused
              ? 'ring-2 ring-blue-400 border-transparent'
              : 'border-gray-300',
            'relative'
          )}
        >
          <input
            type={type}
            name={name}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            id={id}
            value={type === 'number' && isNaN(value as number) ? '' : value}
            step={step}
            min={min}
            max={max}
            placeholder={placeholder}
            autoComplete={autoComplete ?? 'off'}
            disabled={disabled}
            onChange={(e) => handleChange(type === 'number' ? parseFloat(e.target.value) : e.target.value)}
            className={classNames(
              height ?? '',
              textAlign ?? 'text-left',
              'flex-1 block w-full pr-1 rounded-md placeholder-gray-400 sm:text-sm border-none focus:outline-none focus:ring-0 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400'
            )}
            autoFocus={autoFocus}
            onKeyDown={(evt) => type === 'number' && ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          />

          {trailingText && (
            <div
              className={classNames(
                'inset-y-0 right-0 pr-3 flex items-center pointer-events-none select-none',
                trailingTextClassName ?? ''
              )}
            >
              <span className={classNames('truncate sm:text-sm', disabled ? 'text-gray-400' : 'text-gray-500')}>
                {trailingText}
              </span>
            </div>
          )}
        </div>
        {trailingContent && trailingContent}
      </div>
      {error && <p className="mt-2 text-sm text-red-400">{error}</p>}
    </div>
  )
}
