import React, { useState, useRef } from 'react'
import { classNames } from '../../functions/classNames'
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid'

export function ImageDragAndDrop({
  imageURL,
  setImageURL,
}: {
  imageURL?: string
  setImageURL: React.Dispatch<React.SetStateAction<string | undefined>>
}) {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)
  const [isMouseOverDropArea, setIsMouseOverDropArea] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const updateImage = (file: File) => {
    const types = ['image/jpeg', 'image/jpg', 'image/png']
    if (!types.includes(file.type)) {
      setError("L'image doit être au format jpeg ou png.")
      return
    }
    if (file.size > 2000000) {
      setError("L'image doit faire 2MB maximum.")
      return
    }
    setError(undefined)
    const fileURL = URL.createObjectURL(file)
    if (imageURL) URL.revokeObjectURL(imageURL)
    setImageURL(fileURL)
  }

  const clearImage = () => {
    if (imageURL) URL.revokeObjectURL(imageURL)
    setImageURL(undefined)
    setIsImageLoaded(false)
  }

  return (
    <>
      {imageURL ? (
        <div className="flex-shrink-0 flex items-center">
          <div className="relative flex items-start place-content-center">
            <img
              className="h-auto w-auto max-h-32 max-w-xs object-scale-down hover:cursor-pointer"
              src={imageURL}
              alt="Product photo"
              onLoad={() => setIsImageLoaded(true)}
              onClick={() => window.open(imageURL)}
            />
            {isImageLoaded && (
              <button
                type="button"
                className="ml-6 text-gray-800 text-sm font-medium hover:text-gray-600"
                onClick={(e) => {
                  e.preventDefault()
                  clearImage()
                }}
              >
                <XCircleIcon className="flex-shrink-0 h-6 w-6 text-gray-400 hover:text-gray-500" />
              </button>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            className={classNames(
              'max-w-lg flex justify-center px-6 pt-6 pb-6 border-2 border-dashed rounded-md cursor-pointer',
              isMouseOverDropArea
                ? 'bg-green-50 border-green-200 text-gray-300'
                : 'bg-gray-50 border-gray-200 text-gray-400'
            )}
            onDrop={(e) => {
              e.preventDefault()
              updateImage(e.dataTransfer.files[0])
              setIsMouseOverDropArea(false)
            }}
            onDragLeave={() => setIsMouseOverDropArea(false)}
            onDragOver={(e) => {
              e.preventDefault()
              setError(undefined)
              setIsMouseOverDropArea(true)
            }}
            onClick={() => {
              setError(undefined)
              inputFileRef?.current?.click()
            }}
          >
            <div className="select-none text-center pointer-events-none">
              <p className="text-sm">Glissez une photo ici, ou cliquez pour sélectionner</p>
            </div>
            <input
              type="file"
              id="file"
              accept=".jpg,.jpeg,.png"
              ref={inputFileRef}
              className="hidden"
              onChange={(e) => {
                const files = e.target.files
                if (files && files.length > 0) updateImage(files[0])
              }}
            />
          </div>
          {error && (
            <div className="flex items-center space-x-2 mt-2">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
              <span className="text-sm text-red-500">{error}</span>
            </div>
          )}
        </>
      )}
    </>
  )
}
