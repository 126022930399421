import Category from './Category'
import { Origin } from './Origin'
import { EcoScore } from './EcoScore'
import Selectable from './Selectable'
import { PackagingComponent } from './PackagingComponent'

export enum ProductStatus {
  Draft = 'DRAFT',
  Synchronizing = 'SYNCHRONIZING',
  Synchronized = 'SYNCHRONIZED',
}

export interface Product {
  id: number
  ean: string
  brand: string
  parent?: Product
  status: ProductStatus
  ecoScore?: EcoScore
  updated: Date
  frontPhotoUrl?: string
  name: string
  category?: Category
  labels: { slug: string; name: string }[]
  origins: Origin[]
  packagingComponents: {
    component: { formatSlug: string; formatName: string; materialSlug?: string; materialName?: string }
    specificities: { name: string; value: string }[]
  }[]
  fishingTechniques: { slug: string; name: string }[]
  ingredientsList: string
  calories: number
  fat: number
  saturatedFat: number
  carbohydrates: number
  sugar: number
  proteins: number
  fibers: number
  salt: number
  fruits: number
  healthGrade: number
}

export interface ProductForm {
  name?: string
  ean?: string
  parent?: Product
  category?: Category[]
  origins?: Origin[]
  packagingComponents?: PackagingComponent[]
  fishingTechniques?: Selectable[]
  ingredientsList?: string
  labels?: Selectable[]
  brand?: Selectable
  initialEan?: string
  frontPhotoUrl?: string
  localFrontImageUrl?: string
  deleteFrontPhoto?: boolean
  synchronizeProductAfterUpload?: boolean
  calories?: number
  fat?: number
  saturatedFat?: number
  fibers?: number
  salt?: number
  sugar?: number
  carbohydrates?: number
  proteins?: number
  fruits?: number
  healthGrade?: number
}
