import React from 'react'
import Menu from '../../components/Menu'
import { PencilAltIcon, TrashIcon, CheckIcon } from '@heroicons/react/solid'

export default function ProductActionMenu({
  displaySynchronizeAction,
  onEditClick,
  onSynchronizeClick,
  onDeleteClick,
}: {
  displaySynchronizeAction: boolean
  onEditClick: () => void
  onSynchronizeClick: () => void
  onDeleteClick: () => void
}) {
  const topActions = [
    {
      title: 'Modifier',
      icon: PencilAltIcon,
      onClick: onEditClick,
    },
  ]
  const bottomActions = [
    {
      title: 'Supprimer',
      icon: TrashIcon,
      onClick: onDeleteClick,
    },
  ]

  if (displaySynchronizeAction) {
    topActions.unshift({
      title: 'Publier',
      icon: CheckIcon,
      onClick: onSynchronizeClick,
    })
  }

  return <Menu topActions={topActions} bottomActions={bottomActions} />
}
