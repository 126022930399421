import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './pages/App'
import './i18n'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
