import React, { useState, useEffect } from 'react'
import Card from '../../components/Card'
import { Row } from '../../components/Row'
import { Input } from '../../components/Input'
import { ButtonWhite, ButtonPrimary } from '../../components/Button'
import { useCreateOrUpdateProduct } from '../../hooks/cosmetic/useCreateOrUpdateProduct'
import { SelectSingle } from '../../components/Select'
import { useAuth } from '../../hooks/useAuth'
import Category from '../../types/Category'
import API from '../../infrastructure/api'
import { Switch } from '@headlessui/react'
import { classNames } from '../../functions/classNames'
import { ImageDragAndDrop } from '../../components/ImageDragAndDrop'
import { useHistory } from 'react-router-dom'
import { object, string, number, mixed } from 'yup'
import { useYup } from '@/hooks/useYup'
import { ProductStatus } from '../../types/Product'
import Selectable from '@/types/Selectable'
import { CosmeticForm } from '@/types/CosmeticForm'
import CosmeticHealthGradeDetails, { HealthGradeResult } from '@/components/CosmeticHealthGradeDetails'
import axios from 'axios'
import CosmeticIngredient from '@/types/CosmeticIngredient'

const healthGradeRules = {
  category: mixed().required('La catégorie est requise'),
  composition: string()
    .transform((value) => value || '')
    .required('La composition INCI est requise'),
}

const formSchema = object({}).shape({
  ean: string()
    .min(5, "L'EAN doit contenir 5 caractères au minimum")
    .max(25, "L'EAN doit contenir 25 caractères au maximum")
    .required("L'EAN est requis"),
  name: string().required('Le nom est requis'),
  brand: mixed().required('La marque est requise'),
  gender: mixed().required('Le genre est requis'),
  quantity: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'La quantité est requise')
    .required('La quantité est requise'),
  ...healthGradeRules,
})

const healthGradeSchema = object().shape(healthGradeRules)

export default function CosmeticFormPage({ productEan }: { productEan?: string }) {
  const { user } = useAuth()
  const history = useHistory()
  const [isBio, setIsBio] = useState<boolean>(false)
  const [didTapPrimaryButton, setDidTapPrimaryButton] = useState<boolean>(false)
  const [localFrontImageUrl, setLocalFrontImageUrl] = useState<string | undefined>(undefined)
  const { isCreatingProduct, createOrUpdateProduct } = useCreateOrUpdateProduct()
  const [categories, setCategories] = useState<Category[]>([])
  const [isLoadingHealthGrade, setIsLoadingHealthGrade] = useState<boolean>(false)
  const [healthGradeResult, setHealthGradeResult] = useState<HealthGradeResult | undefined>(undefined)
  const [detectedIngredients, setDetectedIngredients] = useState<CosmeticIngredient[]>([])

  const [genderOptions] = useState<Selectable[]>([
    { slug: 'M', name: 'Mixte' },
    { slug: 'H', name: 'Homme' },
    { slug: 'F', name: 'Femme' },
    { slug: 'E', name: 'Enfant' },
  ])
  const [form, setForm] = useState<CosmeticForm>({
    status: ProductStatus.Draft,
    quantityUnit: 'cl',
  })
  const { validate: validateHealthGrade } = useYup(form, healthGradeSchema, {}, true)
  const { errors, validate } = useYup(form, formSchema)
  const quantityUnitOptions = [
    { slug: 'cl', name: 'centilitre (cl)' },
    { slug: 'cm', name: 'centimètre (cm)' },
    { slug: 'dl', name: 'décilitre (dl)' },
    { slug: 'g', name: 'gramme (g)' },
    { slug: 'kg', name: 'kilogramme (kg)' },
    { slug: 'l', name: 'litre (l)' },
    { slug: 'm', name: 'mètre (m)' },
    { slug: 'mg', name: 'miligramme (mg)' },
    { slug: 'ml', name: 'mililitre (ml)' },
    { slug: 'pair', name: 'paire' },
    { slug: 'unit', name: 'pièce' },
  ]

  useEffect(() => {
    Promise.all([API.get<Category[]>('/cosmetic/categories')]).then((responses) => {
      setCategories(responses[0].data)
    })
  }, [])

  useEffect(() => {
    if (!productEan) return
    API.get(`/cosmetic/products/${productEan}`).then(({ data: produit }) => {
      setForm({
        ...produit,
        brand: { slug: produit.brand, name: produit.brand },
        gender: genderOptions.find((gender) => gender.slug == produit.gender),
        quantityUnit: produit.quantityUnit || 'cl',
      })
      if (produit.labels.filter((label: Selectable) => label.slug == 'organic').length > 0) {
        setIsBio(true)
      }
    })
  }, [productEan, genderOptions])

  useEffect(() => {
    const calculateHealthGrade = async () => {
      if (await validateHealthGrade()) {
        const cancelTokenSource = axios.CancelToken.source()
        setIsLoadingHealthGrade(true)
        API.post(
          '/cosmetic/health-grade/evaluate',
          {
            category: form.category?.slug,
            ingredientsList: form.composition,
          },
          { cancelToken: cancelTokenSource.token }
        )
          .then(({ data }) => {
            const { grade, ingredients } = data
            setHealthGradeResult(grade)
            setDetectedIngredients(
              ingredients.map(({ inci, dangerousnessLevel }: { inci: string; dangerousnessLevel: string }) => {
                return {
                  inci,
                  dangerousness: dangerousnessLevel,
                }
              })
            )
            setIsLoadingHealthGrade(false)
          })
          .catch(() => {
            setIsLoadingHealthGrade(false)
          })

        return () => {
          cancelTokenSource.cancel()
        }
      } else {
        setHealthGradeResult(undefined)
        setDetectedIngredients([])
      }
    }

    calculateHealthGrade()
  }, [form.category, form.composition])

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    synchronizeProductAfterUpload?: boolean
  ) => {
    e.preventDefault()

    if (await validate()) {
      await createOrUpdateProduct({
        ...form,
        localFrontImageUrl,
        deleteFrontPhoto: localFrontImageUrl === undefined && form?.frontPhotoUrl === undefined,
        labels: isBio ? ['organic'] : [],
        synchronizeProductAfterUpload,
        healthGrade: healthGradeResult?.total,
      })

      history.push('/cosmetic/products/1')
    }
  }

  return (
    <div className="m-6">
      <div className="max-w-screen-2xl mx-auto lg:grid lg:grid-cols-4 lg:gap-6 lg:space-y-0 space-y-6">
        <div className="lg:col-span-3">
          <form className="space-y-8 divide-y divide-gray-200">
            <Card>
              <div className="m-6 space-y-8 divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {productEan ? 'Modifier un produit' : 'Créer un produit'}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Renseignez les informations du produit avant de le sauvegarder.
                    </p>
                  </div>

                  <div className="mt-1 sm:mt-5 space-y-6 sm:space-y-5">
                    <Row title="EAN" borderTop>
                      <div className="block w-full max-w-lg">
                        <Input
                          id="ean"
                          name="ean"
                          type="text"
                          placeholder="0000000000000"
                          error={errors.ean}
                          value={form?.ean || ''}
                          handleChange={(ean) => setForm({ ...form, ean })}
                        />
                      </div>
                    </Row>

                    <Row title="Nom">
                      <div className="block w-full max-w-lg">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Nom du produit"
                          error={errors.name}
                          value={form?.name || ''}
                          handleChange={(name) => setForm({ ...form, name })}
                        />
                      </div>
                    </Row>

                    <Row title="Marque">
                      <div className="block w-full max-w-lg">
                        <SelectSingle
                          placeholder="Marque"
                          options={
                            user?.cosmeticsBrands?.map((brand) => {
                              return { slug: brand, name: brand }
                            }) ?? []
                          }
                          selectedValue={form?.brand}
                          error={errors.brand}
                          handleSelection={(brand) => setForm({ ...form, brand: brand as Selectable })}
                        />
                      </div>
                    </Row>

                    <Row title="Catégorie">
                      <SelectSingle
                        placeholder="Sélectionnez une categorie"
                        searchable
                        error={errors.category}
                        selectedValue={form?.category}
                        options={categories}
                        ignoreAccentsSearch={false}
                        handleSelection={(category) => setForm({ ...form, category: category as Selectable })}
                      />
                    </Row>

                    <Row title="Bio">
                      <Switch
                        checked={isBio}
                        onChange={setIsBio}
                        className={classNames(
                          isBio ? 'bg-green-500' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                        )}
                      >
                        <span
                          className={classNames(
                            isBio ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    </Row>

                    <Row title="Genre">
                      <div className="block w-full max-w-lg">
                        <SelectSingle
                          placeholder="Genre"
                          error={errors.gender}
                          options={genderOptions}
                          selectedValue={form?.gender}
                          handleSelection={(gender) => setForm({ ...form, gender: gender as Selectable })}
                        />
                      </div>
                    </Row>
                    <Row title="Quantité">
                      <div className="block w-full max-w-lg">
                        <Input
                          id="quantity"
                          name="quantity"
                          type="number"
                          error={errors.quantity}
                          step={0.01}
                          placeholder="Quantité"
                          value={form?.quantity || ''}
                          handleChange={(quantity) => setForm({ ...form, quantity: parseFloat(quantity) })}
                          trailingContent={
                            <div className="absolute inset-y-0 right-0 flex items-center">
                              <label htmlFor="currency" className="sr-only">
                                Currency
                              </label>
                              <select
                                id="currency"
                                name="currency"
                                className="focus:ring-blue-400 focus:border-blue-400 text-right h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                                onChange={(e) => setForm({ ...form, quantityUnit: e.target.value })}
                                value={form.quantityUnit || 'cl'}
                              >
                                {quantityUnitOptions.map((option) => (
                                  <option value={option.slug} key={option.slug}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          }
                        />
                      </div>
                    </Row>

                    <Row title="Photo" borderTop>
                      <div className="block w-full max-w-lg">
                        <ImageDragAndDrop
                          imageURL={localFrontImageUrl || form?.frontPhotoUrl}
                          setImageURL={(url) => {
                            setLocalFrontImageUrl(url)
                            setForm({ ...form, frontPhotoUrl: undefined })
                          }}
                        />
                      </div>
                    </Row>

                    <Row title="Composition INCI" borderTop>
                      <textarea
                        id="composition"
                        name="composition"
                        rows={4}
                        onChange={(e) => {
                          const composition = (e.target as HTMLTextAreaElement).value.trim()
                          setForm({ ...form, composition })
                        }}
                        className={classNames(
                          'border-gray-300 max-w-lg shadow-sm block w-full sm:text-sm rounded-md focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400',
                          errors.composition ? 'border-red-400' : 'border-gray-300'
                        )}
                        defaultValue={form?.composition || ''}
                      />
                      {errors.composition && <p className="text-sm text-red-400">{errors.composition}</p>}
                    </Row>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end space-x-3 px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ButtonWhite
                  title={
                    form?.status === ProductStatus.Synchronized ? 'Basculer en brouillon' : 'Enregistrer le brouillon'
                  }
                  type="button"
                  loading={isCreatingProduct && !didTapPrimaryButton}
                  disabled={isCreatingProduct}
                  onClick={(e) => {
                    setDidTapPrimaryButton(false)
                    handleSubmit(e, false)
                  }}
                />
                <ButtonPrimary
                  title={form?.status === ProductStatus.Synchronized ? 'Mettre à jour' : 'Publier le produit'}
                  type="button"
                  loading={isCreatingProduct && didTapPrimaryButton}
                  disabled={isCreatingProduct}
                  onClick={(e) => {
                    setDidTapPrimaryButton(true)
                    handleSubmit(e, true)
                  }}
                />
              </div>
            </Card>
          </form>
        </div>
        <div className="lg:col-span-1">
          <CosmeticHealthGradeDetails
            grade={healthGradeResult}
            isLoading={isLoadingHealthGrade}
            ingredients={detectedIngredients}
          />
        </div>
      </div>
    </div>
  )
}
