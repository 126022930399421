import { Region, Ingredient, IngredientGroup, RegionType } from '../types/Origin'

export function getCompatibleRegionsWithIngredient(regions: Region[], ingredient: Ingredient): Region[] {
  const groups = ingredient.groups
  const isWildFish = groups.find((group) => group.slug === IngredientGroup.WildFish) !== undefined
  const isFarmedFish = groups.find((group) => group.slug === IngredientGroup.FarmedFish) !== undefined
  if (isWildFish && !isFarmedFish) {
    return regions.filter((region) => region.type === RegionType.Sea || region.type === RegionType.Ocean)
  } else if (!isWildFish) {
    return regions.filter(
      (region) =>
        region.type === RegionType.Country || region.type === RegionType.Continent || region.type === RegionType.World
    )
  } else {
    return regions
  }
}
