import React from 'react'
import { useTitle } from '../../hooks/useTitle'

export default function PageNotFound() {
  useTitle('Page introuvable')

  return (
    <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex md:-mt-52">
          <p className="text-4xl font-extrabold text-green-600 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page introuvable</h1>
              <p className="mt-1 text-base text-gray-500">{"Vérifiez l'URL dans la barre d'adresse et réessayez."}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a
                href="/"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none"
              >
                {"Revenir à l'accueil"}
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
