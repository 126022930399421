import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../components/Card'
import HealthGradeForm from '../../components/FoodHealthGrade/Form'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import { useCalculateHealthGrade } from '../../hooks/useCalculateFoodHealthGrade'
import HealthGradeDetails from '../../components/FoodHealthGradeDetails'
import HealthGradeCalculatorPrefill from '../../types/HealthGradeCalculatorPrefill'
import { useTitle } from '../../hooks/useTitle'

export default function HealthGradeCalculator(healthGradePrefillData: HealthGradeCalculatorPrefill) {
  const { t } = useTranslation('health-grade')
  const {
    categories,
    calculateHealthGrade,
    resetHealthGradeDetails,
    resetHealthGradeForm,
    isLoadingHealthGrade,
    healthGradeResult,
    detectedAdditives,
    formData,
    setters,
    errors,
  } = useCalculateHealthGrade(healthGradePrefillData)

  useTitle(t('page_title'))

  useEffect(() => {
    return calculateHealthGrade(false)
  }, [calculateHealthGrade])

  return (
    <div className="m-6">
      <div className="max-w-screen-2xl mx-auto lg:grid lg:grid-cols-4 lg:gap-6 lg:space-y-0 space-y-6">
        <div className="lg:col-span-3">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={(e) => {
              e.preventDefault()
              calculateHealthGrade()
            }}
          >
            <Card>
              <div className="m-6 space-y-8 divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{t('heading.title')}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('heading.text')}</p>
                  </div>
                  <HealthGradeForm
                    categories={categories}
                    formData={formData}
                    setters={setters}
                    detectedAdditives={detectedAdditives}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end space-x-3 px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ButtonWhite
                  title={t('action_buttons.reset')}
                  type="reset"
                  onClick={() => {
                    resetHealthGradeDetails()
                    resetHealthGradeForm()
                  }}
                />
                <ButtonPrimary
                  title={t('action_buttons.calculate')}
                  type="submit"
                  loading={isLoadingHealthGrade}
                  className="w-24"
                />
              </div>
            </Card>
          </form>
        </div>
        <HealthGradeDetails grade={healthGradeResult} additives={detectedAdditives} isLoading={isLoadingHealthGrade} />
      </div>
    </div>
  )
}
