import Category from './Category'

export enum ProductStatus {
  Draft = 'DRAFT',
  Synchronizing = 'SYNCHRONIZING',
  Synchronized = 'SYNCHRONIZED',
}

export interface CosmeticProduct {
  id: number
  ean: string
  name: string
  brand?: string
  category?: Category
  isBio?: boolean
  gender?: string
  quantity?: number
  quantityUnit?: string
  localFrontImageUrl?: string
  deleteFrontPhoto?: boolean
  composition?: string
  updated: Date
  status: ProductStatus
  labels?: string[]
  frontPhotoUrl?: string
  healthGrade?: number
}
