import React from 'react'
import { classNames } from '../../functions/classNames'

interface TextareaProps {
  id: string
  name: string
  rows?: number
  value?: string | number
  error?: string | undefined
  displayRedBorder?: boolean
  placeholder?: string
  disabled?: boolean
  className?: string
  handleChange?: (value: any) => void
  handleBlur?: (value: any) => void
}

export function Textarea({
  id,
  name,
  rows,
  value,
  error,
  displayRedBorder,
  placeholder,
  disabled,
  className,
  handleChange,
  handleBlur,
}: TextareaProps) {
  return (
    <div>
      <textarea
        id={id}
        name={name}
        rows={rows || 4}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => handleChange?.(e.target.value.trim())}
        onBlur={(e) => handleBlur?.(e.target.value.trim())}
        className={classNames(
          className ?? '',
          'max-w-lg shadow-sm block w-full sm:text-sm rounded-md focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400',
          error || displayRedBorder ? 'border-red-400' : 'border-gray-300'
        )}
        defaultValue={value}
      />
      {error && <p className="mt-2 text-sm text-red-400">{error}</p>}
    </div>
  )
}
