import { NotificationType } from '@/components/Notification'
import { useState, useEffect } from 'react'
import { SchemaOf } from 'yup'
import { useNotification } from './useNotification'

type Values = {
  [field: string]: any
}

type ValidationError = {
  path: string
  message: string
}

type UseYupOptions = {
  validateOnChange?: boolean
}

export function useYup<T extends Values>(
  values: T,
  validationSchema: SchemaOf<any>,
  options: UseYupOptions = {},
  hideNotifications?: boolean
) {
  const [errors, setErrors] = useState<Values>([])
  const { setNotification } = useNotification()

  const validate = () => {
    setErrors([])

    return validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        return true
      })
      .catch((validatorError) => {
        const newErrors: Values = []
        validatorError.inner.forEach((error: ValidationError) => {
          newErrors[error.path] = error.message
        })
        setErrors(newErrors)
        if (Object.values(newErrors).length > 0 && !hideNotifications) {
          setNotification({
            title: 'Merci de corriger les éléments suivants',
            message: Object.values(newErrors).filter(
              (value, index) => Object.values(newErrors).indexOf(value) === index
            ),
            type: NotificationType.Error,
          })
        }
        return false
      })
  }

  useEffect(() => {
    options.validateOnChange && validate()
  })

  return {
    validate,
    errors,
  }
}

export default useYup
