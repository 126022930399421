import React from 'react'
import Menu from '../../components/Menu'
import { PencilAltIcon, TrashIcon, DownloadIcon, UsersIcon } from '@heroicons/react/solid'

export default function UsersActionMenu({
  onEditClick,
  onUpdateProductsClick,
  onImpersonateClick,
  onDeleteClick,
}: {
  onEditClick: () => void
  onUpdateProductsClick: () => void
  onImpersonateClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <Menu
      topActions={[
        {
          title: 'Modifier',
          icon: PencilAltIcon,
          onClick: onEditClick,
        },
        {
          title: 'Charger les produits',
          icon: DownloadIcon,
          onClick: onUpdateProductsClick,
        },
        {
          title: 'Imiter',
          icon: UsersIcon,
          onClick: onImpersonateClick,
        },
      ]}
      bottomActions={[
        {
          title: 'Supprimer',
          icon: TrashIcon,
          onClick: onDeleteClick,
        },
      ]}
    />
  )
}
