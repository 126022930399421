import Category from '../types/Category'
import { useState, useEffect } from 'react'
import { useAuth } from './useAuth'
import API from '../infrastructure/api'

export function useFetchFilterData(): {
  userCategories: Category[]
  brands: string[]
} {
  const [categories, setCategories] = useState<Category[]>([])
  const { user } = useAuth()

  useEffect(() => {
    if (!user) {
      return
    }

    API.get('/food/categories', { params: { userId: user.id } }).then((response) => setCategories(response.data))
  }, [user])

  return { userCategories: categories, brands: user?.foodBrands ?? [] }
}
