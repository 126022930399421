import { useState, useEffect, ReactNode } from 'react'
import { createPortal } from 'react-dom'

// https://github.com/tailwindlabs/headlessui/blob/d60d2a558fc92c3d8e1241983deb3b55a1b965db/packages/%40headlessui-react/pages/menu/menu-with-popper.tsx#L90
export default function Portal(props: { children: ReactNode }) {
  const { children } = props
  const [mounted, setMounted] = useState(false)

  useEffect(() => setMounted(true), [])

  if (!mounted) return null
  return createPortal(children, document.body)
}
