import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import { Input } from '../../components/Input'
import API from '../../infrastructure/api'
import Modal from '../../components/Modal'

export default function ChangePasswordModal({
  open,
  setOpen,
  onSuccess,
  onError,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccess: () => void
  onError: () => void
}) {
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newConfirmPassword, setNewConfirmPassword] = useState<string>('')
  const [error, setError] = useState<{ current?: string; new?: string; confirm?: string } | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setCurrentPassword('')
      setNewPassword('')
      setNewConfirmPassword('')
      setError(undefined)
    }
  }, [open])

  const validateForm = () => {
    if (currentPassword.length === 0) {
      setError({ current: 'Requis' })
      return false
    }
    if (newPassword.length === 0) {
      setError({ new: 'Requis' })
      return false
    }
    if (newConfirmPassword.length === 0) {
      setError({ confirm: 'Requis' })
      return false
    }
    if (currentPassword === newPassword) {
      setError({ new: 'Vous avez déjà utilisé ce mot de passe.' })
      return false
    }
    if (newPassword !== newConfirmPassword) {
      setError({ confirm: 'Les mots de passe ne correspondent pas.' })
      return false
    }
    if (newPassword.length < 8) {
      setError({ new: '8 caractères minimum' })
      return false
    }
    return true
  }

  const changePassword = () => {
    if (!validateForm()) {
      return
    }
    setLoading(true)
    API.post('/auth/change-password', { currentPassword: currentPassword, newPassword: newPassword })
      .then(() => {
        setLoading(false)
        setOpen(false)
        onSuccess()
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 403) {
          setError({ current: 'Mot de passe incorrect' })
        } else {
          setOpen(false)
          onError()
        }
      })
  }

  return (
    <Modal
      title="Mot de passe"
      body={
        <div className="m-4 sm:m-6 mb-6 sm:mb-10 space-y-5">
          <div>
            <Input
              id="current-password"
              name="current-password"
              type="password"
              autoComplete="current-password"
              label="Mot de passe actuel"
              placeholder="Mot de passe actuel"
              disabled={loading}
              value={currentPassword}
              error={error?.current}
              handleChange={(text) => {
                setCurrentPassword(text)
                setError(undefined)
              }}
            />
          </div>
          <div>
            <Input
              id="new-password"
              name="new-password"
              type="password"
              autoComplete="new-password"
              label="Nouveau mot de passe"
              placeholder="Nouveau mot de passe"
              disabled={loading}
              value={newPassword}
              error={error?.new}
              handleChange={(text) => {
                setNewPassword(text)
                setError(undefined)
              }}
            />
          </div>
          <div>
            <Input
              id="new-confirm-password"
              name="new-confirm-password"
              type="password"
              autoComplete="new-password"
              label="Confirmation"
              placeholder="Confirmation"
              disabled={loading}
              value={newConfirmPassword}
              error={error?.confirm}
              handleChange={(text) => {
                setNewConfirmPassword(text)
                setError(undefined)
              }}
            />
          </div>
        </div>
      }
      footer={
        <div className="space-x-3">
          <ButtonPrimary title="Changer mon mot de passe" onClick={changePassword} />
          <ButtonWhite title="Annuler" onClick={() => setOpen(false)} />
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  )
}
