import React, { useState } from 'react'
import Logo from '../../assets/svg/yuka-logo.svg'
import useQuery from '../../hooks/useQuery'
import Card from '../../components/Card'
import { ButtonPrimary } from '../../components/Button'
import { Input } from '../../components/Input'
import API from '../../infrastructure/api'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../../hooks/useNotification'
import { NotificationType } from '../../components/Notification'

export default function ResetPasswordPage() {
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const history = useHistory()
  const { setNotification } = useNotification()
  const query = useQuery()
  const token = query.get('token')
  const email = query.get('email')

  const validatePassword = () => {
    if (password.length === 0) {
      setError('Requis')
      return false
    }
    if (password.length < 8) {
      setError('8 caractères minimum')
      return false
    }
    return true
  }

  if (!token || !email) {
    return <p>Unauthorized</p>
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <img className="lg:block h-8 w-auto mx-auto mb-6" src={Logo} />

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Card>
          <form className="py-8 px-4 sm:px-10 space-y-6">
            <div className="space-y-2">
              <h2 className="text-xl font-semibold text-gray-900">Réinitialiser mon mot de passe</h2>
              <p className="text-sm text-gray-500">{"Saisissez un nouveau mot de passe d'au moins 8 caractères."}</p>
            </div>
            <Input
              id="new-password"
              name="new-password"
              type="password"
              autoComplete="new-password"
              placeholder="Nouveau mot de passe"
              label="Mot de passe"
              value={password}
              error={error}
              handleChange={(text: string) => {
                setPassword(text)
                setError(undefined)
              }}
            />
            <div className="flex items-center justify-between">
              <ButtonPrimary
                title="Réinitialiser mon mot de passe"
                onClick={(e) => {
                  e.preventDefault()
                  if (!validatePassword()) {
                    return
                  }
                  const body = {
                    token: token,
                    password: password,
                    email: email,
                  }
                  API.post(`/auth/reset-password`, body)
                    .then(() => {
                      setNotification({
                        title: 'Mot de passe mis à jour',
                        message: 'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe',
                        type: NotificationType.Success,
                      })
                      history.push('/signin')
                    })
                    .catch((err) => {
                      setNotification({
                        title: 'Impossible de changer votre mot de passe',
                        message: err.response.data.message,
                        type: NotificationType.Error,
                      })
                    })
                }}
              />
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}
