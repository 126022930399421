import React, { Dispatch, SetStateAction } from 'react'

export function Checkbox({
  id,
  title,
  checked,
  setChecked,
}: {
  id: string
  title?: string
  checked: boolean
  setChecked: Dispatch<SetStateAction<boolean>>
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          type="checkbox"
          className="focus:ring-blue-500 focus:ring-opacity-50 h-4 w-4 text-green-500 border-gray-300 rounded cursor-pointer"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      </div>
      {title && (
        <div className="ml-2 text-sm">
          <label className="font-medium text-gray-700 pointer-events-none">{title}</label>
        </div>
      )}
    </div>
  )
}
