import React, { useState, Fragment, useEffect } from 'react'
import { Transition, Popover } from '@headlessui/react'
import { FilterIcon } from '@heroicons/react/solid'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import { classNames } from '../../functions/classNames'
import StatusExpanded from './StatusExpanded'
import BrandExpanded from './BrandExpanded'
import CategoryExpanded from './CategoryExpanded'
import EcoScoreExpanded from './EcoScoreExpanded'
import { useFetchFilterData } from '../../hooks/useFetchFilterData'

export interface Filters {
  status?: 'draft' | 'published' | undefined
  brand?: string
  categorySlug?: string
  ecoScore?: string
}

export default function FilterModal({
  activeFilters,
  setActiveFilters,
}: {
  activeFilters: Filters
  setActiveFilters: React.Dispatch<React.SetStateAction<Filters>>
}) {
  const [nextFilters, setNextFilters] = useState<Filters>(activeFilters)
  const { userCategories, brands } = useFetchFilterData()

  useEffect(() => setNextFilters(activeFilters), [activeFilters])

  return (
    <Popover className="relative flex justify-end items-center">
      {({ open }) => (
        <>
          <Popover.Button as="div">
            <ButtonWhite
              title="Filtrer"
              leftIcon={<FilterIcon className="h-4 w-4" />}
              rightContent={
                Object.keys(nextFilters).length > 0 ? (
                  <p className="text-green-500 border-l pl-2">{Object.keys(nextFilters).length}</p>
                ) : undefined
              }
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            afterLeave={() => setNextFilters(activeFilters)}
          >
            <Popover.Panel className="mx-0 origin-top-left absolute right-0 top-11 w-72 rounded-md shadow-lg z-10 bg-white border focus:outline-none">
              <div className="flex bg-gray-50 justify-between items-center p-2 rounded-t border-b">
                <ButtonWhite
                  title="Effacer"
                  className="w-20 h-7 items-center px-0"
                  onClick={() => setActiveFilters({})}
                />
                <span className="text-sm font-medium text-gray-700">Filtres</span>
                <Popover.Button as="div">
                  <ButtonPrimary
                    title="Terminer"
                    className="w-20 h-7 items-center px-0"
                    onClick={() => setActiveFilters(nextFilters)}
                  />
                </Popover.Button>
              </div>
              <Section
                name="Statut"
                isExpanded={nextFilters.status !== undefined}
                expandedContent={<StatusExpanded filters={nextFilters} setFilters={setNextFilters} />}
                onClick={() => {
                  if (nextFilters.status) {
                    setNextFilters((currents) => {
                      const { ...rest } = currents
                      delete rest.status
                      return rest
                    })
                  } else {
                    setNextFilters((currents) => ({ ...currents, status: 'draft' }))
                  }
                }}
              />
              <Section
                name="Éco-score"
                isExpanded={nextFilters.ecoScore !== undefined}
                expandedContent={<EcoScoreExpanded filters={nextFilters} setFilters={setNextFilters} />}
                onClick={() => {
                  if (nextFilters.ecoScore) {
                    setNextFilters((currents) => {
                      const { ...rest } = currents
                      delete rest.ecoScore
                      return rest
                    })
                  } else {
                    setNextFilters((currents) => ({ ...currents, ecoScore: 'A' }))
                  }
                }}
              />
              <Section
                name="Marque"
                isExpanded={nextFilters.brand !== undefined}
                expandedContent={<BrandExpanded filters={nextFilters} setFilters={setNextFilters} brands={brands} />}
                onClick={() => {
                  if (nextFilters.brand) {
                    setNextFilters((currents) => {
                      const { ...rest } = currents
                      delete rest.brand
                      return rest
                    })
                  } else {
                    setNextFilters((currents) => ({ ...currents, brand: brands[0] }))
                  }
                }}
              />
              <Section
                name="Catégorie"
                isExpanded={nextFilters.categorySlug !== undefined}
                expandedContent={
                  <CategoryExpanded filters={nextFilters} setFilters={setNextFilters} categories={userCategories} />
                }
                onClick={() => {
                  if (nextFilters.categorySlug) {
                    setNextFilters((currents) => {
                      const { ...rest } = currents
                      delete rest.categorySlug
                      return rest
                    })
                  } else {
                    setNextFilters((currents) => ({ ...currents, categorySlug: userCategories[0].slug }))
                  }
                }}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

function Section({
  name,
  isExpanded,
  expandedContent,
  onClick,
}: {
  name: string
  isExpanded: boolean
  expandedContent: JSX.Element
  onClick: () => void
}) {
  return (
    <>
      <div className="flex p-2 items-center space-x-2 border-b">
        <input
          id="comments"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          className="h-4 w-4 text-green-500 border-gray-300 rounded focus:ring-transparent"
          checked={isExpanded}
          readOnly
          onClick={onClick}
        />
        <a
          href="#"
          className="text-sm font-medium text-gray-700"
          onClick={(e) => {
            e.preventDefault()
            onClick()
          }}
        >
          {name}
        </a>
      </div>
      <div
        className={classNames(
          isExpanded ? 'max-h-40 py-2 border-b' : 'max-h-0 py-0',
          'bg-gray-50 px-2 overflow-hidden transition-all duration-300 ease-in-out'
        )}
      >
        {expandedContent}
      </div>
    </>
  )
}
