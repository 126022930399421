import React, { ReactNode } from 'react'
import { classNames } from '../../functions/classNames'

export function Row({
  title,
  children,
  className,
  borderTop,
}: {
  title: string
  children: ReactNode
  className?: string
  borderTop?: boolean
}) {
  return (
    <div
      className={classNames(
        'sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start',
        className || '',
        borderTop ? 'border-t border-gray-200 pt-5' : ''
      )}
    >
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{title}</label>
      <div className="mt-1 sm:mt-0 sm:col-span-3 space-y-3">{children}</div>
    </div>
  )
}
