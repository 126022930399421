import { useState, useCallback, useEffect } from 'react'
import Selectable from '../types/Selectable'
import API from '../infrastructure/api'
import axios from 'axios'
import { HealthGradeResult } from '../components/CosmeticHealthGradeDetails'
import CosmeticIngredient from '../types/CosmeticIngredient'
import { useNotification } from '../hooks/useNotification'
import { NotificationType } from '../components/Notification'

export interface HealthGradeCalculationError {
  category?: string
  ingredientsList?: string
}

export function useCalculateHealthGrade() {
  const [categories, setCategories] = useState<Selectable[]>([])
  const [ingredients, setIngredients] = useState<string | null | undefined>()
  const [category, setCategory] = useState<Selectable | undefined>()
  const [isLoadingHealthGrade, setIsLoadingHealthGrade] = useState<boolean>(false)
  const [healthGradeResult, setHealthGradeResult] = useState<HealthGradeResult | undefined>(undefined)
  const [detectedIngredients, setDetectedIngredients] = useState<CosmeticIngredient[]>([])
  const [errors, setErrors] = useState<HealthGradeCalculationError>({})
  const { setNotification } = useNotification()

  useEffect(() => {
    API.get<Selectable[]>(`/cosmetic/categories`).then((response) => {
      setCategories(response.data)
    })
  }, [])

  const calculateHealthGrade = useCallback(
    (displayErrors = true) => {
      if (!validateData({ category, ingredients }, displayErrors)) {
        return
      }

      const body = {
        category: category?.slug,
        ingredientsList: ingredients,
      }

      const cancelTokenSource = axios.CancelToken.source()

      const query = setTimeout(() => {
        setIsLoadingHealthGrade(true)
        API.post('/cosmetic/health-grade/evaluate', body, { cancelToken: cancelTokenSource.token })
          .then(({ data }) => {
            const { grade, ingredients } = data
            setHealthGradeResult(grade)
            setDetectedIngredients(
              ingredients.map(({ inci, dangerousnessLevel }: { inci: string; dangerousnessLevel: string }) => {
                return {
                  inci,
                  dangerousness: dangerousnessLevel,
                }
              })
            )
            setIsLoadingHealthGrade(false)
            setNotification(undefined)
          })
          .catch(() => {
            setIsLoadingHealthGrade(false)
            setNotification({
              title: 'Échec de la simulation',
              type: NotificationType.Error,
            })
          })
      }, 1000)

      return () => {
        clearTimeout(query)
        cancelTokenSource.cancel()
      }
    },
    [category, ingredients, setNotification]
  )

  const validateData = (data: HealthGradeCalculatorFormData, displayErrors: boolean): boolean => {
    setErrors({})
    let isValid = true
    const newErrors: HealthGradeCalculationError = {}

    if (data.category === undefined) {
      isValid = false
      newErrors.category = 'Obligatoire'
    }

    if (data.ingredients === undefined || data.ingredients === '') {
      isValid = false
      newErrors.ingredientsList = 'Obligatoire'
    }

    if (displayErrors) {
      setErrors(newErrors)
    }

    return isValid
  }

  const resetHealthGradeDetails = () => {
    setHealthGradeResult(undefined)
    setDetectedIngredients([])
    setIsLoadingHealthGrade(false)
    setErrors({})
  }

  const resetHealthGradeForm = () => {
    setCategory(undefined)
    setIngredients(undefined)
  }

  return {
    categories,
    isLoadingHealthGrade: isLoadingHealthGrade,
    calculateHealthGrade: calculateHealthGrade,
    resetHealthGradeDetails,
    resetHealthGradeForm,
    healthGradeResult,
    detectedIngredients,
    formData: {
      category,
      ingredients,
    },
    setters: {
      setCategory,
      setIngredients,
    },
    errors,
  }
}

export interface HealthGradeCalculatorSetters {
  setCategory: React.Dispatch<React.SetStateAction<Selectable | undefined>>
  setIngredients: React.Dispatch<React.SetStateAction<string | null | undefined>>
}

export interface HealthGradeCalculatorFormData {
  category: Selectable | undefined
  ingredients: string | null | undefined
}
