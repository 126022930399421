import React, { useCallback } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { classNames } from '../../functions/classNames'
import { pluralize } from '../../functions/pluralize'
import { PageInfo } from '../../types/PageInfo'

export default function PaginationFooter({
  currentPage,
  pageInfo,
  onPageClick,
}: {
  currentPage: number
  pageInfo: PageInfo
  onPageClick: (page: number) => void
}) {
  const startItem = (pageInfo.currentPage - 1) * pageInfo.itemsPerPage + 1
  const endItem = Math.min(pageInfo.totalItems, pageInfo.currentPage * pageInfo.itemsPerPage)
  const totalItems = pageInfo.totalItems
  const totalPages = pageInfo.totalPages
  const getPages = useCallback(() => {
    if (totalPages < 7) {
      return Array.from(Array(totalPages)).map((e, i) => i + 1)
    }
    let pages: (number | string)[] = []
    if (currentPage < 5) {
      pages = [1, 2, 3, 4, 5, '...', totalPages]
    } else if (currentPage + 4 > totalPages) {
      pages = [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
    } else {
      pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages]
    }
    return pages
  }, [totalPages, currentPage])

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Précédent
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Suivant
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {totalPages > 1 && (
              <>
                <span className="font-medium">{startItem}</span> - <span className="font-medium">{endItem}</span> sur{' '}
              </>
            )}
            {pluralize(totalItems, 'résultat')}
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => onPageClick(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-default"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {getPages().map((page, i) => {
              return Number.isInteger(page) ? (
                <a
                  key={i}
                  onClick={() => onPageClick(Number(page))}
                  className={classNames(
                    'hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
                    page === currentPage
                      ? 'z-10 bg-green-50 border-green-300 text-green-500'
                      : 'bg-white border-gray-300 text-gray-500'
                  )}
                >
                  {page}
                </a>
              ) : (
                <span
                  key={i}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                >
                  {page}
                </span>
              )
            })}
            <button
              onClick={() => onPageClick(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-default"
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}
