import React from 'react'
import Card from '../Card'
import { EcoScore, getEcoScoreLogo } from '../../types/EcoScore'

export interface EcoScoreGradeDetails {
  grade?: EcoScore
  normalizedSingleScore?: number
  productionBonus?: number
  transportBonus?: number
  epiBonus?: number
  packagingPenalty?: number
  threatenedSpeciesPenalty?: number
  score?: number
  singleScore?: number
}

function Row({ title, value }: { title: string; value?: string }) {
  return (
    <div className="flex justify-between items-center">
      <p className="text-gray-500 text-sm">{title}</p>
      <p className="text-gray-900 text-sm text-right">{value ? value : '-'}</p>
    </div>
  )
}

export default function EcoScoreDetails({
  grade,
  normalizedSingleScore,
  productionBonus,
  transportBonus,
  epiBonus,
  packagingPenalty,
  threatenedSpeciesPenalty,
  score,
  singleScore,
}: EcoScoreGradeDetails) {
  return (
    <div>
      <Card>
        <div className="bg-white">
          <div className="px-4 py-5 space-y-6 sm:p-6 mb-2">
            <div className="flex justify-between items-center border-b border-gray-200 pb-2">
              <p className="text-lg leading-6 font-medium text-gray-900">Éco-score</p>
              <img className="h-7 w-auto" src={getEcoScoreLogo(grade)} />
            </div>
            <div className="space-y-4">
              <Row
                title="Score ACV de la catégorie"
                value={
                  singleScore && normalizedSingleScore
                    ? `${singleScore.toString()} -> ${normalizedSingleScore.toString()}`
                    : undefined
                }
              />
              <Row title="Labels" value={productionBonus?.toString()} />
              <Row title="Transport" value={transportBonus?.toString()} />
              <Row title="EPI" value={epiBonus?.toString()} />
              <Row title="Emballages" value={packagingPenalty?.toString()} />
              <Row title="Espèces menacées" value={threatenedSpeciesPenalty?.toString()} />
            </div>
          </div>
          <div className="bg-gray-50 sm:border-t sm:border-gray-200">
            <div className="py-4 px-6">
              <Row title="Total" value={score ? `${score?.toString()}/100` : undefined} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
