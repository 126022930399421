import React from 'react'
import Additive from '../../types/Additive'
import { Switch } from '@headlessui/react'
import Category from '../../types/Category'
import { Row } from '../Row'
import { SelectSingle } from '../Select'
import { Input } from '../Input'
import {
  HealthGradeCalculatorSetters,
  HealthGradeCalculatorFormData,
  HealthGradeCalculationErrors,
} from '../../hooks/useCalculateFoodHealthGrade'
import { classNames } from '../../functions/classNames'
import { useTranslation } from 'react-i18next'

export default function HealthGradeForm({
  categories,
  formData,
  setters,
  detectedAdditives,
  errors,
}: {
  categories: Category[]
  formData: HealthGradeCalculatorFormData
  setters: HealthGradeCalculatorSetters
  detectedAdditives: Additive[]
  errors: HealthGradeCalculationErrors
}) {
  const { t, i18n } = useTranslation('health-grade')
  const getDangerousnessColor = (level: number): { background: string; text: string; dot: string } => {
    const colorSettings = { background: '', text: '', dot: '' }

    switch (level) {
      case 1:
        colorSettings.background = 'bg-red-100'
        colorSettings.text = 'text-red-800'
        colorSettings.dot = 'text-red-500'
        break
      case 2:
        colorSettings.background = 'bg-orange-100'
        colorSettings.text = 'text-orange-800'
        colorSettings.dot = 'text-orange-400'
        break
      case 3:
        colorSettings.background = 'bg-yellow-100'
        colorSettings.text = 'text-yellow-800'
        colorSettings.dot = 'text-yellow-400'
        break
      case 4:
        colorSettings.background = 'bg-green-100'
        colorSettings.text = 'text-green-800'
        colorSettings.dot = 'text-green-500'
        break
    }

    return colorSettings
  }
  const additiveList = detectedAdditives.map((additive: Additive) => (
    <span key={additive.code} className="mr-2">
      <span
        className={classNames(
          'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium',
          getDangerousnessColor(additive.dangerousness).background,
          getDangerousnessColor(additive.dangerousness).text
        )}
      >
        <svg
          className={classNames('mr-1.5 h-2 w-2', getDangerousnessColor(additive.dangerousness).dot)}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
        {i18n.exists(`additives.${additive.code.toLowerCase()}`, { ns: 'health-grade' })
          ? t(`additives.${additive.code.toLowerCase()}`)
          : additive.code}
      </span>
    </span>
  ))

  return (
    <div className="mt-1 sm:mt-5 space-y-1">
      <Row title={t('form_labels.category')} borderTop>
        <SelectSingle
          placeholder={t('form_fields.category.placeholder')}
          searchable
          error={errors.category}
          isDisabled={false}
          selectedValue={formData.category}
          options={categories}
          ignoreAccentsSearch={true}
          handleSelection={(selectedCategory) => {
            const category = selectedCategory as Category
            setters.setCategory(category)
          }}
        />
      </Row>
      <Row title={t('form_labels.organic')} className="sm:border-none pb-5 pt-5">
        <Switch
          checked={formData.organic}
          onChange={setters.setOrganic}
          className={classNames(
            formData.organic ? 'bg-green-500' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              formData.organic ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Row>
      <Row title={t('form_labels.ingredientsList')} className="pb-5" borderTop>
        <textarea
          id="about"
          name="about"
          rows={4}
          disabled={false}
          className={classNames(
            'max-w-lg shadow-sm block w-full sm:text-sm rounded-md focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400 border-gray-300',
            errors.ingredients ? 'border-red-400' : 'border-gray-300'
          )}
          onChange={(e) => {
            const list = (e.target as HTMLTextAreaElement).value.trim()
            setters.setIngredients(list)
          }}
          defaultValue={formData.ingredients ?? ''}
        />
        {errors.ingredients && <p className="text-sm text-red-400">{errors.ingredients}</p>}
        <div className="my-3 max-w-lg">{additiveList}</div>
      </Row>
      <h4 className="text-base sm:pt-5 sm:border-t sm:border-gray-200 leading-6 font-medium text-gray-900">
        {t('nutrition_facts.title')}
      </h4>
      <p className="text-sm text-gray-500">{t('nutrition_facts.text')}</p>
      <div className="space-y-6 pt-5">
        <Row title={t('form_labels.calories')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="calories"
              name="calories"
              type="number"
              error={errors.calories}
              trailingText="kCal"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setCalories(parseInt(value))
              }}
              value={formData.calories ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.fat')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="fat"
              name="fat"
              type="number"
              error={errors.fat}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setFat(parseFloat(value))
              }}
              value={formData.fat ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.saturatedFat')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="saturatedFat"
              name="saturatedFat"
              type="number"
              error={errors.saturatedFat}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setSaturatedFat(parseFloat(value))
              }}
              value={formData.saturatedFat ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.carbohydrates')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="carbohydrates"
              name="carbohydrates"
              type="number"
              error={errors.carbohydrates}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setCarbohydrates(parseFloat(value))
              }}
              value={formData.carbohydrates ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.sugar')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="sugar"
              name="sugar"
              type="number"
              error={errors.sugar}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setSugar(parseFloat(value))
              }}
              value={formData.sugar ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.fibers')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="fibers"
              name="fibers"
              type="number"
              error={undefined}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setFibers(parseFloat(value))
              }}
              value={formData.fibers ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.proteins')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="proteins"
              name="proteins"
              type="number"
              error={errors.proteins}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setProteins(parseFloat(value))
              }}
              value={formData.proteins ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.salt')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="salt"
              name="salt"
              type="number"
              error={errors.salt}
              trailingText="g"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setSalt(parseFloat(value))
              }}
              value={formData.salt ?? ''}
            />
          </div>
        </Row>
        <Row title={t('form_labels.fruits')} className="sm:border-none">
          <div className="flex space-y-0">
            <Input
              id="fruits"
              name="fruits"
              type="number"
              error={undefined}
              trailingText="%"
              step={0.01}
              width="w-64"
              textAlign="text-right"
              disabled={false}
              handleChange={(value) => {
                setters.setFruits(parseInt(value))
              }}
              value={formData.fruits ?? ''}
            />
          </div>
        </Row>
      </div>
    </div>
  )
}
