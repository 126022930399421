import Selectable from './Selectable'

export interface PackagingComponent {
  format?: Selectable
  material?: Selectable
  specificities?: Specificities
}

export interface Specificities {
  bottleColor?: 'transparent_plastic_bottle' | 'colored_plastic_bottle' | 'opaque_plastic_bottle'
  bottleSticker?: 'large_bottle_sticker'
  plasticColor?: 'dark_colored_solid_plastic'
  dryContent?: boolean
  wasteBasedMaterialRate?: number
}

export enum PackagingComponentSpecificity {
  NotRecyclable = 'not_recyclable',
  DarkColorSolidPlastic = 'dark_colored_solid_plastic',
  TransparentPlasticBottle = 'transparent_plastic_bottle',
  ColoredPlasticBottle = 'colored_plastic_bottle',
  OpaquePlasticBottle = 'opaque_plastic_bottle',
  LargeBottleSticker = 'large_bottle_sticker',
  DryContent = 'dry',
}
