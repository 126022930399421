import { useEffect, useState } from 'react'
import API from './../infrastructure/api'
import { Product } from './../types/Product'
import Selectable from './../types/Selectable'
import Category from './../types/Category'
import { Origin } from './../types/Origin'
import decimalStringToPercentageNumber from './../functions/decimalStringToPercentageNumber'
import { PackagingComponent, Specificities } from '../types/PackagingComponent'
import { ProductStatus } from '../types/Product'

interface FetchProductDTO {
  name: string
  ean: string
  brand: string
  parent?: Product
  status: ProductStatus
  frontPhotoUrl?: string
  category?: Category
  labels: Selectable[]
  origins: Origin[]
  packagingComponents: PackagingComponent[]
  fishingTechniques: Selectable[]
  ingredientsList: string
  calories: number
  fat: number
  saturatedFat: number
  carbohydrates: number
  sugar: number
  proteins: number
  fibers: number
  salt: number
  fruits: number
}

export function useFetchProduct(productEan?: string) {
  const [fetchProductDTO, setFetchProductDTO] = useState<FetchProductDTO | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!productEan) return
    setError(undefined)
    API.get(`/food/products/${productEan}`)
      .then((response) => {
        const product: Product = response.data
        product.origins.forEach((origin) => {
          origin.regions?.forEach((region) => (region.slug = region.code))
          origin.percentage = decimalStringToPercentageNumber(origin.percentage)?.toString()
        })
        setFetchProductDTO({
          name: product.name,
          ean: product.ean,
          brand: product.brand,
          parent: product.parent,
          status: product.status,
          frontPhotoUrl: product.frontPhotoUrl,
          category: product.category,
          labels: product.labels,
          origins: product.origins,
          packagingComponents: product.packagingComponents.map((packagingComponent) => {
            const component = packagingComponent.component

            const wasteBasedMaterialRate = packagingComponent.specificities.find(
              (spec) => spec.name === 'waste_based_material_rate'
            )?.value
            const specificities: Specificities = {
              bottleColor: packagingComponent.specificities.find((spec) => spec.name === 'bottle_color')?.value as
                | 'transparent_plastic_bottle'
                | 'colored_plastic_bottle'
                | 'opaque_plastic_bottle',
              plasticColor: packagingComponent.specificities.find((spec) => spec.name === 'plastic_color')
                ?.value as 'dark_colored_solid_plastic',
              bottleSticker: packagingComponent.specificities.find((spec) => spec.name === 'sticker_size')
                ?.value as 'large_bottle_sticker',
              wasteBasedMaterialRate: wasteBasedMaterialRate ? Number(wasteBasedMaterialRate) : undefined,
              dryContent:
                packagingComponent.specificities.find((spec) => spec.name === 'content_dryness')?.value === 'dry'
                  ? true
                  : undefined,
            }

            if (component.materialSlug && component.materialName) {
              return {
                format: { slug: component.formatSlug, name: component.formatName },
                material: { slug: component.materialSlug, name: component.materialName },
                specificities: specificities,
              }
            } else {
              return {
                format: { slug: component.formatSlug, name: component.formatName },
                specificities: specificities,
              }
            }
          }),
          fishingTechniques: product.fishingTechniques,
          ingredientsList: product.ingredientsList,
          calories: product.calories,
          fat: product.fat,
          saturatedFat: product.saturatedFat,
          carbohydrates: product.carbohydrates,
          sugar: product.sugar,
          proteins: product.proteins,
          fibers: product.fibers,
          fruits: product.fruits,
          salt: product.salt,
        })
      })
      .catch((err) => {
        setError(err.response.data.message)
      })
  }, [productEan])

  return { fetchProductDTO, fetchProductError: error }
}
