import React from 'react'
import { usePopper } from '../../hooks/usePopper'
import { Menu } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import Portal from '../../components/Portal'
import { classNames } from '../../functions/classNames'
import { Placement } from '@popperjs/core'

interface MenuActionProps {
  title: string
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  onClick: () => void
}

export default function MenuWithActions({
  topActions,
  bottomActions,
  placement,
  offset,
  menuButtonContent,
  menuButtonClassName,
}: {
  topActions: MenuActionProps[]
  bottomActions?: MenuActionProps[]
  placement?: Placement
  offset?: number[]
  menuButtonContent?: JSX.Element
  menuButtonClassName?: string
}) {
  const [trigger, container] = usePopper({
    placement: placement ?? 'left-start',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: offset ?? [0, 10] } }],
  })

  return (
    <Menu as="div" className="flex justify-end items-center">
      <Menu.Button
        ref={trigger}
        className={
          menuButtonClassName ??
          'relative w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 hover:bg-gray-100 focus:outline-none'
        }
      >
        {menuButtonContent ? menuButtonContent : <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />}
      </Menu.Button>
      <Portal>
        <Menu.Items
          ref={container}
          className="absolute mx-3 origin-top-right right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        >
          <div className="py-1">
            <MenuItems actions={topActions} />
          </div>
          {bottomActions && bottomActions.length > 0 && (
            <div className="py-1">
              <MenuItems actions={bottomActions} />
            </div>
          )}
        </Menu.Items>
      </Portal>
    </Menu>
  )
}

function MenuItems({ actions }: { actions: MenuActionProps[] }) {
  return (
    <>
      {actions.map((action) => (
        <Menu.Item key={action.title}>
          {({ active }) => (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                action.onClick()
              }}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm'
              )}
            >
              {action.icon && (
                <action.icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              )}
              {action.title}
            </a>
          )}
        </Menu.Item>
      ))}
    </>
  )
}
