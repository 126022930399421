import { useState, useReducer, useCallback } from 'react'
import Category from '../types/Category'
import Selectable from '../types/Selectable'
import EcoScoreCalculatorParams from '../types/EcoScoreCalculatorParams'
import { PackagingComponent, PackagingComponentSpecificity } from '../types/PackagingComponent'
import { Origin, Ingredient, Region } from '../types/Origin'
import { getCompatibleRegionsWithIngredient } from '../functions/getCompatibleRegionsWithIngredient'
import { useFetchEcoScoreData } from './useFetchEcoScoreData'

type SelectableReducerAction = { type: 'setAll'; items: Selectable[] }

function selectableReducer(state: Selectable[], action: SelectableReducerAction): Selectable[] {
  switch (action.type) {
    case 'setAll':
      return action.items
    default:
      throw new Error()
  }
}

type PackagingComponentReducerAction =
  | { type: 'setAll'; components: PackagingComponent[] }
  | { type: 'add'; component: PackagingComponent }
  | { type: 'remove'; index: number }
  | { type: 'selectFormat'; index: number; format: Selectable; validComponents: PackagingComponent[] }
  | { type: 'selectMaterial'; index: number; material: Selectable; validComponents: PackagingComponent[] }
  | { type: 'selectSpecificity'; index: number; specificity: PackagingComponentSpecificity }
  | { type: 'setWasteBasedMaterialRate'; index: number; value?: number }

export function packagingComponentsReducer(
  state: PackagingComponent[],
  action: PackagingComponentReducerAction
): PackagingComponent[] {
  switch (action.type) {
    case 'setAll':
      return action.components
    case 'add':
      return [...state, action.component]
    case 'remove':
      return state.filter((_, i) => i !== action.index)
    case 'selectFormat': {
      const components = [...state]

      const isMaterialCompatible = action.validComponents
        .filter((c) => c.format?.slug === action.format?.slug && c.material)
        .map((c) => c.material?.slug === components[action.index].material?.slug)
        .includes(true)
      if (!isMaterialCompatible) {
        components[action.index].material = undefined
      }

      components[action.index].format = action.format
      return components
    }
    case 'selectMaterial': {
      const components = [...state]

      const isFormatCompatible = action.validComponents
        .filter((c) => c.material?.slug === action.material?.slug && c.format)
        .map((c) => c.format?.slug === components[action.index].format?.slug)
        .includes(true)
      if (!isFormatCompatible) {
        components[action.index].format = undefined
      }

      components[action.index].material = action.material
      return components
    }
    case 'selectSpecificity': {
      const components = [...state]
      const specificities = components[action.index].specificities ?? {}

      switch (action.specificity) {
        case PackagingComponentSpecificity.OpaquePlasticBottle:
          specificities.bottleColor =
            specificities.bottleColor === 'opaque_plastic_bottle' ? undefined : 'opaque_plastic_bottle'
          break
        case PackagingComponentSpecificity.ColoredPlasticBottle:
          specificities.bottleColor =
            specificities.bottleColor === 'colored_plastic_bottle' ? undefined : 'colored_plastic_bottle'
          break
        case PackagingComponentSpecificity.TransparentPlasticBottle:
          specificities.bottleColor =
            specificities.bottleColor === 'transparent_plastic_bottle' ? undefined : 'transparent_plastic_bottle'
          break
        case PackagingComponentSpecificity.DarkColorSolidPlastic:
          specificities.plasticColor = specificities.plasticColor ? undefined : 'dark_colored_solid_plastic'
          break
        case PackagingComponentSpecificity.LargeBottleSticker:
          specificities.bottleSticker = specificities.bottleSticker ? undefined : 'large_bottle_sticker'
          break
        case PackagingComponentSpecificity.DryContent:
          specificities.dryContent = !(specificities.dryContent ?? false)
          break
      }

      components[action.index].specificities = specificities
      return components
    }
    case 'setWasteBasedMaterialRate':
      const components = [...state]
      const specificities = components[action.index].specificities ?? {}
      specificities.wasteBasedMaterialRate = action.value
      components[action.index].specificities = specificities
      return components
    default:
      throw new Error()
  }
}

type OriginReducerAction =
  | { type: 'setAll'; origins: Origin[] }
  | { type: 'add'; origin: Origin }
  | { type: 'remove'; index: number }
  | { type: 'selectIngredient'; ingredient: Ingredient; index: number }
  | { type: 'setRegions'; regions: Region[]; index: number }
  | { type: 'selectPercentage'; percentage: string | undefined; index: number }

export function originsReducer(state: Origin[], action: OriginReducerAction): Origin[] {
  switch (action.type) {
    case 'setAll':
      return action.origins
    case 'add':
      return [...state, action.origin]
    case 'remove':
      return state.filter((_, i) => i !== action.index)
    case 'selectIngredient': {
      const origins = [...state]
      origins[action.index].ingredient = action.ingredient
      origins[action.index].regions = getCompatibleRegionsWithIngredient(
        origins[action.index].regions ?? [],
        action.ingredient
      )
      return origins
    }
    case 'setRegions': {
      const origins = [...state]
      origins[action.index].regions = action.regions
      return origins
    }
    case 'selectPercentage': {
      const origins = [...state]
      origins[action.index].percentage = action.percentage
      return origins
    }
    default:
      throw new Error()
  }
}

export function useEcoScoreCalculator({
  categorySlug,
  labelSlugs,
  rawOrigins,
  unspecifiedOrigins,
  packagingComponentSlugs,
  fishingTechniqueSlugs,
  rawIngredientsList,
}: Partial<EcoScoreCalculatorParams>) {
  const prefill = useCallback(
    (
      categories: Category[],
      labels: Selectable[],
      ingredients: Ingredient[],
      regions: Region[],
      packagingComponents: PackagingComponent[],
      fishingTechniques: Selectable[]
    ) => {
      if (categorySlug) {
        const category = categories.find((c) => c.slug === categorySlug)
        if (category) {
          setSelectedCategories([category])
        }
      }
      if (labelSlugs) {
        labelsDispatch({
          type: 'setAll',
          items: labelSlugs
            .map((s) => labels.find((label) => label.slug === s))
            .filter((label) => label !== undefined) as Selectable[],
        })
      }
      if (unspecifiedOrigins) {
        originsDispatch({ type: 'setAll', origins: [] })
      } else if (rawOrigins) {
        const origins = rawOrigins
          .map((rawOrigin) => {
            const ingredient = ingredients.find((ingredient) => ingredient.slug === rawOrigin.ingredientSlug)
            let originRegions
            if (rawOrigin.regions) {
              originRegions = rawOrigin.regions
                .map((code) => regions.find((region) => region.slug === code.toUpperCase()))
                .filter((region) => region !== undefined) as Region[]
            }
            if (ingredient && originRegions) {
              return {
                ingredient: ingredient,
                regions: originRegions,
                percentage: (rawOrigin.percentage * 100).toString(),
              }
            }
            return undefined
          })
          .filter((origin) => origin !== undefined) as Origin[]
        if (origins.length > 0) {
          originsDispatch({ type: 'setAll', origins: origins })
        }
      }
      if (packagingComponentSlugs) {
        const components = packagingComponentSlugs
          .map((raw) =>
            packagingComponents.find(
              (component) => component.format?.slug === raw.format && component.material?.slug === raw.material
            )
          )
          .filter((c) => c !== undefined) as PackagingComponent[]
        if (components.length > 0) {
          packagingComponentsDispatch({ type: 'setAll', components: components })
        }
      }
      if (fishingTechniqueSlugs) {
        fishingTechniquesDispatch({
          type: 'setAll',
          items: fishingTechniqueSlugs
            .map((s) => fishingTechniques.find((fishingTechnique) => fishingTechnique.slug === s))
            .filter((fishingTechnique) => fishingTechnique !== undefined) as Selectable[],
        })
      }
      setIsFetchingData(false)
    },
    [categorySlug, labelSlugs, rawOrigins, unspecifiedOrigins, packagingComponentSlugs, fishingTechniqueSlugs]
  )

  const { categories, labels, ingredients, packagingComponents, regions, fishingTechniques } =
    useFetchEcoScoreData(prefill)
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true)
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [selectedLabels, labelsDispatch] = useReducer(selectableReducer, [])
  const [origins, originsDispatch] = useReducer(originsReducer, [{}])
  const [selectedPackagingComponents, packagingComponentsDispatch] = useReducer(packagingComponentsReducer, [{}])
  const [selectedFishingTechniques, fishingTechniquesDispatch] = useReducer(selectableReducer, [])
  const [ingredientsList, setIngredientsList] = useState<string | undefined>(rawIngredientsList ?? undefined)

  const resetCalculatorState = () => {
    setSelectedCategories([])
    labelsDispatch({ type: 'setAll', items: [] })
    fishingTechniquesDispatch({ type: 'setAll', items: [] })
    packagingComponentsDispatch({ type: 'setAll', components: [{}] })
    originsDispatch({ type: 'setAll', origins: [{}] })
    setIngredientsList('')
  }

  return {
    isFetchingData,
    data: {
      categories,
      labels,
      ingredients,
      packagingComponents,
      regions,
      fishingTechniques,
    },
    state: {
      selectedCategory: selectedCategories.length > 0 ? selectedCategories[selectedCategories.length - 1] : undefined,
      selectedCategories,
      selectedLabels,
      origins,
      selectedPackagingComponents,
      selectedFishingTechniques,
      ingredientsList,
    },
    setters: {
      setSelectedCategories,
      labelsDispatch,
      originsDispatch,
      packagingComponentsDispatch,
      fishingTechniquesDispatch,
      setIngredientsList,
    },
    resetCalculatorState,
  }
}

export interface EcoScoreCalculatorData {
  categories: Category[]
  labels: Selectable[]
  ingredients: Ingredient[]
  packagingComponents: PackagingComponent[]
  regions: Region[]
  fishingTechniques: Selectable[]
}

export interface EcoScoreCalculatorSetters {
  setSelectedCategories: React.Dispatch<React.SetStateAction<Category[]>>
  labelsDispatch: React.Dispatch<SelectableReducerAction>
  originsDispatch: React.Dispatch<OriginReducerAction>
  packagingComponentsDispatch: React.Dispatch<PackagingComponentReducerAction>
  fishingTechniquesDispatch: React.Dispatch<SelectableReducerAction>
  setIngredientsList: React.Dispatch<React.SetStateAction<string | undefined>>
}

export interface EcoScoreCalculatorState {
  selectedCategory: Category | undefined
  selectedCategories: Category[]
  selectedLabels: Selectable[]
  origins: Origin[]
  selectedPackagingComponents: PackagingComponent[]
  selectedFishingTechniques: Selectable[]
  ingredientsList: string | undefined
}
