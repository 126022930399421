import React from 'react'
import { Filters } from './index'

export default function StatusExpanded({
  filters,
  setFilters,
}: {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
}) {
  return (
    <select
      id="status"
      name="status"
      className="block pl-2 pr-7 p-0 w-full h-6 text-sm border-gray-300 overflow-ellipsis focus:outline-none focus:border-transparent focus:ring-2 focus:ring-blue-400 rounded-md"
      value={filters.status}
      onChange={(e) =>
        setFilters((currents) => ({ ...currents, status: e.target.value as 'draft' | 'published' | undefined }))
      }
    >
      <option value="draft">Brouillon</option>
      <option value="published">Publié</option>
    </select>
  )
}
