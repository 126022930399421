import React, { useContext, useState, ReactNode, createContext, Dispatch, SetStateAction } from 'react'
import { NotificationProps as Notification } from './../components/Notification'

interface NotificationContextProps {
  notification: Notification | undefined
  setNotification: Dispatch<SetStateAction<Notification | undefined>>
}

const NotificationContext = createContext<NotificationContextProps>({} as NotificationContextProps)

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [notification, setNotification] = useState<Notification | undefined>(undefined)
  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>{children}</NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)
