import React, { useState, useEffect, useReducer } from 'react'
import Card from '@/components/Card'
import { Row } from '@/components/Row'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/Textarea'
import { ButtonWhite, ButtonPrimary } from '@/components/Button'
import { originsReducer, packagingComponentsReducer, useEcoScoreCalculator } from '@/hooks/useEcoScoreCalculator'
import { useCalculateEcoScore } from '@/hooks/useCalculateEcoScore'
import { useHistory } from 'react-router-dom'
import EcoScoreDetails from '@/components/EcoScoreDetails'
import { useCreateOrUpdateProduct } from '@/hooks/useCreateOrUpdateProduct'
import { useFetchProduct } from '@/hooks/useFetchProduct'
import { ImageDragAndDrop } from '@/components/ImageDragAndDrop'
import { SelectSingle, SelectMultiple } from '@/components/Select'
import Selectable from '@/types/Selectable'
import { useAuth } from '@/hooks/useAuth'
import { ProductForm, ProductStatus } from '@/types/Product'
import Warning from '@/components/Warning'
import { object, string, number, mixed, array, ref } from 'yup'
import { useYup } from '@/hooks/useYup'
import CategorySection from '@/components/EcoScore/CategorySection'
import OriginSection from '@/components/EcoScore/OriginSection'
import { RegionType } from '@/types/Origin'
import PackagingSection from '@/components/EcoScore/PackagingSection'
import Category from '@/types/Category'
import HealthGradeDetails, { HealthGradeResult } from '@/components/FoodHealthGradeDetails'
import axios from 'axios'
import API from '@/infrastructure/api'
import Additive from '@/types/Additive'
import CountriesInContinent from '@/types/CountriesInContinent'

const healthGradeRules = {
  calories: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(1500, 'Doit être inférieur à 1500')
    .required('Les calories sont requises'),
  fat: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(100, 'Doit être inférieur à 100')
    .required('Les matières grasses sont requises')
    .test({
      name: 'total-is-lower-100',
      message: 'La somme des matières grasses, glucides, et protéines doit être inférieure ou égale à 100',
      test: function () {
        const fat = this.parent.fat || 0
        const carbohydrates = this.parent.carbohydrates || 0
        const proteins = this.parent.proteins || 0
        return fat + carbohydrates + proteins <= 100
      },
    }),
  saturatedFat: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(10, 'Doit être inférieur à 10')
    .when('fat', {
      is: (val: number) => val > 0,
      then: (schema) =>
        schema
          .required('Les graisses saturées sont requises')
          .max(ref('fat'), 'Doit être inférieur ou égal aux matières grasses'),
    }),
  carbohydrates: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(100, 'Doit être inférieur à 100')
    .required('Les glucides sont requis')
    .test({
      name: 'total-is-lower-100',
      message: 'La somme des matières grasses, glucides, et protéines doit être inférieure ou égale à 100',
      test: function () {
        const fat = this.parent.fat || 0
        const carbohydrates = this.parent.carbohydrates || 0
        const proteins = this.parent.proteins || 0
        return fat + carbohydrates + proteins <= 100
      },
    }),
  sugar: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(100, 'Doit être inférieur à 100')
    .when('carbohydrates', {
      is: (val: number) => val > 0,
      then: (schema) =>
        schema.required('Le sucre est requis').max(ref('carbohydrates'), 'Doit être inférieur ou égal au sucre'),
    }),
  fibers: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(90, 'Doit être inférieur à 90'),
  proteins: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(100, 'Doit être inférieur à 100')
    .required('Les protéines sont requises')
    .test({
      name: 'total-is-lower-100',
      message: 'La somme des matières grasses, glucides, et protéines doit être inférieure ou égale à 100',
      test: function () {
        const fat = this.parent.fat || 0
        const carbohydrates = this.parent.carbohydrates || 0
        const proteins = this.parent.proteins || 0
        return fat + carbohydrates + proteins <= 100
      },
    }),
  fruits: number()
    .positive()
    .integer('Doit être un nombre entier')
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(100, 'Doit être inférieur à 100'),
  salt: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Doit être supérieur à 0')
    .max(25, 'Doit être inférieur à 25')
    .required('Le sel est requis'),
  category: mixed().test('category-with-slug', 'Une catégorie est requise', (value) => {
    return !value || value[0] == null ? false : true
  }),
  ingredientsList: string().nullable().required("Une liste d'ingrédients est requise"),
}

const formSchema = object().shape({
  ean: string()
    .min(5, "L'EAN doit contenir 5 caractères au minimum")
    .max(25, "L'EAN doit contenir 25 caractères au maximum")
    .required("L'EAN est requis"),
  name: string().required('Le nom est requis'),
  brand: mixed().required('La marque est requise'),
  origins: array(
    object({
      ingredient: mixed().required("Origines: L'ingrédient est requis"),
      regions: array()
        .min(1, 'Obligatoire')
        .required('Origines: La region est requise')
        .test('region-and-country', 'Choisissez le pays ou le continent', (regions) => {
          let found = false
          const selectedContinents = regions?.filter((region) => region.type == 'continent') || []
          const selectedCountries =
            regions?.filter((region) => region.type == 'country').map((country) => country.code) || []

          if (selectedContinents?.length > 0) {
            // Si un continent est sélectionnée, on vérifie que les pays sélectionnées n'y appartiennent pas
            selectedContinents.forEach((selectedContinent) => {
              const countriesToCheck = CountriesInContinent.find(
                (obj) => obj.continent == selectedContinent.code
              )?.countries
              console.log(selectedCountries?.some((r) => countriesToCheck?.includes(r)))
              if (selectedCountries?.some((r) => countriesToCheck?.includes(r))) {
                found = true
              }
            })
          }

          return !found
        }),
      percentage: number()
        .min(0, 'Doit être supérieur à 0')
        .max(100, 'Doit être inférieur à 100')
        .required('Origines: Le pourcentage est requis'),
    }).required('Merci de sélectionner les origines des ingrédients')
  )
    .min(1, 'Au moins une origine doit être indiquée')
    .test('percent-is-lower-100', 'La somme des ingrédients doit être inférieure ou égale à 100%', (value) => {
      const sum = value?.reduce((accumulator, object) => {
        return object.percentage ? accumulator + object.percentage : 0
      }, 0)
      return sum ? sum <= 100 : true
    }),
  packagingComponents: array(
    object({
      format: mixed().required('Emballage: Le format est requis'),
      material: mixed().when('format', {
        is: (val: { slug: string; name: string }) => val?.slug != 'bulk',
        then: (schema) => schema.required('Emballage: Le matériau est requis'),
      }),
    }).required('Merci de sélectionner au moins un emballage')
  ).min(1, 'Au moins un emballage doit être indiqué'),
  ...healthGradeRules,
})

const healthGradeSchema = object().shape(healthGradeRules)

export default function ProductFormPage({ productEan }: { productEan?: string }) {
  const history = useHistory()
  const [form, setForm] = useState<ProductForm>({})
  const { errors, validate } = useYup(form, formSchema)
  const { validate: validateHealthGrade } = useYup(form, healthGradeSchema, {}, true)
  const { user } = useAuth()
  const { data, state, isFetchingData } = useEcoScoreCalculator({})
  const [origins, originsDispatch] = useReducer(originsReducer, [{}])
  const [packagingComponents, packagingComponentsDispatch] = useReducer(packagingComponentsReducer, [{}])
  const [localFrontImageUrl, setLocalFrontImageUrl] = useState<string | undefined>(undefined)
  const [didTapPrimaryButton] = useState<boolean>(false)
  const [isLoadingHealthGrade, setIsLoadingHealthGrade] = useState<boolean>(false)
  const { ecoScoreDetails, calculateEcoScore, resetErrorsAndEcoScoreDetails } = useCalculateEcoScore()
  const { isCreatingProduct, createOrUpdateProduct } = useCreateOrUpdateProduct()
  const { fetchProductDTO, fetchProductError } = useFetchProduct(productEan)
  const [healthGradeResult, setHealthGradeResult] = useState<HealthGradeResult | undefined>(undefined)
  const [detectedAdditives, setDetectedAdditives] = useState<Additive[]>([])
  const [healthGrade, setHealthGrade] = useState<number | undefined>(undefined)

  const handleSubmit = async (synchronizeProductAfterUpload?: boolean) => {
    if (await validate()) {
      await createOrUpdateProduct({
        ...form,
        packagingComponents,
        origins,
        localFrontImageUrl,
        initialEan: fetchProductDTO?.ean,
        deleteFrontPhoto: localFrontImageUrl === undefined && form.frontPhotoUrl === undefined,
        synchronizeProductAfterUpload,
        healthGrade,
      })
      history.push('/food/products/1')
    } else {
      console.log('errors', errors)
    }
  }

  useEffect(() => {
    if (fetchProductDTO) {
      setForm({
        ...fetchProductDTO,
        brand: { slug: fetchProductDTO.brand, name: fetchProductDTO.brand },
        category: [fetchProductDTO.category as Category],
      })

      packagingComponentsDispatch({
        type: 'setAll',
        components: fetchProductDTO.packagingComponents ?? [{}],
      })

      originsDispatch({ type: 'setAll', origins: fetchProductDTO.origins })
    }
  }, [fetchProductDTO])

  useEffect(() => {
    setForm({ ...form, origins: origins, packagingComponents: packagingComponents })
  }, [origins, packagingComponents])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isFetchingData) {
        calculateEcoScore(
          form.category ? form.category[form.category.length - 1] : undefined,
          form.labels || [],
          origins,
          packagingComponents,
          form.fishingTechniques || [],
          form.ingredientsList ?? '-', // calculate eco score even though the ingredients list is undefined
          false
        )
      }
    }, 750)
    return () => clearTimeout(timer)
  }, [
    isFetchingData,
    calculateEcoScore,
    form.category,
    form.labels,
    origins,
    packagingComponents,
    form.fishingTechniques,
    form.ingredientsList,
  ])

  useEffect(() => {
    const calculateHealthGrade = async () => {
      if (await validateHealthGrade()) {
        const cancelTokenSource = axios.CancelToken.source()
        setIsLoadingHealthGrade(true)
        API.post(
          '/food/health-grade/evaluate',
          {
            category: form.category && form.category[0]?.slug,
            bio: form.labels ? form.labels.find((label) => label.slug === 'organic') !== undefined : false,
            calories: form.calories,
            fat: form.fat,
            saturatedFat: form.saturatedFat,
            carbohydrates: form.carbohydrates,
            sugar: form.sugar,
            proteins: form.proteins,
            fibers: form.fibers,
            salt: form.salt,
            fruits: form.fruits,
            ingredientsList: form.ingredientsList,
          },
          { cancelToken: cancelTokenSource.token }
        )
          .then(({ data }) => {
            const { grade, additives } = data
            setHealthGradeResult(grade)
            setDetectedAdditives(
              additives.map(({ code, dangerousnessLevel }: { code: string; dangerousnessLevel: string }) => {
                return { code: code, dangerousness: dangerousnessLevel }
              })
            )
            setHealthGrade(data.grade.total)
            setIsLoadingHealthGrade(false)
          })
          .catch(() => {
            setHealthGrade(undefined)
            setIsLoadingHealthGrade(false)
          })
        return () => {
          cancelTokenSource.cancel()
        }
      } else {
        setHealthGradeResult(undefined)
        setDetectedAdditives([])
      }
    }

    calculateHealthGrade()
  }, [
    form.calories,
    form.fat,
    form.saturatedFat,
    form.carbohydrates,
    form.sugar,
    form.fibers,
    form.proteins,
    form.fruits,
    form.salt,
    form.category,
    form.ingredientsList,
    form.labels,
  ])

  const originsContainOneFishingArea =
    origins
      .map((origin) => origin.regions)
      .find((regions) => regions?.find((region) => [RegionType.Sea, RegionType.Ocean].includes(region.type))) !==
    undefined

  if (!originsContainOneFishingArea && state.selectedFishingTechniques.length > 0) {
    setForm({ ...form, fishingTechniques: [] })
  }

  if (fetchProductError) {
    return <Warning text={fetchProductError} />
  }

  return (
    <div className="m-6 mb-12">
      <div className="max-w-screen-2xl mx-auto lg:grid lg:grid-cols-4 lg:gap-6 lg:space-y-0 space-y-6">
        <div className="lg:col-span-3">
          <form className="space-y-8 divide-y divide-gray-200">
            <Card>
              <div className="m-6 space-y-8 divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {productEan ? 'Modifier un produit' : 'Créer un produit'}
                    </h3>
                    {form.parent ? (
                      <>
                        <span className="mt-1 text-sm text-gray-500">
                          {`Ce produit est lié au produit ${form.parent.ean}. Vous pouvez modifier les informations grisées depuis `}
                        </span>
                        <a href={`/food/products/edit/${form.parent.ean}`} className="text-sm text-blue-500">
                          cette page.
                        </a>
                      </>
                    ) : (
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Renseignez les informations du produit avant de le sauvegarder.
                      </p>
                    )}
                  </div>
                  <hr className="mt-5 pt-5" />
                  <div className="space-y-5">
                    <Row title="EAN">
                      <div className="block w-full max-w-lg">
                        <Input
                          id="ean"
                          name="ean"
                          type="text"
                          placeholder="0000000000000"
                          value={form.ean || ''}
                          error={errors.ean}
                          handleChange={(text: string) => setForm({ ...form, ean: text.trim() })}
                        />
                      </div>
                    </Row>

                    <Row title="Nom">
                      <div className="block w-full max-w-lg">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Donnez un nom au produit"
                          disabled={form.parent != undefined}
                          value={form.name || ''}
                          error={errors.name}
                          handleChange={(text: string) => setForm({ ...form, name: text })}
                        />
                      </div>
                    </Row>

                    <Row title="Marque">
                      <div className="block w-full max-w-lg">
                        <SelectSingle
                          placeholder="Marque"
                          options={
                            user?.foodBrands?.map((brand) => {
                              return { slug: brand, name: brand }
                            }) ?? []
                          }
                          isDisabled={form.parent != undefined}
                          selectedValue={form.brand}
                          handleSelection={(brand) =>
                            setForm({
                              ...form,
                              brand: { ...(brand as Selectable) },
                            })
                          }
                          error={errors.brand}
                        />
                      </div>
                    </Row>

                    <Row title="Catégorie">
                      <CategorySection
                        categories={data.categories}
                        selectedCategories={form.category || []}
                        selectedCategory={form.category ? form.category[form.category.length - 1] : undefined}
                        isDisabled={form.parent ? true : false}
                        error={errors.category}
                        handleCategoriesChange={(category) => {
                          setForm({
                            ...form,
                            category,
                          })
                          resetErrorsAndEcoScoreDetails()
                        }}
                      />
                    </Row>

                    <Row title="Labels">
                      <SelectMultiple
                        placeholder="Aucun"
                        searchable
                        isDisabled={form.parent ? true : false}
                        options={data.labels}
                        selectedValues={form.labels}
                        handleSelection={(labels) => {
                          setForm({ ...form, labels: [...(labels as Selectable[])] })
                          resetErrorsAndEcoScoreDetails()
                        }}
                      />
                    </Row>
                  </div>
                  <hr className="mt-5 pt-5" />
                  <div className="space-y-5">
                    <Row title="Photo">
                      <div className="block w-full max-w-lg">
                        <ImageDragAndDrop
                          imageURL={localFrontImageUrl ?? form?.frontPhotoUrl}
                          setImageURL={(url) => {
                            setLocalFrontImageUrl(url)
                            setForm({ ...form, frontPhotoUrl: undefined })
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                  <hr className="mt-5 pt-5" />
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Tableau nutritionnel</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Pour 100g de produit</p>
                  <div className="mt-1 sm:mt-5 space-y-5">
                    <Row title="Calories">
                      <Input
                        id="calories"
                        name="calories"
                        type="number"
                        min={0}
                        max={1500}
                        step={1}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="kCal"
                        placeholder="0"
                        value={form.calories || ''}
                        error={errors.calories}
                        handleChange={(value: number) => {
                          setForm({ ...form, calories: value })
                        }}
                      />
                    </Row>

                    <Row title="Matières grasses">
                      <Input
                        id="fat"
                        name="fat"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.fat || ''}
                        error={errors.fat}
                        handleChange={(value: number) => {
                          setForm({ ...form, fat: value })
                        }}
                      />
                    </Row>

                    <Row title="Graisses saturées">
                      <Input
                        id="saturatedFat"
                        name="saturatedFat"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.saturatedFat || ''}
                        error={errors.saturatedFat}
                        handleChange={(value: number) => {
                          setForm({ ...form, saturatedFat: value })
                        }}
                      />
                    </Row>

                    <Row title="Glucides">
                      <Input
                        id="carbohydrates"
                        name="carbohydrates"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.carbohydrates || ''}
                        error={errors.carbohydrates}
                        handleChange={(value: number) => {
                          setForm({ ...form, carbohydrates: value })
                        }}
                      />
                    </Row>

                    <Row title="Sucres">
                      <Input
                        id="sugar"
                        name="sugar"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.sugar || ''}
                        error={errors.sugar}
                        handleChange={(value: number) => {
                          setForm({ ...form, sugar: value })
                        }}
                      />
                    </Row>

                    <Row title="Fibres">
                      <Input
                        id="fibers"
                        name="fibers"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.fibers || ''}
                        error={errors.fibers}
                        handleChange={(value: number) => {
                          setForm({ ...form, fibers: value })
                        }}
                      />
                    </Row>

                    <Row title="Protéines">
                      <Input
                        id="proteins"
                        name="proteins"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.proteins || ''}
                        error={errors.proteins}
                        handleChange={(value: number) => {
                          setForm({ ...form, proteins: value })
                        }}
                      />
                    </Row>

                    <Row title="Sel">
                      <Input
                        id="salt"
                        name="salt"
                        type="number"
                        min={0}
                        max={100}
                        step={0.01}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="g"
                        placeholder="0"
                        value={form.salt || ''}
                        error={errors.salt}
                        handleChange={(value: number) => {
                          setForm({ ...form, salt: value })
                        }}
                      />
                    </Row>
                  </div>
                  <hr className="mt-5 pt-5" />
                  <div className="space-y-5">
                    <Row title="Liste d'ingrédients">
                      <Textarea
                        id="ingredientsList"
                        name="ingredientsList"
                        rows={6}
                        value={form.ingredientsList}
                        error={errors.ingredientsList}
                        placeholder="Renseignez une liste d'ingrédients, séparés par des virgules"
                        disabled={form.parent != undefined}
                        handleBlur={(value: string) => {
                          setForm({ ...form, ingredientsList: value })
                          resetErrorsAndEcoScoreDetails()
                        }}
                      />
                    </Row>

                    <Row title="Fruits, légumes et noix">
                      <Input
                        id="fruits"
                        name="fruits"
                        type="number"
                        min={0}
                        max={100}
                        step={1}
                        className="w-52"
                        textAlign="text-right"
                        trailingText="%"
                        placeholder="0"
                        value={form.fruits || ''}
                        error={errors.fruits}
                        handleChange={(value: number) => {
                          setForm({ ...form, fruits: value })
                        }}
                      />
                    </Row>
                  </div>
                  <hr className="mt-5 pt-5" />
                  <div className="space-y-5">
                    <Row title="Origines des ingrédients">
                      <OriginSection
                        ingredients={data.ingredients}
                        regions={data.regions}
                        origins={origins}
                        errors={errors}
                        isDisabled={form.parent != undefined}
                        handleAddOrigin={(e) => {
                          e.preventDefault()
                          originsDispatch({ type: 'add', origin: {} })
                          resetErrorsAndEcoScoreDetails()
                        }}
                        handleIngredientChange={(index, ingredient) => {
                          originsDispatch({ type: 'selectIngredient', index: index, ingredient: ingredient })
                          resetErrorsAndEcoScoreDetails()
                        }}
                        handleRegionSelection={(index, regions) => {
                          originsDispatch({ type: 'setRegions', index: index, regions: regions })
                          resetErrorsAndEcoScoreDetails()
                        }}
                        handlePercentageChange={(index, percentage) => {
                          originsDispatch({ type: 'selectPercentage', index: index, percentage: percentage })
                          resetErrorsAndEcoScoreDetails()
                        }}
                        handleDeleteOrigin={(index, e) => {
                          e.preventDefault()
                          originsDispatch({ type: 'remove', index: index })
                          resetErrorsAndEcoScoreDetails()
                        }}
                      />
                    </Row>
                    {originsContainOneFishingArea && (
                      <Row title="Techniques de pêche">
                        <SelectMultiple
                          searchable
                          placeholder="Non spécifié"
                          options={data.fishingTechniques}
                          selectedValues={state.selectedFishingTechniques}
                          handleSelection={(fishingTechniques) =>
                            setForm({ ...form, fishingTechniques: [...(fishingTechniques as Selectable[])] })
                          }
                        />
                      </Row>
                    )}
                  </div>
                  <hr className="mt-5 pt-5" />
                  <Row title="Emballages">
                    <PackagingSection
                      packagingComponents={data.packagingComponents}
                      selectedComponents={packagingComponents}
                      errors={errors}
                      handleAddPackaging={(e) => {
                        e.preventDefault()
                        packagingComponentsDispatch({ type: 'add', component: {} })
                        resetErrorsAndEcoScoreDetails()
                      }}
                      handleFormatSelection={(index, format) => {
                        packagingComponentsDispatch({
                          type: 'selectFormat',
                          index: index,
                          format: format,
                          validComponents: data.packagingComponents,
                        })
                        resetErrorsAndEcoScoreDetails()
                      }}
                      handleMaterialSelection={(index, material) => {
                        packagingComponentsDispatch({
                          type: 'selectMaterial',
                          index: index,
                          material: material,
                          validComponents: data.packagingComponents,
                        })
                        resetErrorsAndEcoScoreDetails()
                      }}
                      handleSpecificitySelection={(index, specificity) => {
                        packagingComponentsDispatch({
                          type: 'selectSpecificity',
                          index: index,
                          specificity: specificity,
                        })
                      }}
                      handleWasteBasedMaterialRateSelection={(index, value) => {
                        packagingComponentsDispatch({
                          type: 'setWasteBasedMaterialRate',
                          index: index,
                          value: value,
                        })
                      }}
                      handleDeletePackaging={(index, e) => {
                        e.preventDefault()
                        packagingComponentsDispatch({ type: 'remove', index: index })
                        resetErrorsAndEcoScoreDetails()
                      }}
                    />
                  </Row>
                </div>
              </div>

              <div className="flex items-center justify-end space-x-3 px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ButtonWhite
                  title={
                    fetchProductDTO?.status === ProductStatus.Synchronized
                      ? 'Basculer en brouillon'
                      : 'Enregistrer le brouillon'
                  }
                  type="button"
                  loading={(isCreatingProduct && !didTapPrimaryButton) || isLoadingHealthGrade}
                  disabled={isCreatingProduct}
                  onClick={() => handleSubmit(false)}
                />
                <ButtonPrimary
                  title={
                    fetchProductDTO?.status === ProductStatus.Synchronized ? 'Mettre à jour' : 'Publier le produit'
                  }
                  type="button"
                  loading={isCreatingProduct && didTapPrimaryButton}
                  disabled={isCreatingProduct || isLoadingHealthGrade}
                  onClick={() => handleSubmit(true)}
                />
              </div>
            </Card>
          </form>
        </div>
        <div className="space-y-6">
          <HealthGradeDetails
            grade={healthGradeResult}
            additives={detectedAdditives}
            isLoading={isLoadingHealthGrade}
          />
          <EcoScoreDetails {...ecoScoreDetails} />
        </div>
      </div>
    </div>
  )
}
