import React, { useState, useEffect } from 'react'
import API from '../../infrastructure/api'
import Warning from '../../components/Warning'
import PaginationFooter from '../../components/Pagination'
import { useHistory, useParams } from 'react-router-dom'
import { PageInfo } from '../../types/PageInfo'
import { useTitle } from '../../hooks/useTitle'
import { Badge } from '../../components/Badge'

enum OperationStatus {
  Waiting = 'waiting',
  Active = 'active',
  Completed = 'completed',
  Failed = 'failed',
}

interface Operation {
  id: number
  name: string
  created: string
  status: OperationStatus
  completedSteps?: number
  totalSteps?: number
}

interface OperationsPage {
  operations: Operation[]
  info: PageInfo
}

export default function OperationsList() {
  useTitle('Opérations')
  const history = useHistory()
  const params = useParams<{ page?: string | undefined }>()
  const [page, setPage] = useState<number>(params.page ? Number(params.page) : 1)
  const [operationsPage, setOperationsPage] = useState<OperationsPage | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    API.get('/operations', { params: { page: page, size: 20 } })
      .then((response) => {
        setOperationsPage(response.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err.response.status === 403 ? 'Interdit' : "Désolé, quelque chose s'est mal passé.")
        setLoading(false)
      })
  }, [page])

  useEffect(() => history.push(`${page}`), [history, page])

  if (loading) {
    return <></>
  } else if (error) {
    return <Warning text={error} />
  } else if (operationsPage && operationsPage.operations.length === 0) {
    return <div className="m-6 mt-20 text-center">Aucune opération</div>
  }

  return (
    <div className="m-6">
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Opérations</h3>
        </div>
        <div className="inline-block overflow-x-scroll w-full shadow sm:rounded-md bg-white divide-y divide-gray-200">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Nom
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Statut
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Progression
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Crée le
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {operationsPage?.operations.map((operation) => (
                <tr key={operation.id}>
                  <td className="pl-6 pr-4 py-4 text-sm">{operation.id}</td>
                  <td className="p-4 text-sm text-gray-500">{operation.name}</td>
                  <td className="pl-4 pr-6 py-4">
                    <StatusBadge status={operation.status} />
                  </td>
                  <td className="p-4 text-sm text-gray-500">
                    {operation.completedSteps && operation.totalSteps
                      ? `${Math.trunc((operation.completedSteps / operation.totalSteps) * 100)}% (${
                          operation.completedSteps
                        }/${operation.totalSteps})`
                      : ''}
                  </td>
                  <td className="p-4 text-sm text-gray-500">
                    {new Date(operation.created).toLocaleDateString('fr-FR', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {operationsPage && (
            <PaginationFooter currentPage={page} pageInfo={operationsPage.info} onPageClick={setPage} />
          )}
        </div>
      </div>
    </div>
  )
}

function StatusBadge({ status }: { status: OperationStatus }) {
  switch (status) {
    case OperationStatus.Waiting:
      return <Badge title="En attente" color="gray" />
    case OperationStatus.Active:
      return <Badge title="En traitement" color="yellow" />
    case OperationStatus.Completed:
      return <Badge title="Complété" color="green" />
    case OperationStatus.Failed:
      return <Badge title="Échoué" color="red" />
  }
}
