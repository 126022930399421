import React, { useState } from 'react'
import { SelectSingle } from '../../components/Select'
import Category from '../../types/Category'
import { ExclamationIcon } from '@heroicons/react/solid'

function convertSlugsIntoCategories(slugs: string[], categories: Category[]): Category[] {
  return (
    slugs
      .map((slug) => categories.find((category) => category.slug === slug))
      .filter((c) => c !== undefined) as Category[]
  ).sort((a, b) => a.name.localeCompare(b.name))
}

export default function CategorySection({
  categories,
  selectedCategories,
  selectedCategory,
  isDisabled,
  error,
  handleCategoriesChange,
}: {
  categories: Category[]
  selectedCategories: Category[]
  selectedCategory?: Category
  isDisabled?: boolean
  error?: string
  handleCategoriesChange: (categories: Category[]) => void
}) {
  const [didSelectOtherCategory, setDidSelectOtherCategory] = useState<boolean>(false)
  return (
    <>
      <SelectSingle
        placeholder="Sélectionnez une categorie"
        searchable
        error={error}
        isDisabled={isDisabled}
        selectedValue={selectedCategories.length > 0 ? selectedCategories[0] : undefined}
        options={categories}
        ignoreAccentsSearch={false}
        handleSelection={(item) => {
          setDidSelectOtherCategory(false)
          handleCategoriesChange([item as Category])
        }}
      />
      {selectedCategories.slice(1, selectedCategories.length).map((category, index) => {
        return (
          <SelectSingle
            key={category.slug}
            placeholder="Sous-catégorie"
            searchable
            isDisabled={isDisabled}
            selectedValue={category}
            options={convertSlugsIntoCategories(selectedCategories[index].children, categories)}
            handleSelection={(item) => {
              setDidSelectOtherCategory(false)
              const currents = selectedCategories.slice(0, index + 1)
              currents.push(item as Category)
              handleCategoriesChange(currents)
            }}
          />
        )
      })}
      {selectedCategory && selectedCategory.children?.length > 0 && !didSelectOtherCategory && (
        <SelectSingle
          placeholder="Sous-catégorie"
          searchable
          isDisabled={isDisabled}
          options={convertSlugsIntoCategories(selectedCategory.children, categories).concat({
            slug: 'other',
            name: `Autres ${selectedCategory.name}`,
            children: [],
            hasACV: false,
            ecoScoreAllowed: true,
          })}
          handleSelection={(item) => {
            if (!Array.isArray(item) && item.slug === 'other') {
              setDidSelectOtherCategory(true)
              handleCategoriesChange([...selectedCategories])
            } else {
              handleCategoriesChange([...selectedCategories, item as Category])
            }
          }}
        />
      )}
      {selectedCategory && !selectedCategory.hasACV && selectedCategory.ecoScoreAllowed && (
        <WarningMessage
          text={`La catégorie « ${selectedCategory.name} » est trop générique pour avoir un score ACV.`}
        />
      )}
      {selectedCategory && !selectedCategory.ecoScoreAllowed && (
        <WarningMessage text={`Yuka ne note pas la catégorie « ${selectedCategory.name} ».`} />
      )}
    </>
  )
}

function WarningMessage({ text }: { text: string }) {
  return (
    <div className="flex items-center space-x-2">
      <ExclamationIcon className="h-5 w-5 text-yellow-500" />
      <span className="text-sm text-yellow-500">{text}</span>
    </div>
  )
}
