import LogoEcoScoreA from './../assets/svg/eco-score-A.svg'
import LogoEcoScoreB from './../assets/svg/eco-score-B.svg'
import LogoEcoScoreC from './../assets/svg/eco-score-C.svg'
import LogoEcoScoreD from './../assets/svg/eco-score-D.svg'
import LogoEcoScoreE from './../assets/svg/eco-score-E.svg'
import LogoEcoScorePlaceholder from './../assets/svg/eco-score-placeholder.svg'

export enum EcoScore {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export function getEcoScoreLogo(ecoScore?: EcoScore) {
  if (ecoScore) {
    switch (ecoScore) {
      case EcoScore.A:
        return LogoEcoScoreA
      case EcoScore.B:
        return LogoEcoScoreB
      case EcoScore.C:
        return LogoEcoScoreC
      case EcoScore.D:
        return LogoEcoScoreD
      case EcoScore.E:
        return LogoEcoScoreE
    }
  }
  return LogoEcoScorePlaceholder
}
