import { useState, useEffect, useReducer } from 'react'
import API from './../infrastructure/api'
import { Product, ProductStatus } from './../types/Product'
import { Filters } from './../components/FilterModal'
import { PageInfo } from './../types/PageInfo'

interface ProductsPage {
  products: Product[]
  info: PageInfo
}

export function useFetchProductsPage(page: number, size: number, search: string | null, filters: Filters) {
  const [productsPage, setProductsPage] = useState<ProductsPage | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [ignored, forcePageRefresh] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    let unmounted = false

    let status: ProductStatus | null = null
    if (filters.status) {
      switch (filters.status) {
        case 'draft':
          status = ProductStatus.Draft
          break
        case 'published':
          status = ProductStatus.Synchronized
          break
      }
    }

    API.get('/food/products', {
      params: {
        page: page,
        size: size,
        search: search?.trim(),
        status: status,
        brand: filters.brand,
        category: filters.categorySlug,
        ecoScore: filters.ecoScore,
      },
    })
      .then((response) => {
        if (!unmounted) {
          setProductsPage(response.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        if (!unmounted) {
          setError(err.response.data.message)
          setLoading(false)
        }
      })

    return () => {
      unmounted = true
    }
  }, [page, size, search, ignored, filters.status, filters.brand, filters.categorySlug, filters.ecoScore])

  const updateProduct = (product: Product, index: number) => {
    if (!productsPage) return
    const products = [...productsPage.products]
    products[index] = product
    setProductsPage({ products: products, info: productsPage.info })
  }

  return {
    products: productsPage?.products,
    pageInfo: productsPage?.info,
    loading,
    updateProduct,
    forcePageRefresh,
    error,
  }
}
