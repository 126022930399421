import React, { Fragment, useState, useEffect } from 'react'
import Selectable from '../../types/Selectable'
import ReactSelect, { StylesConfig, createFilter, components, MenuListComponentProps } from 'react-select'
import { matchSorter } from 'match-sorter'
import { useTranslation } from 'react-i18next'

interface SelectProps {
  placeholder: string
  searchable?: boolean
  ignoreAccentsSearch?: boolean
  error?: string
  isDisabled?: boolean
  options: Selectable[]
  handleSelection: (value: Selectable | Selectable[]) => void
}

interface SelectSingleProps extends SelectProps {
  selectedValue?: Selectable
}

interface SelectMultipleProps extends SelectProps {
  selectedValues?: Selectable[]
}

interface SharedSelectProps extends SelectSingleProps, SelectMultipleProps {
  isMulti: boolean
}

function Select({
  isMulti,
  placeholder,
  error,
  options,
  selectedValue,
  selectedValues,
  handleSelection,
  searchable,
  ignoreAccentsSearch,
  isDisabled,
}: SharedSelectProps) {
  const { t } = useTranslation()
  const [filteredOptions, setFilteredOptions] = useState(options)
  const tailwindDangerRed = '#F87171'
  const ringBlue = '#5FA5FA'
  const customStyles: StylesConfig<{ label: string; value: string }, boolean> = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? state.theme.colors.neutral5 : 'white',
      color: 'black',
      fontSize: 14,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.theme.colors.neutral5,
      maxWidth: 150,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      padding: 1,
      color: isDisabled ? state.theme.colors.neutral40 : provided.color,
      backgroundColor: isDisabled ? 'white' : provided.backgroundColor,
      paddingRight: isDisabled ? 6 : provided.paddingRight,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      display: isDisabled ? 'none' : provided.display,
      ':hover': { backgroundColor: state.theme.colors.neutral20 },
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? `0 0 0 1px ${ringBlue}` : 'none',
      borderRadius: 5,
      minHeight: 38,
      backgroundColor: state.isDisabled ? '#F3F4F6' : 'white',
      borderColor: error ? tailwindDangerRed : state.isFocused ? ringBlue : state.theme.colors.neutral20,
      pointerEvents: state.isDisabled ? 'auto' : undefined,
      cursor: state.isDisabled ? 'not-allowed' : 'default',
      ':hover': { borderColor: error ? tailwindDangerRed : state.isFocused ? ringBlue : state.theme.colors.neutral20 },
    }),
    input: (css) => ({
      ...css,
      'input:focus': {
        boxShadow: 'none',
      },
      maxWidth: 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? state.theme.colors.neutral40 : provided.color,
    }),
    noOptionsMessage: (provided) => ({ ...provided, fontSize: 14 }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  }

  useEffect(() => setFilteredOptions(options), [options])

  return (
    <>
      <ReactSelect
        className="block w-full max-w-lg relative sm:text-sm"
        components={{
          IndicatorSeparator: () => null,
          MenuList: OptimizedMenuList,
        }}
        styles={customStyles}
        menuPortalTarget={document.body}
        options={selectablesToOptions(filteredOptions)}
        value={
          isMulti
            ? selectedValues
              ? selectablesToOptions(selectedValues)
              : null
            : selectedValue
            ? { value: selectedValue.slug, label: selectedValue.name }
            : null
        }
        onInputChange={(value) =>
          setFilteredOptions(value.length > 0 ? matchSorter(options, value, { keys: ['name'] }) : options)
        }
        onChange={(value) => {
          let selectedOptions: Selectable | Selectable[] | undefined
          if (value && isMulti) {
            const val = value as { value: string; label: string }[]
            selectedOptions = val
              .map((v) => options.find((o) => o.slug === v.value))
              .filter((o) => o !== undefined) as Selectable[]
          } else if (value) {
            const val = value as { value: string; label: string }
            selectedOptions = options.find((o) => o.slug === val.value)
          }
          if (selectedOptions) handleSelection(selectedOptions)
        }}
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable={false}
        isSearchable={searchable}
        isDisabled={isDisabled}
        maxMenuHeight={200}
        noOptionsMessage={() => t('form.select.no_result_text')}
        filterOption={createFilter({ ignoreAccents: ignoreAccentsSearch ?? true })}
        menuPosition="fixed"
        closeMenuOnScroll={(e) => e.target === document}
      />
      {error && <p className="mt-2 text-sm text-red-400">{error}</p>}
    </>
  )
}

export function SelectSingle(props: SelectSingleProps) {
  return <Select isMulti={false} {...props} />
}

export function SelectMultiple(props: SelectMultipleProps) {
  return <Select isMulti={true} {...props} />
}

function selectablesToOptions(selectables: Selectable[]): { label: string; value: string }[] {
  return selectables.map((selectable) => {
    return { value: selectable.slug, label: selectable.name }
  })
}

function OptimizedMenuList(props: MenuListComponentProps<{ value: string; label: string }, boolean>) {
  const { children } = props
  return (
    <components.MenuList {...props}>{Array.isArray(children) ? children.slice(0, 200) : children}</components.MenuList>
  )
}
