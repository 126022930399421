import React from 'react'
import { Filters } from './index'

export default function BrandExpanded({
  filters,
  setFilters,
  brands,
}: {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  brands: string[]
}) {
  return (
    <select
      id="status"
      name="status"
      className="block pl-2 pr-7 p-0 w-full h-6 text-sm border-gray-300 overflow-ellipsis focus:outline-none focus:border-transparent focus:ring-2 focus:ring-blue-400 rounded-md"
      value={filters.brand}
      onChange={(e) => setFilters((currents) => ({ ...currents, brand: e.target.value }))}
    >
      {brands.map((brand) => (
        <option key={brand} value={brand}>
          {brand}
        </option>
      ))}
    </select>
  )
}
