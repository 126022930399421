import React, { Fragment, useState } from 'react'
import Logo from '../../assets/svg/yuka-logo.svg'
import Carrot from '../../assets/svg/yuka-carrot.svg'
import { Menu, Transition, Popover } from '@headlessui/react'
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { ButtonWhite } from '../Button'
import { useAuth, UserRole } from '../../hooks/useAuth'
import { classNames } from '../../functions/classNames'
import { useHistory } from 'react-router-dom'
import ChangePasswordModal from '../../pages/ChangePassword'
import { NotificationType } from '../../components/Notification'
import { useNotification } from '../../hooks/useNotification'
import { Badge } from '../../components/Badge'
import { useTranslation } from 'react-i18next'

export default function Header() {
  const { t } = useTranslation()
  const history = useHistory()
  const { user, impersonatedUser, stopImpersonateUser, signOut } = useAuth()
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)
  const { setNotification } = useNotification()
  const isImpersonating = user !== undefined && impersonatedUser !== undefined

  const signOutThenRedirect = () => signOut().then(() => history.push('/'))

  const userNavigation: { name: string; href: string; onClick?: () => void }[] = [
    {
      name: 'Changer mon mot de passe',
      href: '#',
      onClick: () => {
        setChangePasswordModalOpen(true)
      },
    },
    {
      name: 'Se déconnecter',
      href: '#',
      onClick: signOutThenRedirect,
    },
  ]
  if (user?.roles.includes(UserRole.Admin)) {
    userNavigation.unshift(
      {
        name: 'Admin',
        href: '/admin/users',
      },
      { name: 'Opérations', href: '/operations' }
    )
  }

  if (isImpersonating) {
    userNavigation.unshift({
      name: "Arrêter l'imitation",
      href: '#',
      onClick: () => stopImpersonateUser().then(() => history.push('/')),
    })
  }
  const healthSimulatorsTabs = [
    { name: t('header.food_health_simulator'), href: '/food/health-grade-calculator' },
    { name: t('header.cosmetic_health_simulator'), href: '/cosmetic/health-grade-calculator' },
  ]
  const productsLinks: { name: string; href: string }[] = []
  if (user?.foodBrands) {
    productsLinks.push({ name: t('header.food_product'), href: '/food/products' })
  }
  if (user?.cosmeticsBrands) {
    productsLinks.push({ name: t('header.cosmetic_product'), href: '/cosmetic/products' })
  }

  const environmentSimulatorsTab = { name: t('header.eco_score_simulator'), href: '/eco-score-calculator' }

  return (
    <>
      <div className="relative">
        <ChangePasswordModal
          open={isChangePasswordModalOpen}
          setOpen={setChangePasswordModalOpen}
          onSuccess={() => {
            setNotification({
              title: 'Modification réussie',
              message: 'Votre mot de passe a bien été changé.',
              type: NotificationType.Success,
            })
          }}
          onError={() => {
            setNotification({
              title: 'Erreur',
              message: 'Impossible de modifier votre mot de passe.',
              type: NotificationType.Error,
            })
          }}
        />
        <Popover className="relative bg-white shadow pb-4 pt-4 z-10">
          {({ open }) => (
            <>
              <div className="mx-6">
                <div className="max-w-screen-2xl mx-auto">
                  <nav
                    className="relative flex items-center justify-between sm:h-10 md:justify-center"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-1 md:inset-y-0 md:left-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <a href="/">
                          <img className="lg:block h-8 w-auto" src={Logo} />
                        </a>
                        <div className="-mr-2 flex items-center md:hidden">
                          <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className="hidden md:flex md:space-x-10">
                        <Menu as="div" className="ml-4 relative flex-shrink-0">
                          {({ open }) => (
                            <>
                              <Menu.Button className="mb-1 text-sm font-medium focus:outline-none text-gray-500 hover:text-gray-900">
                                <div className="flex">
                                  <span>{t('header.simulators')}</span>
                                  <ChevronDownIcon className="h-5 w-5 mt-px" />
                                </div>
                              </Menu.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  <div className="px-4 py-3">
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                      {t('header.health_simulator_section')}
                                    </p>
                                  </div>
                                  {healthSimulatorsTabs.map((item, key) => (
                                    <Menu.Item key={key}>
                                      {({ active }) => (
                                        <a
                                          key={item.name}
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                  <div className="px-4 py-3">
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                      {t('header.environment_simulator_section')}
                                    </p>
                                  </div>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        key={environmentSimulatorsTab.name}
                                        href={environmentSimulatorsTab.href}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        {environmentSimulatorsTab.name}
                                      </a>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                      <div className="hidden md:flex md:space-x-10">
                        {user && productsLinks.length > 1 && (
                          <Menu as="div" className="ml-4 relative flex-shrink-0">
                            {({ open }) => (
                              <>
                                <Menu.Button className="mb-1 text-sm font-medium focus:outline-none text-gray-500 hover:text-gray-900">
                                  <div className="flex">
                                    <span>{t('header.products')}</span>
                                    <ChevronDownIcon className="h-5 w-5 mt-px" />
                                  </div>
                                </Menu.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    {productsLinks.map((item, key) => (
                                      <Menu.Item key={key}>
                                        {({ active }) => (
                                          <a
                                            key={item.name}
                                            href={item.href}
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-2 text-sm text-gray-700'
                                            )}
                                          >
                                            {item.name}
                                          </a>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        )}
                        {user && productsLinks.length == 1 && (
                          <a
                            key={productsLinks[0].name}
                            href={productsLinks[0].href}
                            className="text-sm font-medium text-right text-gray-500 hover:text-gray-900 -mt-0.5"
                          >
                            Produits
                          </a>
                        )}
                      </div>
                      <div className="hidden md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                        {user ? (
                          <Menu as="div" className="ml-4 relative flex-shrink-0">
                            {({ open }) => (
                              <>
                                <div className="flex flex-col items-center">
                                  <Menu.Button className="mb-1 text-sm font-medium focus:outline-none text-gray-500 hover:text-gray-900">
                                    <div className="flex">
                                      <span>{user.email}</span>
                                      <ChevronDownIcon className="h-5 w-5 mt-px" />
                                    </div>
                                  </Menu.Button>
                                  {isImpersonating && <Badge title="Mode imitation" color="green" />}
                                </div>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    {userNavigation.map((item) => (
                                      <Menu.Item key={item.name}>
                                        {({ active }) => (
                                          <a
                                            href={item.href}
                                            onClick={item.onClick}
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-2 text-sm text-gray-700'
                                            )}
                                          >
                                            {item.name}
                                          </a>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        ) : (
                          <Link to="/signin">
                            <ButtonWhite title={t('header.connect')} />
                          </Link>
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img className="lg:block h-8 w-auto" src={Carrot} />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                          <span className="sr-only">Close main menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="px-2 pt-2 pb-3 space-y-1">
                      <div className="px-4 py-3">
                        <p className="text-base font-medium text-gray-400 truncate">
                          {t('header.health_simulator_section')}
                        </p>
                      </div>
                      {healthSimulatorsTabs.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                      <div className="px-4 py-3">
                        <p className="text-base font-medium text-gray-400 truncate">
                          {t('header.environment_simulator_section')}
                        </p>
                      </div>
                      <a
                        key={environmentSimulatorsTab.name}
                        href={environmentSimulatorsTab.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {environmentSimulatorsTab.name}
                      </a>
                    </div>
                    <a
                      onClick={() => (user ? signOutThenRedirect() : history.push('/signin'))}
                      className="block w-full px-5 py-3 text-center font-medium text-gray-500 bg-gray-50 hover:bg-gray-100 hover:text-gray-600"
                    >
                      {user ? 'Se déconnecter' : 'Se connecter'}
                    </a>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </>
  )
}
