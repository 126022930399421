import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import Modal from '../../components/Modal'
import { ButtonPrimary, ButtonWhite, ButtonDeleteIcon } from '../../components/Button'
import { Input } from '../../components/Input'
import { PlusCircleIcon } from '@heroicons/react/solid'
import API from '../../infrastructure/api'
import { NotificationType } from '../../components/Notification'
import { useNotification } from '../../hooks/useNotification'
import { User } from '../../hooks/useAuth'

export default function CreateOrUpdateUserModal({
  user,
  open,
  setOpen,
  onSuccess,
}: {
  user?: User
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccess: () => void
}) {
  const [email, setEmail] = useState<string>(user?.email ?? '')
  const [password, setPassword] = useState<string>('')
  const [foodBrands, setFoodBrands] = useState<string[]>(user?.foodBrands ?? [''])
  const [cosmeticsBrands, setCosmeticsBrands] = useState<string[]>(user?.cosmeticsBrands ?? [''])
  const [error, setError] = useState<{ email?: string; password?: string } | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const { setNotification } = useNotification()
  const isPasswordEditable = user === undefined

  useEffect(() => {
    setEmail('')
    setPassword('')
    setFoodBrands([''])
    setCosmeticsBrands([''])
    setError(undefined)
  }, [open])

  useEffect(() => {
    setEmail(user?.email ?? '')
    setFoodBrands(user?.foodBrands ?? [''])
    setCosmeticsBrands(user?.cosmeticsBrands ?? [''])
  }, [user])

  const validateForm = () => {
    if (email.length === 0) {
      setError({ email: 'Requis' })
      return false
    }
    const emailRegex = new RegExp('\\S+@\\S+\\.\\S+')
    if (!emailRegex.test(email)) {
      setError({ email: 'Invalide' })
      return false
    }
    if (isPasswordEditable && password.length === 0) {
      setError({ password: 'Requis' })
      return false
    }
    if (isPasswordEditable && password.length < 8) {
      setError({ password: '8 caractères minimum' })
      return false
    }
    return true
  }

  return (
    <Modal
      title={user ? "Mettre à jour l'utilisateur" : 'Créer un utilisateur'}
      body={
        <div className="m-4 sm:m-6 mb-6 sm:mb-10 space-y-5">
          <div>
            <Input
              id="email"
              name="email"
              type="email"
              label="E-mail"
              placeholder="E-mail"
              error={error?.email}
              disabled={loading}
              value={email}
              handleChange={(text) => {
                setEmail(text)
                setError(undefined)
              }}
            />
          </div>
          {isPasswordEditable && (
            <div>
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                label="Mot de passe"
                placeholder="Mot de passe"
                error={error?.password}
                disabled={loading}
                value={password}
                handleChange={(text) => {
                  setPassword(text)
                  setError(undefined)
                }}
              />
            </div>
          )}
          <div className="space-y-2">
            {foodBrands.map((brand, index) => (
              <div key={index}>
                <Input
                  id="brands"
                  name="brands"
                  type="text"
                  label={index === 0 ? 'Marques alimentaires' : undefined}
                  placeholder="Nom de la marque"
                  autoFocus
                  disabled={loading}
                  value={brand}
                  handleChange={(text) => {
                    setFoodBrands((currents) => {
                      const newBrands = [...currents]
                      newBrands[index] = text
                      return newBrands
                    })
                    setError(undefined)
                  }}
                  trailingContent={
                    foodBrands.length > 1 ? (
                      <ButtonDeleteIcon
                        type="button"
                        onClick={() => {
                          setFoodBrands((currents) => currents.filter((_, i) => i !== index))
                        }}
                      ></ButtonDeleteIcon>
                    ) : undefined
                  }
                />
              </div>
            ))}

            <button
              className="flex space-x-1 text-sm text-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!foodBrands[foodBrands.length - 1] || loading}
              onClick={() => setFoodBrands((currents) => [...currents, ''])}
            >
              <PlusCircleIcon className="w-5 h-5" />
              <p>Ajouter une autre marque</p>
            </button>
          </div>
          <div className="space-y-2">
            {cosmeticsBrands.map((brand, index) => (
              <div key={index}>
                <Input
                  id="brands"
                  name="brands"
                  type="text"
                  label={index === 0 ? 'Marques cosmétiques' : undefined}
                  placeholder="Nom de la marque"
                  autoFocus
                  disabled={loading}
                  value={brand}
                  handleChange={(text) => {
                    setCosmeticsBrands((currents) => {
                      const newBrands = [...currents]
                      newBrands[index] = text
                      return newBrands
                    })
                    setError(undefined)
                  }}
                  trailingContent={
                    cosmeticsBrands.length > 1 ? (
                      <ButtonDeleteIcon
                        type="button"
                        onClick={() => {
                          setCosmeticsBrands((currents) => currents.filter((_, i) => i !== index))
                        }}
                      ></ButtonDeleteIcon>
                    ) : undefined
                  }
                />
              </div>
            ))}

            <button
              className="flex space-x-1 text-sm text-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!cosmeticsBrands[cosmeticsBrands.length - 1] || loading}
              onClick={() => setCosmeticsBrands((currents) => [...currents, ''])}
            >
              <PlusCircleIcon className="w-5 h-5" />
              <p>Ajouter une autre marque</p>
            </button>
          </div>
        </div>
      }
      footer={
        <div className="space-x-3">
          <ButtonPrimary
            type="submit"
            title={user ? 'Mettre à jour' : "Créer l'utilisateur"}
            onClick={() => {
              setError(undefined)
              if (validateForm()) {
                setLoading(true)
                const validFoodBrands = foodBrands.filter((brands) => brands.length > 0)
                const validCosmeticsBrands = cosmeticsBrands.filter((brands) => brands.length > 0)
                const body: { email: string; password?: string; foodBrands?: string[]; cosmeticsBrands?: string[] } = {
                  email: email,
                }
                if (isPasswordEditable) {
                  body.password = password
                }
                if (validFoodBrands.length > 0) {
                  body.foodBrands = validFoodBrands
                }
                if (validCosmeticsBrands.length > 0) {
                  body.cosmeticsBrands = validCosmeticsBrands
                }
                API.put(`/users/${user?.email ?? email}`, body)
                  .then(() => {
                    setLoading(false)
                    setOpen(false)
                    onSuccess()
                  })
                  .catch((err) => {
                    setLoading(false)
                    setOpen(false)
                    setNotification({
                      title: "Impossible de créer l'utilisateur",
                      message: err.response ? err.response.data.message : undefined,
                      type: NotificationType.Error,
                    })
                  })
              }
            }}
          />
          <ButtonWhite title="Annuler" onClick={() => setOpen(false)} />
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  )
}
