import { useState, useEffect } from 'react'
import Category from '../types/Category'
import Selectable from '../types/Selectable'
import { PackagingComponent } from '../types/PackagingComponent'
import { Ingredient, Region } from '../types/Origin'
import API from '../infrastructure/api'

export function useFetchEcoScoreData(
  callback: (
    categories: Category[],
    labels: Selectable[],
    ingredients: Ingredient[],
    regions: Region[],
    packagingComponents: PackagingComponent[],
    fishingTechniques: Selectable[]
  ) => void
): {
  categories: Category[]
  labels: Selectable[]
  ingredients: Ingredient[]
  packagingComponents: PackagingComponent[]
  regions: Region[]
  fishingTechniques: Selectable[]
} {
  const [categories, setCategories] = useState<Category[]>([])
  const [labels, setLabels] = useState<Selectable[]>([])
  const [ingredients, setIngredients] = useState<Ingredient[]>([])
  const [packagingComponents, setPackagingComponents] = useState<PackagingComponent[]>([])
  const [regions, setRegions] = useState<Region[]>([])
  const [fishingTechniques, setFishingTechniques] = useState<Selectable[]>([])

  useEffect(() => {
    Promise.all([
      API.get<Category[]>('/food/categories'),
      API.get<Selectable[]>('/labels'),
      API.get<Ingredient[]>('/ingredients'),
      API.get<PackagingComponent[]>('/packaging/components'),
      API.get<Region[]>('/regions'),
      API.get<Selectable[]>('/fishing-techniques'),
    ]).then((responses) => {
      setCategories(responses[0].data)
      setLabels(responses[1].data)
      setIngredients(responses[2].data)
      setPackagingComponents(responses[3].data)
      setRegions(responses[4].data)
      setFishingTechniques(responses[5].data)

      callback(
        responses[0].data,
        responses[1].data,
        responses[2].data,
        responses[4].data,
        responses[3].data,
        responses[5].data
      )
    })
  }, [callback])

  return { categories, labels, ingredients, packagingComponents, regions, fishingTechniques }
}
