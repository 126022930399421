import { useState, useCallback } from 'react'
import API from './../infrastructure/api'
import { useNotification } from './useNotification'
import { NotificationType } from '../components/Notification'
import { ProductForm } from '@/types/Product'

export function useCreateOrUpdateProduct() {
  const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false)
  const { setNotification } = useNotification()

  const createOrUpdateProduct = async (product: ProductForm) => {
    setIsCreatingProduct(true)
    return API.put(`/food/products/${product.ean}`, {
      name: product.name,
      ean: product.ean,
      brand: product.brand?.slug,
      category: product.category?.[product.category.length - 1].slug,
      labels: product.labels?.map((label) => label.slug) ?? [],
      origins:
        product.origins?.map((origin) => {
          return {
            ingredient: origin.ingredient?.slug,
            regions: origin.regions?.map((region) => region.slug) ?? [],
            percentage: (Number(origin.percentage) ?? 0) / 100,
          }
        }) ?? [],
      packagingComponents:
        product.packagingComponents?.map((component) => {
          const specificities: { name: string; value: string }[] = []

          if (component.specificities?.bottleColor) {
            specificities.push({ name: 'bottle_color', value: component.specificities.bottleColor })
          }
          if (component.specificities?.plasticColor) {
            specificities.push({ name: 'plastic_color', value: component.specificities.plasticColor })
          }
          if (component.specificities?.wasteBasedMaterialRate) {
            specificities.push({
              name: 'waste_based_material_rate',
              value: component.specificities.wasteBasedMaterialRate.toString(),
            })
          }
          if (component.specificities?.bottleSticker) {
            specificities.push({ name: 'sticker_size', value: component.specificities.bottleSticker })
          }
          if (component.specificities && component.specificities.dryContent) {
            specificities.push({ name: 'content_dryness', value: 'dry' })
          }

          return {
            format: component.format?.slug,
            material: component.material?.slug,
            specificities: specificities,
          }
        }) ?? [],
      fishingTechniques: product.fishingTechniques?.map((fishingTechnique) => fishingTechnique.slug) ?? [],
      ingredientsList: product.ingredientsList,
      calories: product.calories,
      fat: product.fat,
      saturatedFat: product.saturatedFat,
      fibers: product.fibers,
      salt: product.salt,
      sugar: product.sugar,
      carbohydrates: product.carbohydrates,
      proteins: product.proteins,
      fruits: product.fruits,
      healthGrade: product.healthGrade,
    })
      .then(async () => {
        if (product.localFrontImageUrl) {
          const response = await fetch(product.localFrontImageUrl)
          const file = await response.blob()
          const formData = new FormData()
          formData.append('file', file)
          await API.post(`/food/products/front-photo/${product.initialEan ?? product.ean}`, formData)
        } else if (product.deleteFrontPhoto) {
          await API.delete(`/food/products/front-photo/${product.initialEan ?? product.ean}`)
        }
        if (product.synchronizeProductAfterUpload) {
          await API.post(`/food/products/${product.initialEan ?? product.ean}/synchronize`)
        }
        setIsCreatingProduct(false)
      })
      .catch((error) => {
        setIsCreatingProduct(false)
        setNotification({
          title: 'Impossible de sauvegarder le produit',
          message: error.response.data.message,
          type: NotificationType.Error,
        })
        throw error
      })
  }

  const resetApiError = useCallback(() => setNotification(undefined), [setNotification])

  return { isCreatingProduct, createOrUpdateProduct, resetApiError }
}
