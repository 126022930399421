import React from 'react'
import { classNames } from '../../functions/classNames'
import { Row } from '../Row'
import { SelectSingle } from '../Select'
import Selectable from '../../types/Selectable'
import CosmeticIngredient, { Dangerousness } from '../../types/CosmeticIngredient'
import {
  HealthGradeCalculatorSetters,
  HealthGradeCalculatorFormData,
  HealthGradeCalculationError,
} from '../../hooks/useCalculateCosmeticHealthGrade'

export default function CosmeticHealthGradeForm({
  categories,
  formData,
  setters,
  errors,
  detectedIngredients,
}: {
  categories: Selectable[]
  formData: HealthGradeCalculatorFormData
  setters: HealthGradeCalculatorSetters
  detectedIngredients: CosmeticIngredient[]
  errors: HealthGradeCalculationError
}) {
  const getDangerousnessColor = (level: Dangerousness): { background: string; text: string; dot: string } => {
    const colorSettings = { background: '', text: '', dot: '' }

    switch (level) {
      case Dangerousness.HIGH:
        colorSettings.background = 'bg-red-100'
        colorSettings.text = 'text-red-800'
        colorSettings.dot = 'text-red-400'
        break
      case Dangerousness.MODERATE:
        colorSettings.background = 'bg-orange-100'
        colorSettings.text = 'text-orange-800'
        colorSettings.dot = 'text-orange-400'
        break
      case Dangerousness.LOW:
        colorSettings.background = 'bg-yellow-100'
        colorSettings.text = 'text-yellow-800'
        colorSettings.dot = 'text-yellow-400'
        break
      case Dangerousness.NONE:
        colorSettings.background = 'bg-green-100'
        colorSettings.text = 'text-green-800'
        colorSettings.dot = 'text-green-400'
        break
    }

    return colorSettings
  }

  const ingredientsList = detectedIngredients.map((ingredient: CosmeticIngredient) => (
    <span key={ingredient.inci} className="mr-2">
      <span
        className={classNames(
          'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium',
          getDangerousnessColor(ingredient.dangerousness).background,
          getDangerousnessColor(ingredient.dangerousness).text
        )}
      >
        <svg
          className={classNames('mr-1.5 h-2 w-2', getDangerousnessColor(ingredient.dangerousness).dot)}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
        {ingredient.inci}
      </span>
    </span>
  ))

  return (
    <div className="mt-1 sm:mt-5 space-y-1">
      <Row title="Catégorie" className="pb-5" borderTop>
        <SelectSingle
          placeholder="Sélectionnez une catégorie"
          searchable
          error={errors.category}
          isDisabled={false}
          selectedValue={formData.category}
          options={categories}
          ignoreAccentsSearch={true}
          handleSelection={(selectedCategory) => {
            const category = selectedCategory as Selectable
            setters.setCategory(category)
          }}
        />
      </Row>
      <Row title="Composition INCI" className="pb-5" borderTop>
        <textarea
          id="about"
          name="about"
          rows={8}
          disabled={false}
          className={classNames(
            'max-w-lg shadow-sm block w-full sm:text-sm rounded-md focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400 border-gray-300',
            errors.ingredientsList ? 'border-red-400' : 'border-gray-300'
          )}
          onChange={(e) => {
            const list = (e.target as HTMLTextAreaElement).value.trim()
            setters.setIngredients(list)
          }}
          defaultValue={formData.ingredients ?? ''}
        />
        {errors.ingredientsList && <p className="text-sm text-red-400">{errors.ingredientsList}</p>}
        <div className="my-3 max-w-lg">{ingredientsList}</div>
      </Row>
    </div>
  )
}
