import axios from 'axios'
import { useMemo } from 'react'
import { useAuth } from './../hooks/useAuth'

const axiosApiInstance = axios.create({ withCredentials: true, baseURL: '/api' })

export const WithAxios = ({ children }: { children: JSX.Element }) => {
  const { clearUser } = useAuth()

  useMemo(() => {
    axiosApiInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && originalRequest.url === '/auth/refresh') {
          clearUser()
          return Promise.reject(error)
        } else if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          await axiosApiInstance.get('/auth/refresh')
          return axiosApiInstance(originalRequest)
        }
        return Promise.reject(error)
      }
    )
  }, [clearUser])

  return children
}

export default axiosApiInstance
