import React, { Fragment, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { useNotification } from '../../hooks/useNotification'
import { useLocation } from 'react-router-dom'

export enum NotificationType {
  Success,
  Error,
}

export interface NotificationProps {
  title?: string
  message?: string | string[]
  type: NotificationType
}

export function Notification() {
  const { notification, setNotification } = useNotification()
  const location = useLocation()

  useEffect(() => {
    // remove notifications when page changed
    setNotification(undefined)
  }, [location, setNotification])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-10 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={notification !== undefined}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">{getIcon(notification?.type)}</div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{notification?.title}</p>
                    {notification?.message && (
                      <>
                        {Array.isArray(notification.message) ? (
                          <ul className="pl-6 list-disc mt-1 text-sm text-gray-500">
                            {notification.message.map((item, i) => {
                              return <li key={i}>{item}</li>
                            })}
                          </ul>
                        ) : (
                          <p className="mt-1 text-sm text-gray-500">{notification.message}</p>
                        )}
                      </>
                    )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setNotification(undefined)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function getIcon(notificationType?: NotificationType) {
  switch (notificationType) {
    case NotificationType.Success: {
      return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
    }
    case NotificationType.Error: {
      return <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
    }
  }
}
