export enum Dangerousness {
  HIGH = 1,
  MODERATE = 2,
  LOW = 3,
  NONE = 4,
}

export default interface Additive {
  code: string
  dangerousness: Dangerousness
}
