import React, { useState, useEffect, useCallback } from 'react'
import API from '../../infrastructure/api'
import { ButtonPrimary } from '../../components/Button'
import { Link } from 'react-router-dom'
import { Badge } from '../../components/Badge'
import { classNames } from '../../functions/classNames'
import { CosmeticProduct, ProductStatus } from '../../types/CosmeticProduct'
import { useHistory, useParams } from 'react-router-dom'
import { useTitle } from '../../hooks/useTitle'
import { useNotification } from '../../hooks/useNotification'
import { NotificationType } from '../../components/Notification'
import ProductImagePlaceholder from '../../assets/svg/product-default.svg'
import ProductActionMenu from '../../components/ProductActionMenu'
import PaginationFooter from '../../components/Pagination'
import { useFetchCosmeticProductsPage } from '../../hooks/useFetchCosmeticProductsPage'
import { SearchBar } from '../../components/Search'
import useQuery from '../../hooks/useQuery'
import { PlusIcon } from '@heroicons/react/solid'
import BarCodeModal from '../../components/BarCodeModal'
import DeleteProductModal from '@/components/DeleteProductModal'
import { pluralize } from '@/functions/pluralize'
import BulkOperationsMenu from './BulkOperationsMenu'
import BulkUpdateCategoryModal from './BulkUpdateCategoryModal'

function CreateProductButton() {
  return (
    <Link to="/cosmetic/products/new">
      <ButtonPrimary title="Créer un produit" leftIcon={<PlusIcon className="h-4 w-4" />} />
    </Link>
  )
}

function StatusBadge({ status }: { status: ProductStatus }) {
  switch (status) {
    case ProductStatus.Draft:
      return <Badge title="Brouillon" color="gray" />
    case ProductStatus.Synchronizing:
      return <Badge title="En traitement" color="yellow" />
    case ProductStatus.Synchronized:
      return <Badge title="Publié" color="green" />
  }
}

function genderLabel(gender: string) {
  switch (gender) {
    case 'M':
      return 'Mixte'
    case 'E':
      return 'Enfant'
    case 'H':
      return 'Homme'
    case 'F':
      return 'Femme'
  }
}

export default function CosmeticProductsPage() {
  useTitle('Produits')

  const pageSize = 20
  const history = useHistory()
  const params = useParams<{ page?: string | undefined }>()
  const query = useQuery()
  const search = query.get('search')
  const [page, setPage] = useState<number>(params.page ? Number(params.page) : 1)
  const [tempSearchText, setTempSearchText] = useState<string>(search ?? '')
  const [searchText, setSearchText] = useState<string>(search ?? '')
  const [selectedProducts, setSelectedProducts] = useState<CosmeticProduct[]>([])
  const [isBulkOperationsModalOpen, setIsBulkOperationsModalOpen] = useState<boolean>(false)
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] = useState<boolean>(false)
  const [productToDelete, setProductToDelete] = useState<CosmeticProduct | undefined>(undefined)

  const { products, pageInfo, updateProduct, forcePageRefresh } = useFetchCosmeticProductsPage(page, pageSize, search)

  const pushUpdatedPath = useCallback(() => {
    const url = new URL(`/cosmetic/products/${page}`, window.location.origin)
    if (searchText) {
      url.searchParams.append('search', searchText)
    }
    setSelectedProducts([])
    history.push(url.pathname + url.search)
  }, [history, page, searchText])

  useEffect(() => {
    const timeout = setTimeout(() => setSearchText(tempSearchText), 500)
    return () => clearTimeout(timeout)
  }, [tempSearchText, setSearchText])

  useEffect(() => pushUpdatedPath(), [pushUpdatedPath])

  const { setNotification } = useNotification()
  const editProduct = (product: CosmeticProduct) => {
    history.push(`/cosmetic/products/edit/${product.ean}`)
  }
  const synchronizeProduct = (product: CosmeticProduct, index: number) => {
    if (!products) return

    const temp = [...products]
    temp[index].status = ProductStatus.Synchronizing
    updateProduct(temp[index], index)
    API.post(`/cosmetic/products/${product.ean}/synchronize`)
      .then((response) => updateProduct(response.data, index))
      .catch((err) => {
        const temp = [...products]
        temp[index].status = ProductStatus.Draft
        updateProduct(temp[index], index)
        setNotification({
          title: 'Erreur',
          message: `Impossible de synchroniser ${product.name}. ${err.response.data.message}`,
          type: NotificationType.Error,
        })
      })
  }

  const deleteProduct = (product: CosmeticProduct) => {
    if (!products) return
    setProductToDelete(product)
    setIsDeleteProductModalOpen(true)
  }

  // const getResultColor = (total: number): string => {
  //   if (!total) {
  //     return 'bg-gray-200'
  //   }

  //   if (total >= 75) {
  //     return 'bg-green-500'
  //   }

  //   if (total >= 50) {
  //     return 'bg-yellow-400'
  //   }

  //   if (total >= 25) {
  //     return 'bg-orange-400'
  //   }

  //   return 'bg-red-500'
  // }

  return (
    <div className="m-6">
      <DeleteProductModal
        product={productToDelete}
        open={isDeleteProductModalOpen}
        endpoint={`/cosmetic/products/${productToDelete?.ean}`}
        setOpen={(open) => {
          setIsDeleteProductModalOpen(open)
          if (!open) {
            setProductToDelete(undefined)
          }
        }}
        onSuccess={forcePageRefresh}
      />
      <BulkUpdateCategoryModal
        products={selectedProducts}
        open={isBulkOperationsModalOpen}
        setOpen={setIsBulkOperationsModalOpen}
      />
      <div className="max-w-screen-2xl mx-auto">
        {selectedProducts.length > 0 ? (
          <div className="flex justify-end items-center mb-4 space-x-4">
            <span className="text-sm text-gray-500">{pluralize(selectedProducts.length, 'sélectionné')}</span>
            <div className="w-px h-6 bg-gray-300" />
            <button
              className="text-sm text-green-500 font-medium hover:text-gray-700"
              onClick={() => setSelectedProducts([])}
            >
              Désélectionner
            </button>
            <BulkOperationsMenu onEditCategoryClick={() => setIsBulkOperationsModalOpen(true)} />
          </div>
        ) : (
          <div className="flex justify-between items-end mb-4 space-x-4">
            <div className="flex-1 max-w-md items-center">
              <SearchBar
                placeholder="Recherche par EAN ou nom"
                value={tempSearchText}
                onChange={(text) => {
                  setPage(1)
                  setTempSearchText(text)
                }}
              />
            </div>
            <div className="flex items-center space-x-2">
              <CreateProductButton />
            </div>
          </div>
        )}
        {products && products.length > 0 ? (
          <div className="inline-block overflow-x-scroll w-full shadow sm:rounded-md bg-white divide-y divide-gray-200">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {/* <th scope="col" className="px-6 py-3">
                    <Checkbox
                      id="checkbox_products_all"
                      checked={selectedProducts.length === pageSize}
                      setChecked={(checked) => (checked ? setSelectedProducts(products) : setSelectedProducts([]))}
                    />
                  </th> */}
                  <th
                    scope="col"
                    className="pl-4 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nom
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Statut
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Marque
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Catégorie
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Genre
                  </th>
                  {/* <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Score
                  </th> */}
                  <th scope="col" className="relative px-4 py-3">
                    <span className="sr-only">Menu</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {products.map((product, index) => {
                  const isProductSelected = selectedProducts.find((p) => p.ean === product.ean) !== undefined
                  return (
                    <tr key={product.id} className={isProductSelected ? 'bg-gray-50' : ''}>
                      {/* <td className="pl-6 pr-0 py-4">
                        <Checkbox
                          id={`checkbox_${product.id}`}
                          checked={selectedProducts.find((p) => p.ean === product.ean) !== undefined}
                          setChecked={(checked) =>
                            checked
                              ? setSelectedProducts((currents) => [...currents, product])
                              : setSelectedProducts((currents) => [...currents].filter((p) => p.ean !== product.ean))
                          }
                        />
                      </td> */}
                      <td className="pl-4 pr-4 py-4">
                        <div className="flex items-center space-x-4">
                          <div className="flex-none h-20 w-20">
                            <img
                              className={classNames(
                                'object-scale-down h-20 w-auto',
                                product.frontPhotoUrl ? 'cursor-pointer' : ''
                              )}
                              src={product.frontPhotoUrl ?? ProductImagePlaceholder}
                              onClick={() => {
                                if (product.frontPhotoUrl) window.open(product.frontPhotoUrl)
                              }}
                            />
                          </div>
                          {/* <div className="flex-none h-20 w-20">
                            <img
                              className={classNames(
                                'object-scale-down h-20 w-auto',
                                product.localFrontImageUrl ? 'cursor-pointer' : ''
                              )}
                              src={product.localFrontImageUrl ?? ProductImagePlaceholder}
                              onClick={() => {
                                if (product.localFrontImageUrl) window.open(product.localFrontImageUrl)
                              }}
                            />
                          </div> */}
                          <div className="flex flex-col">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault()
                                editProduct(product)
                              }}
                              className="text-sm font-medium text-gray-900"
                            >
                              {product.name}
                            </a>
                            <BarCodeModal ean={product.ean} label={product.name} />
                          </div>
                        </div>
                      </td>
                      <td className="pl-4 pr-6 py-4 text-sm text-gray-500">
                        {new Date(product.updated).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-4">
                        <StatusBadge status={product.status} />
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500">{product.brand}</td>
                      <td className="px-4 py-4 text-sm text-gray-500">{product.category?.name}</td>
                      <td className="px-4 py-4 text-sm text-gray-500">
                        {product.gender && genderLabel(product.gender)}
                      </td>
                      {/* <td className="pl-4 pr-6 py-4 text-sm text-gray-500">
                        <span
                          className={
                            (product.healthGrade !== undefined ? getResultColor(product.healthGrade) : '') +
                            ' inline-block rounded-full h-2 w-2'
                          }
                        ></span>
                        {product.healthGrade !== undefined && (
                          <span className="ml-2">
                            <span className="font-medium text-black">{product.healthGrade}</span>
                            <span className="text-xs text-gray-400">/100</span>
                          </span>
                        )}
                      </td> */}

                      <td className="pl-4 pr-6 py-4">
                        <ProductActionMenu
                          displaySynchronizeAction={product.status === ProductStatus.Draft}
                          onEditClick={() => editProduct(product)}
                          onSynchronizeClick={() => synchronizeProduct(product, index)}
                          onDeleteClick={() => deleteProduct(product)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {pageInfo && <PaginationFooter currentPage={page} pageInfo={pageInfo} onPageClick={setPage} />}
          </div>
        ) : (
          <div className="text-center mt-20">Aucun résultat</div>
        )}
      </div>
    </div>
  )
}
