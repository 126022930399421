import React, { useContext, createContext, ReactNode, useState } from 'react'
import { useCookies } from 'react-cookie'
import API from './../infrastructure/api'

export enum UserRole {
  Admin = 'ADMIN',
}

export interface User {
  id: number
  created: string
  email: string
  foodBrands?: string[]
  cosmeticsBrands?: string[]
  roles: UserRole[]
}

interface AuthContextProps {
  user?: User
  impersonatedUser?: User
  signIn: (email: string, password: string) => Promise<User>
  signOut: () => Promise<void>
  clearUser: () => void
  impersonateUser: (userId: number) => Promise<User>
  stopImpersonateUser: () => Promise<void>
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [impersonatedUser, setImpersonatedUser] = useState<User | undefined>(undefined)
  const [cookies, setCookie, removeCookie] = useCookies(['user', 'impersonatedUser'])

  const signIn = (email: string, password: string) =>
    API.post('/auth/login', { email: email, password: password }).then((response) => {
      const data: User = response.data
      setUser(data)
      setCookie('user', data, { path: '/' })
      return data
    })

  const signOut = () => API.post('/auth/logout', { user }).then(clearUser)

  const clearUser = () => {
    removeCookie('user', { path: '/' })
    removeCookie('impersonatedUser', { path: '/' })
    setUser(undefined)
    setImpersonatedUser(undefined)
  }

  const impersonateUser = (userId: number) =>
    API.post(`/users/${userId}/impersonate`).then((response) => {
      const data: User = response.data
      setImpersonatedUser(data)
      setCookie('impersonatedUser', data, { path: '/' })
      return data
    })

  const stopImpersonateUser = () =>
    API.post(`/users/impersonate/stop`).then(() => {
      removeCookie('impersonatedUser', { path: '/' })
      setImpersonatedUser(undefined)
    })

  if (cookies.user && !user) {
    setUser(cookies.user)
  }

  if (cookies.impersonatedUser && !impersonatedUser) {
    setImpersonatedUser(cookies.impersonatedUser)
  }

  return (
    <AuthContext.Provider
      value={{
        user: impersonatedUser ?? user,
        impersonatedUser,
        signIn,
        signOut,
        clearUser,
        impersonateUser,
        stopImpersonateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
