import React from 'react'
import Menu from '../../components/Menu'
import { ButtonWhite } from '../../components/Button'
import { ChevronDownIcon } from '@heroicons/react/solid'

export default function BulkOperationsMenu({ onEditCategoryClick }: { onEditCategoryClick: () => void }) {
  return (
    <Menu
      topActions={[
        {
          title: 'Modifier la catégorie',
          onClick: onEditCategoryClick,
        },
      ]}
      placement="bottom-start"
      offset={[0, 5]}
      menuButtonContent={<ButtonWhite title="Actions" rightContent={<ChevronDownIcon className="w-5" />} />}
      menuButtonClassName="focus:outline-none"
    />
  )
}
