import React from 'react'
import EcoScoreCalculator from './'
import useQuery from '../../hooks/useQuery'

export default function PrefilledEcoScoreCalculator() {
  const query = useQuery()
  const category = query.get('category')
  const labels = query.get('labels')
  const origins = query.get('origins')
  const unspecifiedOrigins = query.get('unspecifiedOrigins') === 'true'
  const packaging = query.get('packaging')
  const fishingTechniques = query.get('fishingTechniques')
  const ingredientsList = query.get('ingredients')

  let labelSlugs
  let rawOrigins
  let packagingComponentSlugs
  let fishingTechniqueSlugs
  let rawIngredientsList
  try {
    if (labels) {
      labelSlugs = JSON.parse(labels) as string[]
    }
    if (packaging) {
      packagingComponentSlugs = JSON.parse(packaging) as { format: string; material: string }[]
    }
    if (fishingTechniques) {
      fishingTechniqueSlugs = JSON.parse(fishingTechniques) as string[]
    }
    if (origins) {
      rawOrigins = JSON.parse(origins) as { ingredientSlug: string; regions: string[]; percentage: number }[]
    }
    if (ingredientsList) {
      rawIngredientsList = JSON.parse(ingredientsList)
    }
  } catch (err) {}

  return (
    <EcoScoreCalculator
      categorySlug={category}
      labelSlugs={labelSlugs}
      rawOrigins={rawOrigins}
      unspecifiedOrigins={unspecifiedOrigins}
      packagingComponentSlugs={packagingComponentSlugs}
      fishingTechniqueSlugs={fishingTechniqueSlugs}
      rawIngredientsList={rawIngredientsList}
    />
  )
}
