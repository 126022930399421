import Category from '../types/Category'
import Additive from '../types/Additive'
import { HealthGradeResult } from '../components/FoodHealthGradeDetails'
import { useState, useCallback, useEffect } from 'react'
import API from '../infrastructure/api'
import HealthGradeCalculatorPrefill from '../types/HealthGradeCalculatorPrefill'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useNotification } from '../hooks/useNotification'
import { NotificationType } from '../components/Notification'

export interface HealthGradeCalculationErrors {
  category?: string
  organic?: string
  ingredients?: string
  calories?: string
  fat?: string
  saturatedFat?: string
  carbohydrates?: string
  sugar?: string
  proteins?: string
  salt?: string
}

export function useCalculateHealthGrade(formPrefill: HealthGradeCalculatorPrefill) {
  const { t, i18n } = useTranslation('health-grade')
  const [healthGradeResult, setHealthGradeResult] = useState<HealthGradeResult | undefined>(undefined)
  const [detectedAdditives, setDetectedAdditives] = useState<Additive[]>([])
  const [isLoadingHealthGrade, setIsLoadingHealthGrade] = useState<boolean>(false)

  const [category, setCategory] = useState<Category | undefined>()
  const [organic, setOrganic] = useState<boolean>(formPrefill.organic)
  const [calories, setCalories] = useState<number | undefined>(formPrefill.calories)
  const [fat, setFat] = useState<number | undefined>(formPrefill.fat)
  const [saturatedFat, setSaturatedFat] = useState<number | undefined>(formPrefill.saturatedFat)
  const [carbohydrates, setCarbohydrates] = useState<number | undefined>(formPrefill.carbohydrates)
  const [sugar, setSugar] = useState<number | undefined>(formPrefill.sugar)
  const [proteins, setProteins] = useState<number | undefined>(formPrefill.proteins)
  const [fibers, setFibers] = useState<number | undefined>(formPrefill.fibers)
  const [salt, setSalt] = useState<number | undefined>(formPrefill.salt)
  const [fruits, setFruits] = useState<number | undefined>(formPrefill.fruits)
  const [ingredients, setIngredients] = useState<string | null | undefined>(formPrefill.ingredientsList)
  const [categories, setCategories] = useState<Category[]>([])
  const [errors, setErrors] = useState<HealthGradeCalculationErrors>({})
  const { setNotification } = useNotification()

  useEffect(() => {
    if (!i18n.language) {
      return
    }

    API.get<Category[]>(`/food/categories?lang=${i18n.language}`).then((response) => {
      setCategories(response.data)
    })
  }, [i18n.language])

  useEffect(() => {
    setCategory(categories.find((c) => c.slug === formPrefill.categorySlug))
  }, [formPrefill.categorySlug, categories])

  const calculateHealthGrade = useCallback(
    (displayErrors = true) => {
      const validateData = (data: HealthGradeCalculatorFormData, displayErrors: boolean): boolean => {
        setErrors({})
        const optionalFields = ['fibers', 'fruits', 'organic']
        const newErrors: HealthGradeCalculationErrors = {}
        let isValid = true

        for (const [key, value] of Object.entries(data)) {
          if (optionalFields.includes(key)) {
            continue
          }

          const formKey = key as keyof HealthGradeCalculatorFormData
          const errorKey = key as keyof HealthGradeCalculationErrors

          if (
            value === undefined ||
            value === null ||
            (typeof data[formKey] === 'number' && isNaN(value)) ||
            (typeof data[formKey] === 'string' && value === '')
          ) {
            newErrors[errorKey] = t('form_fields.validation.mandatory')
            isValid = false
          }
        }

        if (displayErrors) {
          setErrors(newErrors)
        }

        return isValid
      }

      if (
        !validateData(
          {
            category,
            organic,
            calories,
            fat,
            saturatedFat,
            carbohydrates,
            sugar,
            proteins,
            fibers,
            salt,
            fruits,
            ingredients,
          },
          displayErrors
        )
      ) {
        resetHealthGradeDetails()

        return
      }

      const body = {
        category: category?.slug,
        bio: organic,
        calories: calories,
        fat: fat,
        saturatedFat: saturatedFat,
        carbohydrates: carbohydrates,
        sugar: sugar,
        proteins: proteins,
        fibers: fibers,
        salt: salt,
        fruits: fruits,
        ingredientsList: ingredients,
      }

      const cancelTokenSource = axios.CancelToken.source()

      const query = setTimeout(() => {
        setIsLoadingHealthGrade(true)
        API.post('/food/health-grade/evaluate', body, { cancelToken: cancelTokenSource.token })
          .then(({ data }) => {
            const { grade, additives } = data
            setHealthGradeResult(grade)
            setDetectedAdditives(
              additives.map(({ code, dangerousnessLevel }: { code: string; dangerousnessLevel: string }) => {
                return { code: code, dangerousness: dangerousnessLevel }
              })
            )
            setIsLoadingHealthGrade(false)
            setNotification(undefined)
          })
          .catch(() => {
            setIsLoadingHealthGrade(false)
            setNotification({
              title: 'Échec de la simulation',
              type: NotificationType.Error,
            })
          })
      }, 1000)

      return () => {
        clearTimeout(query)
        cancelTokenSource.cancel()
      }
    },
    [
      category,
      organic,
      calories,
      fat,
      saturatedFat,
      carbohydrates,
      sugar,
      proteins,
      fibers,
      fruits,
      ingredients,
      salt,
      setNotification,
      t,
    ]
  )

  const resetHealthGradeDetails = () => {
    setHealthGradeResult(undefined)
    setDetectedAdditives([])
    setIsLoadingHealthGrade(false)
  }

  const resetHealthGradeForm = () => {
    setErrors({})
    setCategory(undefined)
    setOrganic(false)
    setCalories(undefined)
    setFat(undefined)
    setSaturatedFat(undefined)
    setCarbohydrates(undefined)
    setSugar(undefined)
    setProteins(undefined)
    setFibers(undefined)
    setFruits(undefined)
    setIngredients(undefined)
    setSalt(undefined)
  }

  return {
    errors,
    categories,
    isLoadingHealthGrade: isLoadingHealthGrade,
    calculateHealthGrade: calculateHealthGrade,
    resetHealthGradeDetails: resetHealthGradeDetails,
    resetHealthGradeForm: resetHealthGradeForm,
    healthGradeResult: healthGradeResult,
    detectedAdditives: detectedAdditives,
    formData: {
      category,
      organic,
      calories,
      fat,
      saturatedFat,
      carbohydrates,
      sugar,
      proteins,
      fibers,
      salt,
      fruits,
      ingredients,
    },
    setters: {
      setCategory,
      setOrganic,
      setCalories,
      setFat,
      setSaturatedFat,
      setCarbohydrates,
      setSugar,
      setProteins,
      setFibers,
      setSalt,
      setFruits,
      setIngredients,
    },
  }
}

export interface HealthGradeCalculatorSetters {
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>
  setOrganic: React.Dispatch<React.SetStateAction<boolean>>
  setCalories: React.Dispatch<React.SetStateAction<number | undefined>>
  setFat: React.Dispatch<React.SetStateAction<number | undefined>>
  setSaturatedFat: React.Dispatch<React.SetStateAction<number | undefined>>
  setCarbohydrates: React.Dispatch<React.SetStateAction<number | undefined>>
  setSugar: React.Dispatch<React.SetStateAction<number | undefined>>
  setProteins: React.Dispatch<React.SetStateAction<number | undefined>>
  setFibers: React.Dispatch<React.SetStateAction<number | undefined>>
  setSalt: React.Dispatch<React.SetStateAction<number | undefined>>
  setFruits: React.Dispatch<React.SetStateAction<number | undefined>>
  setIngredients: React.Dispatch<React.SetStateAction<string | null | undefined>>
}

export interface HealthGradeCalculatorFormData {
  category: Category | undefined
  organic: boolean
  calories: number | undefined
  fat: number | undefined
  saturatedFat: number | undefined
  carbohydrates: number | undefined
  sugar: number | undefined
  proteins: number | undefined
  fibers: number | undefined
  salt: number | undefined
  fruits: number | undefined
  ingredients: string | null | undefined
}
