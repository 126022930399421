import React from 'react'
import { ExclamationIcon } from '@heroicons/react/solid'

export default function Warning({ text }: { text: string }) {
  return (
    <div className="flex flex-col mt-10 items-center space-y-1">
      <ExclamationIcon className="h-8 w-8 text-gray-500" />
      <p className="text-sm font-medium text-gray-500">{text}</p>
    </div>
  )
}
