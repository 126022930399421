import { useState, useCallback } from 'react'
import API from '../../infrastructure/api'
import { useNotification } from '../useNotification'
import { NotificationType } from '../../components/Notification'
import { CosmeticForm } from '@/types/CosmeticForm'

export function useCreateOrUpdateProduct() {
  const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false)
  const { setNotification } = useNotification()

  const createOrUpdateProduct = async (product: CosmeticForm) => {
    setIsCreatingProduct(true)
    return API.put(`/cosmetic/products/${product.ean}`, {
      name: product.name,
      ean: product.ean,
      brand: product.brand?.slug,
      category: product.category?.slug,
      labels: product.labels,
      gender: product.gender?.slug,
      quantity: product.quantity,
      quantityUnit: product.quantityUnit,
      composition: product.composition,
      healthGrade: product.healthGrade,
    })
      .then(async () => {
        if (product.localFrontImageUrl) {
          const response = await fetch(product.localFrontImageUrl)
          const file = await response.blob()
          const formData = new FormData()
          formData.append('file', file)
          await API.post(`/cosmetic/products/front-photo/${product.ean}`, formData)
        } else if (product.deleteFrontPhoto) {
          await API.delete(`/cosmetic/products/front-photo/${product.ean}`)
        }
        if (product.synchronizeProductAfterUpload) {
          await API.post(`/cosmetic/products/${product.ean}/synchronize`)
        }
        setIsCreatingProduct(false)
      })
      .catch((error) => {
        setIsCreatingProduct(false)
        setNotification({
          title: 'Impossible de sauvegarder le produit',
          message: error.response.data.message,
          type: NotificationType.Error,
        })
        throw error
      })
  }

  const resetApiError = useCallback(() => setNotification(undefined), [setNotification])

  return { isCreatingProduct, createOrUpdateProduct, resetApiError }
}
