import { useState, useCallback } from 'react'
import { EcoScoreGradeDetails } from '../components/EcoScoreDetails'
import Category from '../types/Category'
import Selectable from '../types/Selectable'
import { PackagingComponent, Specificities } from '../types/PackagingComponent'
import { Origin } from '../types/Origin'
import { useNotification } from '../hooks/useNotification'
import API from '../infrastructure/api'
import axios from 'axios'
import { OriginSectionErrors } from '../components/EcoScore/OriginSection'
import { NotificationType } from '../components/Notification'

export interface EcoScoreCalculatorErrors {
  category?: string
  ingredientsList?: string
  packaging?: { format?: string; material?: string }[]
  origins?: OriginSectionErrors
}

export function useCalculateEcoScore() {
  const [isLoadingEcoScore, setIsLoadingEcoScore] = useState<boolean>(false)
  const [ecoScoreDetails, setEcoScoreDetails] = useState<EcoScoreGradeDetails | undefined>(undefined)
  const [errors, setErrors] = useState<EcoScoreCalculatorErrors>({})
  const { setNotification } = useNotification()

  const validateEcoScore = (
    category: Category | undefined,
    labels: Selectable[],
    origins: Origin[],
    packagingComponents: PackagingComponent[],
    fishingTechniques: Selectable[],
    ingredientsList: string | undefined,
    displayErrors: boolean,
    skipIngredientsValidation?: boolean
  ) => {
    let isValid = true
    const newErrors: EcoScoreCalculatorErrors = {}

    if (!category) {
      isValid = false
      newErrors.category = 'Obligatoire'
    }

    if (!ingredientsList && !skipIngredientsValidation) {
      isValid = false
      newErrors.ingredientsList = 'Obligatoire'
    }

    newErrors.origins = { rows: [] }
    let totalPercentage = 0
    origins.forEach((origin, index) => {
      if (newErrors.origins) {
        newErrors.origins.rows[index] = {}
        if (!origin.ingredient) {
          newErrors.origins.rows[index].ingredient = 'Obligatoire'
          isValid = false
        }
        if (!origin.regions || origin.regions.length === 0) {
          newErrors.origins.rows[index].regions = 'Obligatoire'
          isValid = false
        }
        if (origin.percentage) {
          const value = Number(origin.percentage)
          totalPercentage += value
          if (value < 0 || value > 100) {
            newErrors.origins.rows[index].percentage = { message: 'Invalide' }
            isValid = false
          }
        } else {
          newErrors.origins.rows[index].percentage = { message: 'Obligatoire' }
          isValid = false
        }
      }
    })

    if (totalPercentage > 100 && origins.find((origin) => (origin.percentage ?? 0) > 100) === undefined) {
      Array.from(Array(origins.length).keys()).forEach((index) => {
        if (newErrors.origins) {
          newErrors.origins.rows[index].percentage = { displayRedBorder: true }
        }
      })
      newErrors.origins.global = 'La somme des ingrédients est supérieure à 100%.'
      isValid = false
    }

    isValid = isValid && packagingComponents.length > 0
    newErrors.packaging = []
    packagingComponents.forEach((component, index) => {
      if (newErrors.packaging) {
        newErrors.packaging[index] = {}
        if (!component.format) {
          newErrors.packaging[index].format = 'Obligatoire'
          isValid = false
        }
        if (!component.material && component.format?.slug !== 'bulk') {
          newErrors.packaging[index].material = 'Obligatoire'
          isValid = false
        }
      }
    })

    if (displayErrors) {
      setErrors(newErrors)
    }

    return isValid
  }

  const resetErrorsAndEcoScoreDetails = () => {
    setErrors({})
    setEcoScoreDetails(undefined)
    setIsLoadingEcoScore(false)
  }

  const calculateEcoScore = useCallback(
    (
      category: Category | undefined,
      labels: Selectable[],
      origins: Origin[],
      packagingComponents: PackagingComponent[],
      fishingTechniques: Selectable[],
      ingredientsList: string | undefined,
      displayErrors = true
    ) => {
      if (
        validateEcoScore(
          category,
          labels,
          origins,
          packagingComponents,
          fishingTechniques,
          ingredientsList,
          displayErrors
        ) &&
        category &&
        category.hasACV
      ) {
        setIsLoadingEcoScore(true)

        const body: {
          category: string
          bio: boolean
          labels: string[]
          packagingComponents: {
            component: { materialSlug?: string; formatSlug?: string }
            specificities?: Specificities
          }[]
          origins?: { ingredientSlug?: string; percentage: number; regions?: string[] }[]
          unspecifiedOrigins: boolean
          ingredientsList?: string
          fishingTechniques?: string[]
        } = {
          category: category.slug,
          bio: labels.find((label) => label.slug === 'organic') !== undefined,
          labels: labels.filter((label) => label.slug !== 'organic').map((label) => label.slug),
          packagingComponents: packagingComponents.map((component) => {
            return {
              component: {
                materialSlug: component.material?.slug,
                formatSlug: component.format?.slug,
              },
              specificities: component.specificities,
            }
          }),
          origins:
            origins.length === 0
              ? undefined
              : origins
                  .filter((origin) => origin.ingredient && origin.regions && origin.percentage)
                  .map((origin) => ({
                    ingredientSlug: origin.ingredient?.slug,
                    percentage: (Number(origin.percentage) ?? 0) / 100,
                    regions: origin.regions?.map((region) => region.slug.toLowerCase()),
                  })),
          unspecifiedOrigins: origins.length === 0,
          ingredientsList: ingredientsList,
          fishingTechniques: undefined,
        }

        if (fishingTechniques.length > 0) {
          body.fishingTechniques = fishingTechniques.map((fishingTechnique) => fishingTechnique.slug)
        }

        const cancelTokenSource = axios.CancelToken.source()
        const query = setTimeout(() => {
          API.post('/eco-score/evaluate', body, { cancelToken: cancelTokenSource.token })
            .then((response) => {
              setIsLoadingEcoScore(false)
              setEcoScoreDetails(response.data)
              setNotification(undefined)
            })
            .catch((error) => {
              setIsLoadingEcoScore(false)
              setEcoScoreDetails(undefined)
              setNotification({
                title: 'Échec de la simulation',
                message: error.response.data.message,
                type: NotificationType.Error,
              })
            })
        }, 1000)

        return () => {
          clearTimeout(query)
          cancelTokenSource.cancel()
        }
      }
    },
    [setNotification]
  )

  return {
    isLoadingEcoScore,
    ecoScoreDetails,
    errors,
    calculateEcoScore,
    validateEcoScore,
    resetErrorsAndEcoScoreDetails,
  }
}
