import React, { useState } from 'react'
import Card from '../Card'
import Additive, { Dangerousness } from '../../types/Additive'
import { useTranslation } from 'react-i18next'

export interface HealthCalculationResult {
  grade?: HealthGradeResult
  additives: Additive[]
  isLoading: boolean
}

export interface HealthGradeResult {
  total?: number
  nutritionPoints?: number
  additivesPoints?: number
  organicPoints?: number
}

export default function HealthGradeDetails({
  grade: gradeResults,
  additives: detectedAdditives,
  isLoading: isLoading,
}: HealthCalculationResult) {
  const { t } = useTranslation('health-grade')
  const { total, nutritionPoints, additivesPoints, organicPoints } = gradeResults || {}
  const [isHelpVisible, setHelpVisible] = useState<boolean>(false)

  const getResultColor = (total: number): string => {
    if (total >= 75) {
      return 'bg-green-700'
    }

    if (total >= 50) {
      return 'bg-green-400'
    }

    if (total >= 25) {
      return 'bg-orange-400'
    }

    return 'bg-red-600'
  }

  const hasAtLeastOneDangerousAdditive = detectedAdditives.some((additive) => {
    return additive.dangerousness === Dangerousness.HIGH
  })

  const shouldPoorGradeBeJustified =
    nutritionPoints !== undefined && organicPoints !== undefined
      ? nutritionPoints + organicPoints >= 50 && hasAtLeastOneDangerousAdditive
      : false

  return (
    <div>
      <Card>
        <div className="bg-white">
          <div className="px-4 py-5 space-y-5 sm:p-6 mb-2">
            <div className="flex justify-between items-center border-b border-gray-200 pb-2">
              <p className="text-lg leading-6 font-medium text-gray-900">{t('grade_details.title')}</p>
            </div>
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-sm">{t('grade_details.nutritional_balance')}</p>
                <p className="text-gray-900 font-medium text-sm text-right">
                  {!isLoading && nutritionPoints?.toString()}
                  <span className="text-xs text-gray-400">/60</span>
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-sm">{t('grade_details.additives')}</p>
                <p className="text-gray-900 font-medium text-sm text-right">
                  {!isLoading && additivesPoints?.toString()}
                  <span className="text-xs text-gray-400">/30</span>
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-sm">{t('grade_details.organic')}</p>
                <p className="text-gray-900 font-medium text-sm text-right">
                  {!isLoading && organicPoints?.toString()}
                  <span className="text-xs text-gray-400">/10</span>
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 sm:rounded">
            <div className="py-3 px-6 sm:border-t sm:border-gray-200">
              <div className="flex justify-between items-center">
                <p className="text-gray-500 text-sm">{t('grade_details.score')}</p>
                <p className="relative text-gray-900 font-medium text-sm text-right">
                  {isLoading && (
                    <svg
                      className="animate-spin h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <span className={isLoading ? 'hidden' : ''}>
                    <span
                      className={
                        (total !== undefined ? getResultColor(total) : '') + ' inline-block rounded-full h-2 w-2'
                      }
                    ></span>
                    <span className="ml-2">
                      {total?.toString()}
                      <span className="text-xs text-gray-400">/100</span>
                    </span>
                    {shouldPoorGradeBeJustified && (
                      <>
                        <div className={(isHelpVisible ? '' : 'hidden') + ' absolute w-max mx-2 -top-7 -right-3'}>
                          <div className="bg-black text-white text-xs rounded py-1 px-4 right-0 bottom-full">
                            {t('grade_details.poor_grade_explanations')}
                            <svg
                              className="absolute text-black h-2 right-0 mr-3 top-full"
                              x="0px"
                              y="0px"
                              viewBox="0 0 255 255"
                            >
                              <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
                            </svg>
                          </div>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 inline-block text-gray-400 h-5 w-5 cursor-pointer"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          onMouseEnter={() => setHelpVisible(true)}
                          onMouseLeave={() => setHelpVisible(false)}
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </>
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
