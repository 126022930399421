import React, { useEffect } from 'react'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import Card from '../../components/Card'
import EcoScoreDetails from '../../components/EcoScoreDetails'
import { useEcoScoreCalculator } from '../../hooks/useEcoScoreCalculator'
import { useCalculateEcoScore } from '../../hooks/useCalculateEcoScore'
import EcoScoreCalculatorParams from '../../types/EcoScoreCalculatorParams'
import EcoScoreForm from '../../components/EcoScore/Form'

export default function EcoScoreCalculator(ecoScoreRawData: EcoScoreCalculatorParams) {
  const { data, state, setters, resetCalculatorState } = useEcoScoreCalculator(ecoScoreRawData)

  const { isLoadingEcoScore, ecoScoreDetails, errors, calculateEcoScore, resetErrorsAndEcoScoreDetails } =
    useCalculateEcoScore()

  useEffect(() => {
    return calculateEcoScore(
      state.selectedCategory,
      state.selectedLabels,
      state.origins,
      state.selectedPackagingComponents,
      state.selectedFishingTechniques,
      state.ingredientsList,
      false
    )
  }, [
    calculateEcoScore,
    state.selectedCategory,
    state.selectedLabels,
    state.origins,
    state.selectedPackagingComponents,
    state.selectedFishingTechniques,
    state.ingredientsList,
  ])

  return (
    <div className="m-6">
      <div className="max-w-screen-2xl mx-auto lg:grid lg:grid-cols-4 lg:gap-6 lg:space-y-0 space-y-6">
        <div className="lg:col-span-3">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={(e) => {
              e.preventDefault()
              calculateEcoScore(
                state.selectedCategory,
                state.selectedLabels,
                state.origins,
                state.selectedPackagingComponents,
                state.selectedFishingTechniques,
                state.ingredientsList
              )
            }}
          >
            <Card>
              <div className="m-6 space-y-8 divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{"Simulation de l'Éco-score"}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Saisissez les informations d’un produit et obtenez son éco-score.
                    </p>
                  </div>
                  <EcoScoreForm
                    data={data}
                    state={state}
                    errors={errors}
                    setters={setters}
                    resetErrorsAndEcoScoreDetails={resetErrorsAndEcoScoreDetails}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end space-x-3 px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ButtonWhite
                  title="Réinitialiser"
                  type="reset"
                  onClick={() => {
                    resetCalculatorState()
                    resetErrorsAndEcoScoreDetails()
                  }}
                />
                <ButtonPrimary
                  title="Calculer"
                  type="submit"
                  disabled={
                    (state.selectedCategory && !state.selectedCategory.hasACV) ||
                    state.selectedPackagingComponents.length === 0
                  }
                  loading={isLoadingEcoScore}
                  className="w-24"
                />
              </div>
            </Card>
          </form>
        </div>
        <EcoScoreDetails {...ecoScoreDetails} />
      </div>
    </div>
  )
}
