import React from 'react'
import Category from '../../types/Category'
import { Filters } from './index'

export default function CategoryExpanded({
  filters,
  setFilters,
  categories,
}: {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  categories: Category[]
}) {
  return (
    <select
      id="status"
      name="status"
      className="block pl-2 pr-7 p-0 w-full h-6 text-sm border-gray-300 overflow-ellipsis focus:outline-none focus:border-transparent focus:ring-2 focus:ring-blue-400 rounded-md"
      value={filters.categorySlug}
      onChange={(e) => setFilters((currents) => ({ ...currents, categorySlug: e.target.value }))}
    >
      {categories.map((category: Category) => (
        <option key={category.slug} value={category.slug}>
          {category.name}
        </option>
      ))}
    </select>
  )
}
