import React from 'react'
import { ButtonWhite, ButtonDeleteIcon } from '../../components/Button'
import { SelectSingle } from '../../components/Select'
import { classNames } from '../../functions/classNames'
import Selectable from '../../types/Selectable'
import { PackagingComponent, PackagingComponentSpecificity } from '../../types/PackagingComponent'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Checkbox } from '../../components/Checkbox'
import { useIsMobile } from '../../hooks/useIsMobile'
import { Input } from '../../components/Input'

interface PackagingSectionProps {
  packagingComponents: PackagingComponent[]
  selectedComponents: PackagingComponent[]
  errors?: { [field: string]: any }
  handleAddPackaging: React.MouseEventHandler<HTMLButtonElement>
  handleFormatSelection: (index: number, format: Selectable) => void
  handleMaterialSelection: (index: number, material: Selectable) => void
  handleSpecificitySelection: (index: number, specificity: PackagingComponentSpecificity) => void
  handleWasteBasedMaterialRateSelection: (index: number, percentage?: number) => void
  handleDeletePackaging: (index: number, event: React.SyntheticEvent<Element, Event>) => void
}

export default function PackagingSection({
  packagingComponents,
  selectedComponents,
  errors,
  handleAddPackaging,
  handleFormatSelection,
  handleMaterialSelection,
  handleSpecificitySelection,
  handleWasteBasedMaterialRateSelection,
  handleDeletePackaging,
}: PackagingSectionProps) {
  const isMobile = useIsMobile()

  const disableAddButton = selectedComponents
    .map((component) => component.format !== undefined && component.material !== undefined)
    .includes(false)

  return (
    <>
      {selectedComponents.length > 0 && (
        <div className="space-y-3">
          {selectedComponents.map((component, index) => {
            const displayBottleSpecificities =
              component.format &&
              component.format.slug === 'bottle' &&
              component.material &&
              ['pp', 'pet'].includes(component.material.slug)

            const displayDryContentSpecificity =
              component.format &&
              component.material &&
              ['pot', 'case'].includes(component.format.slug) &&
              ['pp', 'pehd'].includes(component.material.slug)

            const displayPlasticSpecificity =
              component.format &&
              component.format.slug !== 'bottle' &&
              component.material &&
              ['pp', 'pet'].includes(component.material.slug)

            let formats: Selectable[] = packagingComponents
              .map((component) => component.format)
              .filter((format) => format) as Selectable[]
            let materials: Selectable[] = packagingComponents
              .map((component) => component.material)
              .filter((material) => material) as Selectable[]
            if (component.format) {
              materials = packagingComponents
                .filter((c) => c.format?.slug === component.format?.slug && c.material)
                .map((c) => c.material) as Selectable[]
            }
            if (component.material) {
              formats = packagingComponents
                .filter((c) => c.material?.slug === component.material?.slug && c.format)
                .map((c) => c.format) as Selectable[]
            }
            formats = formats
              .filter((format, index) => formats.findIndex((e) => e.slug === format.slug) === index)
              .sort((a, b) => a.name.localeCompare(b.name))
            materials = materials
              .filter((material, index) => materials.findIndex((e) => e.slug === material.slug) === index)
              .sort((a, b) => a.name.localeCompare(b.name))

            const SpecificityCheckboxes = (
              <>
                {displayDryContentSpecificity && (
                  <Checkbox
                    id={`dry_content_${index}`}
                    title="Contenu sec"
                    checked={component.specificities?.dryContent ?? false}
                    setChecked={() => handleSpecificitySelection(index, PackagingComponentSpecificity.DryContent)}
                  />
                )}
                {displayPlasticSpecificity && (
                  <Checkbox
                    id={`pp_pet_color_${index}`}
                    title={`${component.material?.slug.toUpperCase()} foncé`}
                    checked={component.specificities?.plasticColor === 'dark_colored_solid_plastic'}
                    setChecked={() =>
                      handleSpecificitySelection(index, PackagingComponentSpecificity.DarkColorSolidPlastic)
                    }
                  />
                )}
                {displayBottleSpecificities && (
                  <>
                    <Checkbox
                      id={`pp_pet_color_${index}`}
                      title={'Étiquette large'}
                      checked={component.specificities?.bottleSticker === 'large_bottle_sticker'}
                      setChecked={() =>
                        handleSpecificitySelection(index, PackagingComponentSpecificity.LargeBottleSticker)
                      }
                    />
                    <Checkbox
                      id={`pp_pet_color_${index}`}
                      title={'Plastique transparent'}
                      checked={component.specificities?.bottleColor === 'transparent_plastic_bottle'}
                      setChecked={() =>
                        handleSpecificitySelection(index, PackagingComponentSpecificity.TransparentPlasticBottle)
                      }
                    />
                    <Checkbox
                      id={`pp_pet_color_${index}`}
                      title={'Plastique coloré'}
                      checked={component.specificities?.bottleColor === 'colored_plastic_bottle'}
                      setChecked={() =>
                        handleSpecificitySelection(index, PackagingComponentSpecificity.ColoredPlasticBottle)
                      }
                    />
                    <Checkbox
                      id={`pp_pet_color_${index}`}
                      title={'Plastique opaque'}
                      checked={component.specificities?.bottleColor === 'opaque_plastic_bottle'}
                      setChecked={() =>
                        handleSpecificitySelection(index, PackagingComponentSpecificity.OpaquePlasticBottle)
                      }
                    />
                  </>
                )}
              </>
            )

            return (
              <div key={index} className="space-y-1">
                <div className="flex space-x-3 items-center">
                  <div className="sm:flex w-full max-w-lg flex-wrap sm:space-x-3 space-y-1 sm:space-y-0">
                    <div className="flex-1 space-y-1">
                      <SelectSingle
                        searchable
                        placeholder="Format"
                        selectedValue={component.format}
                        error={errors && errors[`packagingComponents[${index}].format`] && 'Obligatoire'}
                        options={formats}
                        handleSelection={(format) => handleFormatSelection(index, format as Selectable)}
                      />
                      {!isMobile && SpecificityCheckboxes}
                    </div>
                    <div className="flex-1 -mt-px sm:mt-0 space-y-1 sm:space-y-2">
                      <SelectSingle
                        searchable
                        placeholder="Matériau"
                        selectedValue={component.material}
                        error={errors && errors[`packagingComponents[${index}].material`] && 'Obligatoire'}
                        options={materials}
                        handleSelection={(material) => handleMaterialSelection(index, material as Selectable)}
                        isDisabled={component.format?.slug === 'bulk'}
                      />
                      {displayBottleSpecificities && (
                        <Input
                          id="waste-based-material-rate"
                          name="waste-based-material-rate"
                          type="text"
                          placeholder="0"
                          value={component.specificities?.wasteBasedMaterialRate}
                          trailingText="% de matériaux recyclés"
                          trailingTextClassName="bg-gray-50 rounded-r-md pl-2"
                          handleChange={(text) => {
                            let value = text
                            const isNumber = /^[0-9]*$/.test(value)
                            if (!isNumber) {
                              value = value.slice(0, -1)
                            }
                            let percentage = value.length > 0 ? Number(value) : undefined
                            if (percentage && percentage > 100) {
                              percentage = Math.floor(percentage / 10)
                            }
                            handleWasteBasedMaterialRateSelection(index, percentage)
                          }}
                        />
                      )}
                    </div>
                    {isMobile && SpecificityCheckboxes}
                  </div>
                  <div
                    className={classNames(
                      'flex',
                      errors &&
                        (errors[`packagingComponents[${index}].format`] ||
                          errors[`packagingComponents[${index}].material`])
                        ? 'mb-7'
                        : ''
                    )}
                  >
                    <ButtonDeleteIcon type="button" onClick={(e) => handleDeletePackaging(index, e)} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <ButtonWhite type="button" title="Ajouter" onClick={handleAddPackaging} disabled={disableAddButton} />
      {selectedComponents.length === 0 && (
        <div className="flex items-center space-x-2">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          <span className="text-sm text-red-500">{"L'emballage du produit est obligatoire."}</span>
        </div>
      )}
    </>
  )
}
