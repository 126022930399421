import React from 'react'
import HealthGradeCalculator from '.'
import useQuery from '../../hooks/useQuery'

export default function PrefilledHealthGradeCalculator() {
  const query = useQuery()

  const category = query.get('category')
  const ingredientsList = query.get('ingredients')

  const labels = query.getAll('labels[]')
  const organic = labels.includes('bio')

  const calories = query.get('calories') ? Number(query.get('calories')) : undefined
  const fat = query.get('fat') ? Number(query.get('fat')) : undefined
  const saturatedFat = query.get('saturatedFat') ? Number(query.get('saturatedFat')) : undefined
  const carbohydrates = query.get('carbohydrates') ? Number(query.get('carbohydrates')) : undefined
  const sugar = query.get('sugar') ? Number(query.get('sugar')) : undefined
  const fibers = query.get('fibers') ? Number(query.get('fibers')) : undefined
  const proteins = query.get('proteins') ? Number(query.get('proteins')) : undefined
  const salt = query.get('salt') ? Number(query.get('salt')) : undefined
  const fruits = query.get('fruits') ? Number(query.get('fruits')) : undefined

  return (
    <HealthGradeCalculator
      categorySlug={category}
      ingredientsList={ingredientsList}
      organic={organic}
      calories={calories}
      fat={fat}
      saturatedFat={saturatedFat}
      carbohydrates={carbohydrates}
      sugar={sugar}
      fibers={fibers}
      proteins={proteins}
      salt={salt}
      fruits={fruits}
    />
  )
}
