import React from 'react'
import { SearchIcon } from '@heroicons/react/solid'

export function SearchBar({
  placeholder,
  value,
  onChange,
}: {
  placeholder: string
  value?: string
  onChange: (text: string) => void
}) {
  return (
    <div className="w-full">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-transparent focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type="search"
        />
      </div>
    </div>
  )
}
