import React from 'react'
import OriginSection from './OriginSection'
import { RegionType } from '../../types/Origin'
import {
  EcoScoreCalculatorData,
  EcoScoreCalculatorState,
  EcoScoreCalculatorSetters,
} from '../../hooks/useEcoScoreCalculator'
import { EcoScoreCalculatorErrors } from '../../hooks/useCalculateEcoScore'
import { Row } from '../../components/Row'
import { SelectMultiple } from '../../components/Select'
import { classNames } from '../../functions/classNames'
import PackagingSection from './PackagingSection'
import CategorySection from './CategorySection'
import Selectable from '../../types/Selectable'

export default function EcoScoreForm({
  data,
  state,
  errors,
  setters,
  disabledSections,
  resetErrorsAndEcoScoreDetails,
}: {
  data: EcoScoreCalculatorData
  state: EcoScoreCalculatorState
  errors: EcoScoreCalculatorErrors
  setters: EcoScoreCalculatorSetters
  disabledSections?: Array<'category' | 'labels' | 'origins' | 'packaging' | 'ingredients' | 'fishingTechniques'>
  resetErrorsAndEcoScoreDetails: () => void
}) {
  const originsContainOneFishingArea =
    state.origins
      .map((origin) => origin.regions)
      .find((regions) => regions?.find((region) => [RegionType.Sea, RegionType.Ocean].includes(region.type))) !==
    undefined

  if (!originsContainOneFishingArea && state.selectedFishingTechniques.length > 0) {
    setters.fishingTechniquesDispatch({ type: 'setAll', items: [] })
  }

  return (
    <div className="mt-1 sm:mt-5 space-y-6 sm:space-y-5">
      <Row title="Catégorie" borderTop>
        <CategorySection
          categories={data.categories}
          selectedCategories={state.selectedCategories}
          selectedCategory={state.selectedCategory}
          isDisabled={disabledSections?.includes('category')}
          error={errors.category}
          handleCategoriesChange={(categories) => {
            setters.setSelectedCategories(categories)
            resetErrorsAndEcoScoreDetails()
          }}
        />
      </Row>

      <Row title="Labels" borderTop>
        <SelectMultiple
          placeholder="Aucun"
          searchable
          isDisabled={disabledSections?.includes('labels')}
          options={data.labels}
          selectedValues={state.selectedLabels}
          handleSelection={(labels) => setters.labelsDispatch({ type: 'setAll', items: labels as Selectable[] })}
        />
      </Row>

      <Row title="Origines" borderTop>
        <OriginSection
          ingredients={data.ingredients}
          regions={data.regions}
          origins={state.origins}
          errors={errors.origins}
          isDisabled={disabledSections?.includes('origins')}
          handleAddOrigin={(e) => {
            e.preventDefault()
            setters.originsDispatch({ type: 'add', origin: {} })
            resetErrorsAndEcoScoreDetails()
          }}
          handleIngredientChange={(index, ingredient) => {
            setters.originsDispatch({ type: 'selectIngredient', index: index, ingredient: ingredient })
            resetErrorsAndEcoScoreDetails()
          }}
          handleRegionSelection={(index, regions) => {
            setters.originsDispatch({ type: 'setRegions', index: index, regions: regions })
            resetErrorsAndEcoScoreDetails()
          }}
          handlePercentageChange={(index, percentage) => {
            setters.originsDispatch({ type: 'selectPercentage', index: index, percentage: percentage })
            resetErrorsAndEcoScoreDetails()
          }}
          handleDeleteOrigin={(index, e) => {
            e.preventDefault()
            setters.originsDispatch({ type: 'remove', index: index })
            resetErrorsAndEcoScoreDetails()
          }}
        />
      </Row>

      {originsContainOneFishingArea && (
        <Row title="Techniques de pêche" borderTop>
          <SelectMultiple
            searchable
            isDisabled={disabledSections?.includes('fishingTechniques')}
            placeholder="Non spécifié"
            options={data.fishingTechniques}
            selectedValues={state.selectedFishingTechniques}
            handleSelection={(fishingTechniques) =>
              setters.fishingTechniquesDispatch({ type: 'setAll', items: fishingTechniques as Selectable[] })
            }
          />
        </Row>
      )}

      <Row title="Emballages" borderTop>
        <PackagingSection
          packagingComponents={data.packagingComponents}
          selectedComponents={state.selectedPackagingComponents}
          errors={errors.packaging}
          handleAddPackaging={(e) => {
            e.preventDefault()
            setters.packagingComponentsDispatch({ type: 'add', component: {} })
            resetErrorsAndEcoScoreDetails()
          }}
          handleFormatSelection={(index, format) => {
            setters.packagingComponentsDispatch({
              type: 'selectFormat',
              index: index,
              format: format,
              validComponents: data.packagingComponents,
            })
            resetErrorsAndEcoScoreDetails()
          }}
          handleMaterialSelection={(index, material) => {
            setters.packagingComponentsDispatch({
              type: 'selectMaterial',
              index: index,
              material: material,
              validComponents: data.packagingComponents,
            })
            resetErrorsAndEcoScoreDetails()
          }}
          handleSpecificitySelection={(index, specificity) => {
            setters.packagingComponentsDispatch({
              type: 'selectSpecificity',
              index: index,
              specificity: specificity,
            })
          }}
          handleWasteBasedMaterialRateSelection={(index, value) => {
            setters.packagingComponentsDispatch({
              type: 'setWasteBasedMaterialRate',
              index: index,
              value: value,
            })
          }}
          handleDeletePackaging={(index, e) => {
            e.preventDefault()
            setters.packagingComponentsDispatch({ type: 'remove', index: index })
            resetErrorsAndEcoScoreDetails()
          }}
        />
      </Row>

      <Row title="Liste d'ingrédients" borderTop>
        <textarea
          id="about"
          name="about"
          rows={4}
          disabled={disabledSections?.includes('ingredients')}
          onChange={(e) => {
            const list = (e.target as HTMLTextAreaElement).value.trim()
            setters.setIngredientsList(list)
            resetErrorsAndEcoScoreDetails()
          }}
          className={classNames(
            'max-w-lg shadow-sm block w-full sm:text-sm rounded-md focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400',
            errors.ingredientsList ? 'border-red-400' : 'border-gray-300'
          )}
          defaultValue={state.ingredientsList}
        />
        {errors.ingredientsList && <p className="text-sm text-red-400">{errors.ingredientsList}</p>}
      </Row>
    </div>
  )
}
