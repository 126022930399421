import React, { useRef, MouseEventHandler } from 'react'
import { classNames } from '../../functions/classNames'

export function BadgeWithRemoveButton({
  title,
  color,
  handleRemove,
}: {
  title: string
  color: string
  handleRemove: MouseEventHandler<HTMLButtonElement>
}) {
  const ref = useRef<HTMLSpanElement>(null)

  return (
    <>
      <span
        className={classNames(
          `inline-flex items-center py-0.5 pl-2 pr-0.5 sm:text-xs text-sm font-medium bg-${color}-100 text-${color}-700`,
          ref.current && ref.current.clientHeight > 16 ? 'rounded-lg' : 'rounded-full'
        )}
        ref={ref}
      >
        {title}
        <button
          type="button"
          onClick={handleRemove}
          className={`flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-${color}-400 hover:bg-${color}-200 hover:text-${color}-500 focus:outline-none focus:bg-${color}-500 focus:text-white`}
        >
          <span className="sr-only">Remove {title}</span>
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      </span>
    </>
  )
}

export function Badge({ title, color }: { title: string; color: string }) {
  return (
    <span
      className={`inline-flex whitespace-nowrap items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${color}-100 text-${color}-800`}
    >
      {title}
    </span>
  )
}
