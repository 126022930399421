import React, { useEffect } from 'react'
import Card from '../../components/Card'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import { useTitle } from '../../hooks/useTitle'
import CosmeticHealthGradeForm from '../../components/CosmeticHealthGrade/Form'
import { useCalculateHealthGrade } from '../../hooks/useCalculateCosmeticHealthGrade'
import CosmeticHealthGradeDetails from '../../components/CosmeticHealthGradeDetails'

export default function CosmeticHealthGradeCalculator() {
  useTitle('Simulateur note Yuka')

  const {
    categories,
    formData,
    setters,
    calculateHealthGrade,
    resetHealthGradeForm,
    resetHealthGradeDetails,
    healthGradeResult,
    detectedIngredients,
    isLoadingHealthGrade,
    errors,
  } = useCalculateHealthGrade()

  useEffect(() => {
    return calculateHealthGrade(false)
  }, [calculateHealthGrade])

  return (
    <div className="m-6">
      <div className="max-w-screen-2xl mx-auto lg:grid lg:grid-cols-4 lg:gap-6 lg:space-y-0 space-y-6">
        <div className="lg:col-span-3">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={(e) => {
              e.preventDefault()
              calculateHealthGrade()
            }}
          >
            <Card>
              <div className="m-6 space-y-8 divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Simulation score cosmétique</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Saisissez les informations d&apos;un produit et obtenez son score sur Yuka.
                    </p>
                  </div>
                  <CosmeticHealthGradeForm
                    categories={categories}
                    formData={formData}
                    setters={setters}
                    errors={errors}
                    detectedIngredients={detectedIngredients}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end space-x-3 px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ButtonWhite
                  title="Réinitialiser"
                  type="reset"
                  onClick={() => {
                    resetHealthGradeDetails()
                    resetHealthGradeForm()
                  }}
                />
                <ButtonPrimary title="Calculer" type="submit" loading={isLoadingHealthGrade} className="w-24" />
              </div>
            </Card>
          </form>
        </div>
        <CosmeticHealthGradeDetails
          grade={healthGradeResult}
          isLoading={isLoadingHealthGrade}
          ingredients={detectedIngredients}
        />
      </div>
    </div>
  )
}
