import React from 'react'
import { ButtonWhite, ButtonDeleteIcon } from '../../components/Button'
import { SelectSingle, SelectMultiple } from '../../components/Select'
import { Input } from '../../components/Input'
import { getCompatibleRegionsWithIngredient } from '../../functions/getCompatibleRegionsWithIngredient'
import { ExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { Origin, Region, Ingredient } from '../../types/Origin'
import { useIsMobile } from '../../hooks/useIsMobile'

export interface OriginSectionErrors {
  rows: { ingredient?: string; regions?: string; percentage?: { message?: string; displayRedBorder?: boolean } }[]
  global?: string
}

interface OriginSectionProps {
  ingredients: Ingredient[]
  regions: Region[]
  origins: Origin[]
  isDisabled?: boolean
  errors?: { [field: string]: any }
  handleAddOrigin: React.MouseEventHandler<HTMLButtonElement>
  handleIngredientChange: (index: number, value: Ingredient) => void
  handleRegionSelection: (index: number, value: Region[]) => void
  handlePercentageChange: (index: number, percentage?: string) => void
  handleDeleteOrigin: (index: number, event: React.SyntheticEvent<Element, Event>) => void
}

export default function OriginSection({
  ingredients,
  regions,
  origins,
  isDisabled,
  errors,
  handleAddOrigin,
  handleIngredientChange,
  handleRegionSelection,
  handlePercentageChange,
  handleDeleteOrigin,
}: OriginSectionProps) {
  const isMobile = useIsMobile()
  const disableAddButton = origins
    .map(
      (origin) =>
        origin.ingredient !== undefined &&
        origin.regions !== undefined &&
        origin.regions.length > 0 &&
        origin.percentage !== undefined
    )
    .includes(false)

  return (
    <>
      {origins.length > 0 && (
        <div className="space-y-3">
          {origins.map((origin, index) => {
            const compatibleRegions = origin.ingredient
              ? getCompatibleRegionsWithIngredient(regions, origin.ingredient)
              : regions
            return (
              <div key={index} className="flex items-center sm:items-start space-x-3">
                <div className="sm:flex w-full max-w-lg sm:space-x-3 space-y-1 sm:space-y-0">
                  <div className="flex-1">
                    <SelectSingle
                      placeholder="Ingrédient"
                      searchable
                      isDisabled={isDisabled}
                      options={ingredients}
                      error={errors && errors[`origins[${index}].ingredient`] && 'Obligatoire'}
                      selectedValue={origins[index].ingredient}
                      handleSelection={(ingredient) => handleIngredientChange(index, ingredient as Ingredient)}
                    />
                  </div>
                  <div className="flex-1">
                    <SelectMultiple
                      searchable
                      placeholder="Régions"
                      isDisabled={isDisabled}
                      error={errors && errors[`origins[${index}].regions`]}
                      options={compatibleRegions}
                      selectedValues={origin.regions ? origin.regions : []}
                      handleSelection={(region) => handleRegionSelection(index, region as Region[])}
                    />
                  </div>
                  <Input
                    id="percentage"
                    name="percentage"
                    type="text"
                    value={origin?.percentage ?? ''}
                    error={errors && errors[`origins[${index}].percentage`] && 'Obligatoire'}
                    trailingText="%"
                    step={0.01}
                    width={isMobile ? undefined : 'w-20'}
                    height={isMobile ? 'h-9' : undefined}
                    disabled={isDisabled}
                    handleChange={(text) => {
                      let value = text.replace(',', '.')
                      const isDecimal = /^[0-9]+\.?[0-9]*$/.test(value)
                      if (!isDecimal) {
                        value = value.slice(0, -1)
                      }
                      handlePercentageChange(index, value)
                    }}
                  />
                </div>
                {!isDisabled && (
                  <div
                    className={
                      errors &&
                      (errors[`origins[${index}].ingredient`] ||
                        errors[`origins[${index}].regions`] ||
                        errors[`origins[${index}].percentage`])
                        ? 'mb-7'
                        : ''
                    }
                  >
                    <ButtonDeleteIcon
                      type="button"
                      disabled={isDisabled}
                      onClick={(e) => handleDeleteOrigin(index, e)}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
      <ButtonWhite title="Ajouter" type="button" onClick={handleAddOrigin} disabled={disableAddButton || isDisabled} />
      {origins.length === 0 && (
        <div className="flex items-center space-x-2">
          <ExclamationIcon className="h-5 w-5 text-yellow-500" />
          <span className="text-sm text-yellow-500">Aucune origine indiquée</span>
        </div>
      )}
      {errors && errors.origins && (
        <div className="flex items-center space-x-2">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          <span className="text-sm text-red-500">{errors.origins}</span>
        </div>
      )}
    </>
  )
}
