import React, { useState, useEffect } from 'react'
import API from '../../infrastructure/api'
import Warning from '../../components/Warning'
import { User, useAuth } from '../../hooks/useAuth'
import PaginationFooter from '../../components/Pagination'
import { useHistory, useParams } from 'react-router-dom'
import { PageInfo } from '../../types/PageInfo'
import { ButtonPrimary } from '../../components/Button'
import { PlusIcon } from '@heroicons/react/solid'
import CreateOrUpdateUserModal from './CreateOrUpdateUserModal'
import UsersActionMenu from './UsersActionMenu'
import { useNotification } from '../../hooks/useNotification'
import { NotificationType } from '../../components/Notification'
import { useTitle } from '../../hooks/useTitle'
import { SearchBar } from '@/components/Search'
import useQuery from '@/hooks/useQuery'

interface UserDTO extends User {
  foodProductsCount: number
  cosmeticProductsCount: number
}

interface UsersPage {
  users: UserDTO[]
  info: PageInfo
}

export default function UsersList() {
  useTitle('Admin')
  const history = useHistory()
  const params = useParams<{ page?: string | undefined }>()
  const query = useQuery()
  const search = query.get('search')
  const { impersonateUser } = useAuth()
  const { setNotification } = useNotification()
  const [tempSearchText, setTempSearchText] = useState<string>(search ?? '')
  const [searchText, setSearchText] = useState<string>(search ?? '')
  const [page, setPage] = useState<number>(params.page ? Number(params.page) : 1)
  const [usersPage, setUsersPage] = useState<UsersPage | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isCreateOrUpdateUserModalOpen, setCreateOrUpdateUserModalOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  useEffect(() => {
    API.get('/users', { params: { page: page, size: 20, search: searchText } })
      .then((response) => {
        setUsersPage(response.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err.response.status === 403 ? 'Interdit' : "Désolé, quelque chose s'est mal passé.")
        setLoading(false)
      })
  }, [page, searchText])

  useEffect(() => {
    const url = searchText ? `${page}?search=${searchText}` : `${page}`
    history.push(url)
  }, [history, page, searchText])

  useEffect(() => {
    const timeout = setTimeout(() => setSearchText(tempSearchText), 500)
    return () => clearTimeout(timeout)
  }, [tempSearchText, setSearchText])

  if (loading) {
    return <></>
  } else if (error) {
    return <Warning text={error} />
  }

  return (
    <div className="m-6">
      <CreateOrUpdateUserModal
        open={isCreateOrUpdateUserModalOpen}
        setOpen={setCreateOrUpdateUserModalOpen}
        onSuccess={() => window.location.reload()}
        user={selectedUser}
      />
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="flex-1 max-w-md items-center">
            <SearchBar
              placeholder="Recherche par e-mail ou nom de marque"
              value={tempSearchText}
              onChange={(text) => {
                setPage(1)
                setTempSearchText(text)
              }}
            />
          </div>
          <ButtonPrimary
            title="Créer un utilisateur"
            leftIcon={<PlusIcon className="h-4 w-4" />}
            onClick={() => {
              setSelectedUser(undefined)
              setCreateOrUpdateUserModalOpen(true)
            }}
          />
        </div>
        <div className="inline-block overflow-x-scroll w-full shadow sm:rounded-md bg-white divide-y divide-gray-200">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  E-mail
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Marques
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Nombre de produits
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Crée le
                </th>
                <th scope="col" className="relative px-4 py-3">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {usersPage?.users.map((user) => {
                let userBrands: string[] = []
                const userBrandsType: string =
                  user.foodBrands && user.cosmeticsBrands
                    ? 'Alimentaire, Cosmétique'
                    : user.foodBrands
                    ? 'Alimentaire'
                    : user.cosmeticsBrands
                    ? 'Cosmétique'
                    : ''
                if (user.foodBrands) {
                  userBrands = user.foodBrands
                }
                if (user.cosmeticsBrands) {
                  userBrands = [...userBrands, ...user.cosmeticsBrands]
                }
                return (
                  <tr key={user.id}>
                    <td className="pl-6 pr-4 py-4 text-sm">{user.email}</td>
                    <td className="p-4 text-sm text-gray-500">{userBrandsType}</td>
                    <td className="p-4 text-sm text-gray-500">{userBrands.join(', ')}</td>
                    <td className="p-4 text-sm text-gray-500">{user.foodProductsCount + user.cosmeticProductsCount}</td>
                    <td className="p-4 text-sm text-gray-500">
                      {new Date(user.created).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td className="pl-4 pr-6 py-4">
                      <UsersActionMenu
                        onEditClick={() => {
                          setSelectedUser(user)
                          setCreateOrUpdateUserModalOpen(true)
                        }}
                        onUpdateProductsClick={() => {
                          if (
                            (!user.foodBrands || user.foodBrands?.length === 0) &&
                            (!user.cosmeticsBrands || user.cosmeticsBrands?.length === 0)
                          ) {
                            setNotification({
                              title: `${user.email} n'a aucune marque`,
                              message: `Veuillez affecter au moins une marque à ${user.email} pour pouvoir charger les produits.`,
                              type: NotificationType.Error,
                            })
                            return
                          }
                          API.post(`/operations/update-user-products`, { email: user.email })
                            .then(() =>
                              setNotification({
                                title: 'Tâche crée',
                                message: 'Le chargement des produits peut prendre plusieurs minutes.',
                                type: NotificationType.Success,
                              })
                            )
                            .catch(() =>
                              setNotification({
                                title: 'Erreur',
                                message: `Impossible de charger les produits de l'utilisateur ${user.email}`,
                                type: NotificationType.Error,
                              })
                            )
                        }}
                        onImpersonateClick={() => {
                          impersonateUser(user.id)
                            .then(() => history.push('/'))
                            .catch(() =>
                              setNotification({
                                title: 'Erreur',
                                message: `Impossible d'imiter l'utilisateur ${user.email}`,
                                type: NotificationType.Error,
                              })
                            )
                        }}
                        onDeleteClick={() => {
                          API.delete(`/users/${user.email}`)
                            .then(() => window.location.reload())
                            .catch(() =>
                              setNotification({
                                title: 'Erreur',
                                message: `Impossible de supprimer l'utilisateur ${user.email}`,
                                type: NotificationType.Error,
                              })
                            )
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {usersPage && <PaginationFooter currentPage={page} pageInfo={usersPage.info} onPageClick={setPage} />}
        </div>
      </div>
    </div>
  )
}
