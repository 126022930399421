import React from 'react'
import { Filters } from './index'
import { EcoScore } from '../../types/EcoScore'

export default function EcoScoreExpanded({
  filters,
  setFilters,
}: {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
}) {
  return (
    <select
      id="status"
      name="status"
      className="block pl-2 p-0 w-full h-6 text-sm border-gray-300 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-blue-400 rounded-md"
      value={filters.ecoScore}
      onChange={(e) => setFilters((currents) => ({ ...currents, ecoScore: e.target.value }))}
    >
      {Object.keys(EcoScore).map((key) => (
        <option key={key} value={key}>
          {key}
        </option>
      ))}
    </select>
  )
}
