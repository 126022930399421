import React from 'react'
import { classNames } from '../../functions/classNames'
import { XCircleIcon } from '@heroicons/react/solid'

interface ButtonProps {
  title?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  loading?: boolean
  color?: string
  className?: string
  leftIcon?: JSX.Element
  rightContent?: JSX.Element
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export function ButtonPrimary({
  title,
  type,
  disabled,
  loading,
  color = 'green',
  className,
  leftIcon,
  rightContent,
  onClick,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={classNames(
        `inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-${color}-500 transition duration-400 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-offset-2`,
        loading ? 'cursor-default' : '',
        disabled ? 'cursor-not-allowed disabled:opacity-50' : '',
        !loading && !disabled ? `hover:bg-${color}-600 active:bg-${color}-700` : '',
        leftIcon ? 'pl-3 pr-4' : 'px-4',
        className ?? ''
      )}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <div className="flex items-center space-x-2">
        {leftIcon && leftIcon}
        <ButtonContent title={title} loading={loading} color="text-white" />
        {rightContent && rightContent}
      </div>
    </button>
  )
}

export function ButtonWhite({
  title,
  type,
  disabled,
  loading,
  className,
  leftIcon,
  rightContent,
  onClick,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={classNames(
        'inline-flex bg-white justify-center py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 transition duration-400 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-offset-2',
        disabled ? 'cursor-not-allowed disabled:opacity-50' : 'hover:bg-gray-50 active:bg-gray-100',
        leftIcon ? 'pl-3 pr-4' : 'px-4',
        className ?? ''
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex items-center space-x-2">
        {leftIcon && leftIcon}
        <ButtonContent title={title} loading={loading} color="text-black" />
        {rightContent && rightContent}
      </div>
    </button>
  )
}

export function ButtonDeleteIcon({ type, disabled, onClick }: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      className="group flex items-center hover:bg-gray-100 p-2 rounded text-sm w-auto active:bg-gray-200 transition duration-400 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-50 disabled:cursor-not-allowed"
      onClick={onClick}
    >
      <XCircleIcon className="h-5 w-5 text-gray-500" />
    </button>
  )
}

function ButtonContent({ loading, title, color }: { loading?: boolean; title?: string; color: string }) {
  return loading ? (
    <svg
      className={classNames('animate-spin w-5', color)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  ) : (
    <p>{title}</p>
  )
}
