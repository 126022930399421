import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import Modal from '../../components/Modal'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import API from '../../infrastructure/api'
import { SelectSingle } from '../../components/Select'
import Category from '../../types/Category'
import { CosmeticProduct } from '../../types/CosmeticProduct'

export default function BulkUpdateCategoryModal({
  products,
  open,
  setOpen,
}: {
  products: CosmeticProduct[]
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined)
  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    API.get('/cosmetic/categories').then((response) => setCategories(response.data))
  }, [])

  useEffect(() => {
    setSelectedCategory(undefined)
    setLoading(false)
  }, [open])

  return (
    <Modal
      title="Modification de la catégorie"
      body={
        <div className="m-4 sm:m-6 mb-6 sm:mb-10 space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-1">
              {`${products.length} ${products.length > 1 ? 'produits sélectionnés :' : 'produit sélectionné :'}`}
            </h3>
            <p className="text-sm text-gray-700">{products.map((p) => p.ean).join(', ')}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-900 mb-2">Nouvelle catégorie</p>
            <SelectSingle
              placeholder="Sélectionnez une catégorie"
              options={categories}
              selectedValue={selectedCategory}
              handleSelection={(item) => setSelectedCategory(item as Category)}
            />
          </div>
        </div>
      }
      footer={
        <div className="space-x-3">
          <ButtonPrimary
            type="submit"
            title="Modifier la catégorie"
            disabled={!selectedCategory || loading}
            onClick={() => {
              if (!selectedCategory) {
                return
              }
              setLoading(true)
              // API.post('/food/products/update-category', {
              //   productEans: products.map((p) => p.ean),
              //   category: selectedCategory.slug,
              // })
              //   .then(() => {
              //     setLoading(false)
              //     setOpen(false)
              //     window.location.reload()
              //   })
              //   .catch((err) => {
              //     console.error(err)
              //     setLoading(false)
              //   })
            }}
          />
          <ButtonWhite title="Annuler" onClick={() => setOpen(false)} />
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  )
}
